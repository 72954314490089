<template>
  <div>
    <a-spin :spinning="loading">
      <a-card
        :title="$t(I18N_KEY + 'userDetail')"
        type="inner"
        style="margin-top: 24px;" size="small">
        <detail-list>
          <detail-list-item :term="$t(I18N_KEY + 'app')">{{handleApp(teleMarketData.appSsid)}}</detail-list-item>
          <detail-list-item :term="$t(I18N_KEY + 'userName')">{{teleMarketData.username}}</detail-list-item>
          <detail-list-item :term="$t(I18N_KEY + 'registerTime')">{{teleMarketData.registerTime}}</detail-list-item>
          <detail-list-item :term="$t(I18N_KEY + 'type')">{{$t(I18N_KEY + 'type_' + teleMarketData.type)}}</detail-list-item>
          <detail-list-item :term="$t(I18N_KEY + 'mobile')">{{teleMarketData.mobile}}</detail-list-item>
          <detail-list-item :term="$t(I18N_KEY + 'email')">{{teleMarketData.email}}</detail-list-item>
          <detail-list-item :term="$t(I18N_KEY + 'assignTime')" >{{ teleMarketData.createTime }}</detail-list-item>
        </detail-list>
      </a-card>

      <a-card
        :title="$t(I18N_KEY + 'userOperateHistory')"
        type="inner"
        style="margin-top: 24px;" size="small">
        <detail-list>
          <detail-list-item :term="$t(I18N_KEY + 'registerTime')">{{teleMarketData.registerTime}}</detail-list-item>
          <detail-list-item :term="$t(I18N_KEY + 'baseInfoSubmitTime')">{{teleMarketData.baseInfoSubmitTime}}</detail-list-item>
          <detail-list-item :term="$t(I18N_KEY + 'linkManSubmitTime')">{{teleMarketData.linkManSubmitTime}}</detail-list-item>
          <detail-list-item :term="$t(I18N_KEY + 'idInfoSubmitTime')">{{teleMarketData.idInfoSubmitTime}}</detail-list-item>
          <detail-list-item :term="$t(I18N_KEY + 'bankInfoSubmitTime')">{{teleMarketData.bankInfoSubmitTime}}</detail-list-item>
          <detail-list-item :term="$t(I18N_KEY + 'orderSubmitTime')">{{teleMarketData.orderSubmitTime}}</detail-list-item>
        </detail-list>
      </a-card>

      <a-card
        :title="$t(I18N_KEY + 'followUpHistory')"
        type="inner"
        style="margin-top: 24px;" size="small">
        <a-table
          ref="table"
          rowKey="id"
          size="middle"
          :columns="columns"
          :dataSource="dataSource"
          :pagination="false"
          :loading="loading"
          bordered>
<!--          <template #notApplyReason="text,record,index">{{$t(I18N_KEY + 'notApplyReason_' + text)}}</template>-->
<!--          <template #dataStatus="text,record,index">{{$t(I18N_KEY + 'convertStatus_' + text)}}</template>-->
          <!--        <template #assignStatus="text,record,index">{{$t(I18N_KEY + 'assignStatus_' + text)}}</template>-->
          <span slot="action" slot-scope="text, record">
            <a v-if='record.showView' @click="handleDetail(record)">{{ $t('view') }}</a>
          </span>
        </a-table>
      </a-card>
    </a-spin>
    <tele-market-attachment ref="attachment" :attachments='attachments' @ok="modalFormOk"></tele-market-attachment>
  </div>
</template>
<script>
import { DetailList } from '@/components';
import imageMixin from '@/mixins/image-mixin';
import {baseData, dataDetail} from '@/webpublicapi/teleMarket';
import TeleMarketAttachment from './TeleMarketAttachment';
import {mapGetters} from "vuex";
const DetailListItem = DetailList.Item
const I18N_KEY = 'teleMarket.';

export default {
  name: 'TeleMarketDetail',
  props: {
    orderId: {
      type: [String, Number],
      default: undefined,
    },
    followUpNotApplyReasons: {
      type: Array,
      default: () => [],
    },
    followUpNotApplySubReasonsE: {
      type: Array,
      default: () => [],
    },
    followUpNotApplySubReasonsG: {
      type: Array,
      default: () => [],
    },
    followUpNotApplySubReasonsI: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    DetailList,
    DetailListItem,
    TeleMarketAttachment,
  },
  mixins: [imageMixin],
  data () {
    return {
      I18N_KEY,
      loading: false,
      visible: false,
      showView: false,
      teleMarketData: {},
      workOrder: {},
      attachments: [],
      dataSource:[],
      apps: '',
    }
  },
  created () {
    this.loadBaseData();
    this.requestDetail(this.orderId);
  },
  methods: {
    loadBaseData() {
      baseData({}).then((res) => {
        if (res.code === 1000) {
          this.teleMarketUsers = res.data.seats;
          var teleMarketUserValues = [];
          for(var item in this.teleMarketUsers) {
            teleMarketUserValues.push(this.teleMarketUsers[item].value)
          }
          this.teleMarketUserValues = teleMarketUserValues;
          this.types = res.data.types;
          var typeValues = [];
          for(var item in this.types) {
            typeValues.push(this.types[item].value)
          }
          this.typeValues = typeValues;
          this.followStatuses = res.data.followStatuses;
          this.view = res.data.view;
          this.apps = res.data.apps;
        } else {
          this.$message.error(res.msg)
        }
      }).catch(e => {
        this.$message.error(e.message);
      })
    },
    requestDetail (id) {
      dataDetail(id).then(res => {
        this.teleMarketData = res.data.data || {};
        this.dataSource = res.data.followUpRecords || [];
        // console.log(this.dataSource)
        this.loading = false;
      }).catch(e => {
        this.$message.error(e.message);
        this.loading = false;
      })
    },
    handleApp(app) {
      var app = app;
      for(var item in this.apps) {
        if(app === this.apps[item].appSsid) {
          return app+ '/' + this.apps[item].appName
        }
      }
      return app
    },
    handleDetail(record){
      this.attachments = record.attachmentUrl;
      this.visible = true;
      this.$refs.attachment.open();
    },
    modalFormOk(){
      this.$refs.attachment.closeLoading();
      this.$refs.attachment.close();
    },
  },
  computed: {
    ...mapGetters('language', ['languageStorage']),
    columns () {
      return [
        {
          title: this.$t(I18N_KEY + 'followUpTime'),
          align: 'center',
          dataIndex: 'followUpTime'
        },
        {
          title: this.$t(I18N_KEY + 'followUpUser'),
          align: 'center',
          dataIndex: 'operator'
        },
        {
          title: this.$t(I18N_KEY + 'followUpStatus'),
          align: 'center',
          dataIndex: 'followStatuses',
          customRender: (text, record) => {
            var text = text;
            // console.log('text='+text)
            var result = ''
            for(var item in text) {
              // console.log('item='+item)
              if(result === '') {
                result = this.$t(I18N_KEY + 'followUpStatus_' + text[item])
              } else {
                result = result+','+this.$t(I18N_KEY + 'followUpStatus_' + text[item])
              }
            }
            return result;
          }
        },
        {
          title: this.$t(I18N_KEY + 'notApplyReason'),
          align: 'center',
          dataIndex: 'notApplyReason',
          // scopedSlots: { customRender: 'notApplyReason' }
          customRender: (text, record) => {
            var text = text;
            if(text != undefined && text != null && text != '') {
              // return this.$t(I18N_KEY + 'notApplyReason_' + text)
              for(var i in this.followUpNotApplyReasons) {
                if(text === this.followUpNotApplyReasons[i].value) {
                  return this.followUpNotApplyReasons[i][this.fieldName]
                }
              }
            } else {
              return '';
            }
          }
        },
        {
          title: this.$t(I18N_KEY + 'notApplySubReason'),
          align: 'center',
          width: 150,
          dataIndex: 'notApplySubReason',
          // scopedSlots: { customRender: 'notApplyReason' }
          customRender: (text, record) => {
            var text = text;
            if(text != undefined && text != null && text != '') {
              var pre = text.substring(0,1)
              var followUpNotApplySubReasons = [];
              switch (pre) {
                case 'e': followUpNotApplySubReasons = this.followUpNotApplySubReasonsE; break;
                case 'g': followUpNotApplySubReasons = this.followUpNotApplySubReasonsG; break;
                case 'i': followUpNotApplySubReasons = this.followUpNotApplySubReasonsI; break;
                default: followUpNotApplySubReasons = [];
              }
              if(followUpNotApplySubReasons != []) {
                for(var i in followUpNotApplySubReasons) {
                  if(text === followUpNotApplySubReasons[i].value) {
                    return followUpNotApplySubReasons[i][this.fieldName]
                  }
                }
              }
            } else {
              return '';
            }
          }
        },
        {
          title: this.$t(I18N_KEY + 'remark'),
          align: 'center',
          dataIndex: 'remark'
        },
        {
          title: this.$t('action'),
          dataIndex: 'action',
          align: 'center',
          fixed: 'right',
          width: 100,
          scopedSlots: { customRender: 'action' }
        }
      ]
    },
  },
  watch: {
    languageStorage: {
      handler (lang) {
        if (lang == 'en-US') {
          this.fieldName = 'textEn';
        } else if (lang == 'zh-CN') {
          this.fieldName = 'textCn';
        } else if (lang == 'es') {
          this.fieldName = 'textEs';
        } else {
          this.fieldName = 'textEn';
        }
      },
      immediate: true,
    }
  },
}
</script>
<style lang="less" scoped>
.file-box {
  float: left;
  width: 50px;
  height: 50px;
  margin-right: 10px;
  margin: 0 8px 8px 0;

  .file-item {
    width: 100%;
    height: 100%;
    position: relative;
    border: 1px solid #d9d9d9;
    border-radius: 4px;

    .file-preview {
      padding: 8px;
      width: 182px;
      height: 142px;
    }

    .file-label {
      margin: 0;
      text-align: center;
      border-bottom: 1px solid #d9d9d9;
      padding: 8px;
      font-weight: 500;
      font-size: 16px;
    }
  }
}
</style>