<template>
  <div>
    <a-form layout="inline" @keyup.enter.native="searchQuery">
      <a-row :gutter="24">
        <a-col :md="6" :sm="8">
          <a-form-item :label="$t(I18N_KEY + 'dictType')">
            <a-input :placeholder="$t('enter') + ' ' + $t(I18N_KEY + 'dictType')" v-model="form.dictType"></a-input>
          </a-form-item>
        </a-col>
        <a-col :md="6" :sm="8">
          <a-form-item :label="$t(I18N_KEY + 'dictKey')">
            <a-input :placeholder="$t('enter') + ' ' + $t(I18N_KEY + 'dictKey')" v-model="form.dictKey"></a-input>
          </a-form-item>
        </a-col>
        <a-col :md="6" :sm="8">
          <a-form-item :label="$t(I18N_KEY + 'dictStatus')">
            <a-input :placeholder="$t('enter') + ' ' + $t(I18N_KEY + 'dictStatus')" v-model="form.status"></a-input>
          </a-form-item>
        </a-col>
        <span style="float: left; margin-top: 5px; display: block; margin-bottom: 24px;white-space: nowrap; overflow: hidden;" class="table-page-search-submitButtons">
            <a-col :md="6" :sm="24">
                <a-button type="primary" @click="searchQuery">{{ $t('search') }}</a-button>
                <a-button style="margin-left: 8px" @click="searchReset">{{ $t('reset') }}</a-button>
                <a-button style="margin-left: 8px" @click="reload">{{ $t('reload') }}</a-button>
            </a-col>
        </span>
      </a-row>
    </a-form>
    <div class="table-operator"  style="margin-top: 5px">
      <a-button @click="handleAdd" type="primary" icon="plus">{{ $t('add') }}</a-button>
    </div>
    <a-table
      ref="table"
      size="middle"
      rowKey="id"
      :columns="columns"
      :dataSource="dataSource"
      :pagination="false"
      :loading="loading"
      bordered>
      <template #status="text,record,index">{{$t(I18N_KEY + 'dictStatus_' + text)}}</template>
      <span slot="action" slot-scope="text, record">
        <a @click="handleEdit(record)">{{ $t('edit') }}</a>
        <a-divider type="vertical" />
        <a-dropdown>
            <a class="ant-dropdown-link">{{ $t('more') }} <a-icon type="down" /></a>
            <a-menu slot="overlay">
                <a-menu-item>
                    <a-popconfirm :title="$t('confirmDelete')" @confirm="() => deleteCurrent(record)">
                        <a>{{ $t('delete') }}</a>
                    </a-popconfirm>
                </a-menu-item>
            </a-menu>
        </a-dropdown>
      </span>
    </a-table>
    <div style="margin-top: 15px; text-align: right;">
      <v-pagination
        v-if="total"
        :page-num="form.pageNum"
        :page-size="form.pageSize"
        :total="total"
        @change="handleTableChange" />
    </div>
    <dict-modal ref="modalForm" @ok="modalFormOk"></dict-modal>
  </div>
</template>
<script>
import { dictList, dictOperate, dictDelete,dictReload} from '@/webpublicapi/custServiceWorkOrder';
import DictModal from './modules/DictModal'

const I18N_KEY = 'custServiceWorkOrder.';
export default {
  components: {
    DictModal,
  },
  data () {
    return {
      I18N_KEY,
      loading: false,
      total: 0,
      form: {
        pageNum: 1,
        pageSize: 10,
        dictType: undefined,
        dictKey: undefined,
        status: undefined,
      },
      // DICT_STATUS: {
      //   0: '启用',
      //   1: '禁用',
      // },
      visible: false,
      dataSource: [],
    }
  },
  computed: {
    // 表头
    columns () {
      return [
        {
          title: this.$t(I18N_KEY + 'id'),
          dataIndex: 'id',
          width:60,
          align:"center",
        },
        {
          title: this.$t(I18N_KEY + 'dictType'),
          align:"center",
          dataIndex: 'dictType',
        },
        {
          title: this.$t(I18N_KEY + 'dictKey'),
          align:"center",
          dataIndex: 'dictKey',
        },
        {
          title: this.$t(I18N_KEY + 'dictValue'),
          align:"center",
          dataIndex: 'dictValue',
          ellipsis: true,
        },
        {
          title: this.$t(I18N_KEY + 'dictStatus'),
          dataIndex: 'status',
          align:"center",
          scopedSlots: {customRender: 'status'}
          // customRender: (text, record, index) => {
          //   return this.DICT_STATUS[text];
          // },
        },
        {
          title: this.$t(I18N_KEY + 'dictSort'),
          align:"center",
          dataIndex: 'sort',
        },
        {
          title: this.$t(I18N_KEY + 'remark'),
          align:"center",
          dataIndex: 'remark',
        },
        {
          title: this.$t(I18N_KEY + 'dictTextCn'),
          align:"center",
          dataIndex: 'dictTextCn',
        },
        {
          title: this.$t(I18N_KEY + 'dictTextEn'),
          align:"center",
          dataIndex: 'dictTextEn',
        },
        {
          title: this.$t(I18N_KEY + 'dictTextEs'),
          align:"center",
          dataIndex: 'dictTextEs',
        },
        {
          title: this.$t(I18N_KEY + 'dictTextFr'),
          align:"center",
          dataIndex: 'dictTextFr',
        },
        {
          title: this.$t(I18N_KEY + 'createTime'),
          align:"center",
          dataIndex: 'createTime',
        },
        {
          title: this.$t(I18N_KEY + 'updateTime'),
          align:"center",
          dataIndex: 'updateTime',
        },
        {
          title: this.$t(I18N_KEY + 'operate'),
          dataIndex: 'action',
          align:"center",
          scopedSlots: { customRender: 'action' },
        }
      ]
    },
  },
  created () {
    this.loadData('');
  },
  methods: {
    searchQuery () {
      this.loadData(1)
    },
    handleTableChange (pageNum, pageSize) {
      this.form.pageNum = pageNum;
      this.form.pageSize = pageSize;
      this.loadData();
    },
    modalFormOk(data) {
      this.createDict(data);
    },
    handleAdd () {
      this.dictId = undefined;
      this.$refs.modalForm.add();
      this.$refs.modalForm.title = "1";
      this.$refs.modalForm.disableSubmit = false;
    },
    handleEdit (record) {
      this.dictId = record.id;
      this.$refs.modalForm.edit(record);
      this.$refs.modalForm.title = "2";
      this.$refs.modalForm.disableSubmit = false;
    },
    changeModal (visible) {
      this.visible = visible;
    },
    deleteCurrent (row) {
      this.loading = true;
      dictDelete(row.id).then(res => {
        this.$message.success(this.$t('success'));
        this.loadData(1);
      }).catch(e => {
        this.loading = false;
        this.$message.error(e.message);
      });
    },
    createDict (data) {
      console.log(data)
      const form = {
        ...data,
        id: this.dictId,
        opt: this.dictId ? '2' : '1',
      };
      dictOperate(form).then(res => {
        this.$refs.modalForm.closeLoading();
        this.$refs.modalForm.close();
        this.$message.success('success');
        this.loadData(1);
      }).catch(e => {
        this.$refs.modalForm.closeLoading();
        this.$message.error(e.message);
      })
    },
    searchReset () {
      this.form = {
        dictType: undefined,
        dictKey: undefined,
        status: undefined,
        pageNum: 1,
        pageSize: 10,
      }
    },
    reload(){
      this.loading = true;
      dictReload().then(res => {
        this.loading = false;
        this.$message.info("success");
      }).catch(e => {
        this.loading = false;
        this.$message.error(e.message);
      });
    },
    loadData (pageNum) {
      if(pageNum) { this.form.pageNum = pageNum; }
      this.loading = true;
      dictList(this.form).then(res => {
        this.loading = false;
        this.total = res.data.total
        this.dataSource = res.data.rows;
      }).catch(e => {
        this.loading = false;
        this.$message.error(e.message);
      });
    }
  }
}
</script>
<style scoped>
@import '~@/assets/less/common.less';
</style>
