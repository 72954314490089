import { render, staticRenderFns } from "./CollectionPerformanceZuzhang.vue?vue&type=template&id=396f6cb6&scoped=true&"
import script from "./CollectionPerformanceZuzhang.vue?vue&type=script&lang=js&"
export * from "./CollectionPerformanceZuzhang.vue?vue&type=script&lang=js&"
import style0 from "./CollectionPerformanceZuzhang.vue?vue&type=style&index=0&id=396f6cb6&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "396f6cb6",
  null
  
)

export default component.exports