import api from './index'
import { axios } from '@/utils/request'

export function getAccountList(parameter = {}) {
  return axios({
    url: '/account/list',
    method: 'post',
    data: parameter
  })
}

export function addAccount(parameter = {}) {
  return axios({
    url: '/account/add',
    method: 'post',
    data: parameter
  })
}

export function editAccount(parameter = {}) {
  return axios({
    url: '/account/edit',
    method: 'post',
    data: parameter
  })
}

export function deleteAccount(parameter = {}) {
  return axios({
    url: '/account/delete',
    method: 'post',
    data: parameter
  })
}

export function getProductList(parameter = {}) {
  return axios({
    url: '/product/list',
    method: 'post',
    data: parameter
  })
}

export function getProductItemList(parameter = {}) {
  return axios({
    url: '/product/case/list',
    method: 'post',
    data: parameter
  })
}

export function addProductItem(parameter = {}) {
  return axios({
    url: '/product/case/add',
    method: 'post',
    data: parameter
  })
}

export function editProductItem(parameter = {}) {
  return axios({
    url: '/product/case/edit',
    method: 'post',
    data: parameter
  })
}

export function addProduct(parameter = {}) {
  return axios({
    url: '/product/add',
    method: 'post',
    data: parameter
  })
}

export function editProduct(parameter = {}) {
  return axios({
    url: '/product/update',
    method: 'post',
    data: parameter
  })
}

export function addProductConfig(parameter = {}) {
  return axios({
    url: '/normalConfig/add',
    method: 'post',
    data: parameter
  })
}

export function editProductConfig(parameter = {}) {
  return axios({
    url: '/normalConfig/edit',
    method: 'post',
    data: parameter
  })
}

export function getProductConfig(parameter = {}) {
  return axios({
    url: '/normalConfig/list',
    method: 'post',
    data: parameter
  })
}

export function getProductFeeConfigList(parameter = {}) {
  return axios({
    url: '/product/config/list',
    method: 'post',
    data: parameter
  })
}

export function addProductFeeConfig(parameter = {}) {
  return axios({
    url: '/product/config/add',
    method: 'post',
    data: parameter
  })
}

export function editProductFeeConfig(parameter = {}) {
  return axios({
    url: '/product/config/update',
    method: 'post',
    data: parameter
  })
}


export function getQueueList(parameter = {}) {
  return axios({
    url: '/queue/list',
    method: 'post',
    data: parameter
  })
}

export function addQueueList(parameter = {}) {
  return axios({
    url: '/queue/add',
    method: 'post',
    data: parameter
  })
}

export function deleteQueueList(parameter = {}) {
  return axios({
    url: '/queue/delete ',
    method: 'post',
    data: parameter
  })
}

export function getCouponList(parameter = {}) {
  return axios({
    url: '/coupon/list',
    method: 'post',
    data: parameter
  })
}

export function addCoupon(parameter = {}) {
  return axios({
    url: '/coupon/add',
    method: 'post',
    data: parameter
  })
}

export function editCoupon(parameter = {}) {
  return axios({
    url: '/coupon/update',
    method: 'post',
    data: parameter
  })
}

export function getCouponTaskList(parameter = {}) {
  return axios({
    url: '/coupon/task/list',
    method: 'post',
    data: parameter
  })
}

export function addCouponTask(parameter = {}) {
  return axios({
    url: '/coupon/task/add',
    method: 'post',
    data: parameter
  })
}

export function editCouponTask(parameter = {}) {
  return axios({
    url: '/coupon/task/update',
    method: 'post',
    data: parameter
  })
}

export function executeTask(parameter = {}) {
  return axios({
    url: '/coupon/executeTask',
    method: 'post',
    data: parameter
  })
}



export function listSendGroup(parameter = {}) {
  return axios({
    url: '/coupon/task/listSendGroup',
    method: 'post',
    data: parameter
  })
}

export function getTemplateList(parameter = {}) {
  return axios({
    url: '/smsTemplate/list',
    method: 'post',
    data: parameter
  })
}

export function addOrEditTemplate(parameter = {}) {
  return axios({
    url: '/smsTemplate/addOrEdit',
    method: 'post',
    data: parameter
  })
}


export function getSmsTaskList(parameter = {}) {
  return axios({
    url: '/smsTemplate/task/list',
    method: 'post',
    data: parameter
  })
}

export function addOrEditSmsTask(parameter = {}) {
  return axios({
    url: '/smsTemplate/task/addOrEdit',
    method: 'post',
    data: parameter
  })
}

export function listSmsSendGroup(parameter = {}) {
  return axios({
    url: '/smsTemplate/task/listSendGroup',
    method: 'post',
    data: parameter
  })
}

export function listSmsChannel(parameter = {}) {
  return axios({
    url: '/smsTemplate/task/smsChannel',
    method: 'post',
    data: parameter
  })
}

export function listSmsTemplate(parameter = {}) {
  return axios({
    url: '/smsTemplate/task/listSmsTemplate',
    method: 'post',
    data: parameter
  })
}

export function listSendGroupAll(parameter = {}) {
  return axios({
    url: '/sendGroup/list',
    method: 'post',
    data: parameter
  })
}

export function editSendGroup(parameter = {}) {
  return axios({
    url: '/sendGroup/addOrEdit',
    method: 'post',
    data: parameter
  })
}

export function getProductFeeConfigFixedList(parameter = {}) {
  return axios({
    url: '/product/config/fixed/list',
    method: 'post',
    data: parameter
  })
}

