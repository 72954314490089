import { render, staticRenderFns } from "./InstallmentProductManagementAso.vue?vue&type=template&id=25bcf1ae&scoped=true&"
import script from "./InstallmentProductManagementAso.vue?vue&type=script&lang=js&"
export * from "./InstallmentProductManagementAso.vue?vue&type=script&lang=js&"
import style0 from "./InstallmentProductManagementAso.vue?vue&type=style&index=0&id=25bcf1ae&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "25bcf1ae",
  null
  
)

export default component.exports