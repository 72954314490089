import { render, staticRenderFns } from "./Overduelist.vue?vue&type=template&id=94f86106&scoped=true&"
import script from "./Overduelist.vue?vue&type=script&lang=js&"
export * from "./Overduelist.vue?vue&type=script&lang=js&"
import style0 from "./Overduelist.vue?vue&type=style&index=0&id=94f86106&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "94f86106",
  null
  
)

export default component.exports