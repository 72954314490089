<template>
  <div :bordered="false">
    <div class="table-page-search-wrapper">
      <a-form layout="inline" @keyup.enter.native="searchQuery">
        <a-row :gutter="12">
          <a-col :md="6" :sm="8">
            <a-form-item :label="$t(I18N_KEY.ORDER + 'orderNo')">
              <a-input :placeholder="$t('enter') + ' ' + transformStringToLow($t(I18N_KEY.ORDER + 'orderNo'))" v-model="queryParam.orderNo"></a-input>
            </a-form-item>
          </a-col>
          <a-col :md="10" :sm="10">
             <a-form-item :label="$t(I18N_KEY.COLLECTION + 'endTime')">
                <a-range-picker
                   v-model="queryParam.endTime"
                   format="YYYY-MM-DD"
                   style="width: 100%;"/>
             </a-form-item>
          </a-col>

        <template v-if="toggleSearchStatus">
          <a-col :md="6" :sm="8">
             <a-form-item :label="$t(I18N_KEY.COLLECTION + 'collectionTaskStatus')">
              <dict-select
                v-model="queryParam.collectionTaskStatus"
                :dict-code="DICT_CODE.COLLECTION_TASK_STATUS"
                :begin="[{ value: '', label: '全部', labelEn: 'All' }]"
                :placeholder="$t('select')"
              />
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="8">
            <a-form-item :label="$t(I18N_KEY.CUSTOMER + 'custName')">
               <a-input :placeholder="$t('enter') + ' ' + transformStringToLow($t(I18N_KEY.CUSTOMER + 'custName'))" v-model="queryParam.aadhaarName"></a-input>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="8">
            <a-form-item :label="$t(I18N_KEY.CUSTOMER + 'mobile')">
                <a-input :placeholder="$t('enter') + ' ' + transformStringToLow($t(I18N_KEY.CUSTOMER + 'mobile'))" v-model="queryParam.mobileNo"></a-input>
            </a-form-item>
          </a-col>
           <a-col :md="6" :sm="8">
                <a-form-item :label="$t(I18N_KEY.COLLECTION + 'followUserName')">
                          <a-input :placeholder="$t('enter') + ' ' + transformStringToLow($t(I18N_KEY.COLLECTION + 'followUserName'))" v-model="queryParam.followUserName"></a-input>
                 </a-form-item>
            </a-col>
          <a-col :md="6" :sm="8">
            <a-form-item :label="$t(I18N_KEY.ORDER + 'overdueDays')">
              <a-input :placeholder="$t('enter') + ' ' + transformStringToLow($t(I18N_KEY.ORDER + 'overdueDays'))" v-model="queryParam.overdueDaysStart"></a-input>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="8">
            <a-form-item :label="$t(I18N_KEY.COLLECTION + 'repaymentIntention')">
              <dict-select
                v-model="queryParam.repaymentIntention"
                :dict-code="DICT_CODE.REAPYMENT_INTENTION"
                :begin="[{ value: '', label: '全部', labelEn: 'All' }]"
                :placeholder="$t('select')"
              />
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="8">
            <a-form-item :label="$t(I18N_KEY.COLLECTION + 'followResult')">
              <dict-select
                v-model="queryParam.followResult"
                :dict-code="DICT_CODE.FOLLOW_RESULT"
                :begin="[{ value: '', label: '全部', labelEn: 'All' }]"
                :placeholder="$t('select')"
              />
            </a-form-item>
          </a-col>
          </template>
          <a-col :md="6" :sm="8">
            <span style="float: left;overflow: hidden;" class="table-page-search-submitButtons">
              <a-button type="primary" @click="searchQuery" icon="search">{{ $t('search') }}</a-button>
              <a-button type="primary" @click="searchReset" icon="reload" style="margin-left: 8px">{{ $t('reset') }}</a-button>
               <a @click="handleToggleSearch" style="margin-left: 8px">
                     {{ $t(toggleSearchStatus ? 'hide' : 'show') }}
                    <a-icon :type="toggleSearchStatus ? 'up' : 'down'"/>
                  </a>
            </span>
          </a-col>
        </a-row>
      </a-form>




      <a-button style="margin-bottom: 20px;" type="primary" @click="openBatchDialog" :disabled="!selectedList.length" icon="plus">{{ $t(I18N_KEY.COLLECTION + 'batchReplacementFollowStaff') }}</a-button>

      <a-table
        ref="table"
        size="middle"
        rowKey="taskId"
        :columns="columns"
        :dataSource="dataSource"
        :pagination="false"
        :loading="loading"
        :rowSelection="{ onChange: selectRowsChange, selectedRowKeys }"
        bordered>
        <Dict slot="followResult" slot-scope="text" :dict-code="DICT_CODE.FOLLOW_RESULT" :value="text"/>
        <Dict slot="repaymentIntention" slot-scope="text" :dict-code="DICT_CODE.REAPYMENT_INTENTION" :value="text"/>
        <Dict slot="collectionTaskStatus" slot-scope="text" :dict-code="DICT_CODE.COLLECTION_TASK_STATUS" :value="text"/>

        <span slot="action" slot-scope="text, record">
          <!-- slot-scope="text, record" -->
            <a @click="handleDetail(record)">{{ $t('view') }}</a>
              <a-divider type="vertical"/>
            <a v-if="record.collectionTaskStatus == '0'||record.collectionTaskStatus == '1'" @click="handleUser(record)">{{ $t(I18N_KEY.COLLECTION + 'transferOrder') }}</a>
        </span>
      </a-table>
      <div style="margin-top: 15px; text-align: right;">
          <v-pagination
              v-if="total"
              :page-num="form.pageNum"
              :page-size="form.pageSize"
              :total="total"
              @change="handleTableChange" />
      </div>
    </div>




    <v-modal
        v-model="visible"
        :title="$t('order.collectionDetail')">
        <div style="padding: 20px;">
            <collection-detail
              v-if="visible"
              :orderId="orderId"
              :custInfoId="custInfoId"
              :taskId="taskId"
              :auditDrawer="false"
              @close="visible = false;"/>
        </div>
    </v-modal>
    <a-modal
        v-model="visibleT"
        :title="$t(I18N_KEY.COLLECTION + 'transferOrder')"
        @ok="handleOk"
        :confirmLoading="confirmLoading"
        @cancel="handleCancel"
        >
          <a-radio-group @change="onChange" v-model="selectUser">
            <a-radio v-for="(item) in ExamineUser" :key="item.userId" :style="radioStyle" :value="item.userId">{{item.userName}}</a-radio>
        </a-radio-group>
    </a-modal>
    <a-modal
        v-model="batchVisible"
        :title="$t(I18N_KEY.COLLECTION)"
        :confirmLoading="confirmLoading"
        :width="700"
        @ok="submitBatch"
        @cancel="closeBatchDialog"
        >
        <div>
          <div>
            <p class="description d_title">{{ $t('platformCollection.instructions_for_filling_in')  }}</p>
            <p class="description d_text">{{ $t('platformCollection.instructions_for_filling_in_1')  }}</p>
            <p class="description d_text">{{ $t('platformCollection.instructions_for_filling_in_2')  }}</p>
          </div>
          <p class="description d_high_light">{{ $t('platformCollection.total_order_quantity_checked')  }}{{ selectedList.length }}</p>
          <a-table
            ref="table"
            rowKey="id"
            size="middle"
            :columns="batchColumns"
            :dataSource="batchList"
            :pagination="false"
            :loading="batchLoading"
            bordered>
            <span slot="action" slot-scope="text, record">
                <a-input v-model="record.taskNum" />
            </span>
          </a-table>
        </div>
    </a-modal>
  </div>
</template>

<script>
import { filterObj } from '@/utils/util'
import { getAllCollectionTask, getCollectionUser, modCollectionTaskUser } from '@/webpublicapi/collection'
import Dict from '../modules/Dict';
import DictSelect from '../modules/DictSelect';
import moment from 'moment'
import vModal from '../modules/Modal';
import CollectionDetail from './CollectionDetail';
import _ from 'lodash';
import { mapActions, mapGetters } from 'vuex'

const I18N_KEY = {
  ORDER: 'order.',
  CUSTOMER: 'customer.',
  COLLECTION: 'collection.'
}

export default {
  name: 'AllApprovalTask',
  components: {
    Dict,
    DictSelect,
    vModal,
    CollectionDetail,
  },
  data () {
    return {
      I18N_KEY,
      toggleSearchStatus:false,
      confirmLoading: false,
      visible: false,
      visibleT: false,
      orderId: undefined,
      custInfoId: undefined,
      taskId: undefined,
      batchVisible: false,
      batchLoading: false,
      selectedRowKeys: [],
      selectedList: [],
      // 分页参数
      form: {
        pageNum: 1,
        pageSize: 10,
      },
      total: 0,
      dataSource: [],
      // 查询条件
      queryParam: {
        "orderNo":"",
        "aadhaarName":"",
        "mobileNo":"",
        collectionTaskStatus:'',
        endTime: undefined,
      },
      loading: false,
      isorter: {
        column: 'createTime',
        order: 'desc'
      },
      labelCol: {
        xs: { span: 8 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 16 },
        sm: { span: 19 }
      },
      radioStyle: {
        display: 'block',
        height: '30px',
        lineHeight: '30px',
      },
      selectUser: '',
      ExamineUser: [],
      taskId: ''
    }
  },
  computed: {
    ...mapGetters('collection',['listUserWithNum']),
    batchList () {
      return this.listUserWithNum || [];
    },
    columns () {
      return [
        {
          title: this.$t('order.orderNo'),
          align: 'left',
          dataIndex: 'orderNo'
        },
        {
          title:  this.$t('customer.custName'),
          align: 'left',
          dataIndex: 'aadhaarName'
        },
        {
          title: this.$t('order.overdueDays'),
          align: 'left',
          dataIndex: 'overdueDay'
        },
        {
          title:this.$t('collection.createTime'),
          align: 'left',
          dataIndex: 'createTime'
        },
        {
          title: this.$t('collection.followNum'),
          align: 'left',
          dataIndex: 'followNum'
        },
        {
          title: this.$t('collection.followUserName'),
          align: 'left',
          dataIndex: 'followUserName',
        },
        {
          title: this.$t('collection.followResult'),
          align: 'left',
          dataIndex: 'followResult',
          scopedSlots: { customRender: 'followResult' }
        },
        {
          title: this.$t('collection.repaymentIntention'),
          align: 'left',
          dataIndex: 'repaymentIntention',
          scopedSlots: { customRender: 'repaymentIntention' }
        },
        {
          title: this.$t('collection.collectionTaskStatus'),
          align: 'left',
          dataIndex: 'collectionTaskStatus',
          scopedSlots: { customRender: 'collectionTaskStatus' }
        },
        {
          title: this.$t('action'),
          dataIndex: 'action',
          align: 'center',
          scopedSlots: { customRender: 'action' }
        }
      ]
    },
    batchColumns () {
      return [
        {
          title: this.$t('name'),
          align: 'left',
          dataIndex: 'userName'
        },
        {
          title: this.$t('order.followingUpOrder'),
          align: 'left',
          dataIndex: 'undoNum'
        },
        {
          title: this.$t('action'),
          dataIndex: 'action',
          align: 'center',
          scopedSlots: { customRender: 'action' }
        }
      ]
    }
  },
  created () {
    this.getDict();
    this.loadData(1)
  },
  methods: {
    ...mapActions('collection', ['getListUserWithNum', 'batchChangeUser']),
    handleToggleSearch(){
      this.toggleSearchStatus = !this.toggleSearchStatus;
    },
    searchQuery () {
      this.loadData(1)
    },
    loadData (arg) {
      // 清空选中项
      this.selectedList = [];
      this.selectedRowKeys = [];
      // 加载数据 若传入参数1则加载第一页的内容
      if (arg === 1) {
        this.form.pageNum = 1
      }
      this.loading = true
      var params = this.getQueryParams()// 查询条件
      getAllCollectionTask(Object.assign({}, params)).then((res)=>{
        if(res.code===1000){
          this.total = res.data.total
          this.dataSource = res.data.rows
        }else{
          this.total = 0
          this.dataSource = []
          this.$message.error(res.msg)
        }
        this.loading = false
      }).catch(e => {
            this.loading = false;
            this.$message.error(e.message);
      })
    },
    transformTime (time) {
      return moment(time).format('YYYY-MM-DD')
    },
    transformStringToLow (str) {
        return str && str.toLocaleLowerCase();
    },
    selectRowsChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys;
      this.originSelectedList = selectedRows;
      this.selectedList = _.cloneDeep(selectedRows);
    },
    openBatchDialog () {
      this.batchVisible = true;
      this.batchLoading = true;
      this.selectedList.length && this.getListUserWithNum().finally(res => {
        this.batchLoading = false;
      })
    },
    closeBatchDialog () {
      this.batchVisible = false;
      this.selectedList = _.cloneDeep(this.originSelectedList || []);
    },
    submitBatch () {
      const taskIds = this.selectedList.map(t => t.taskId);
      const userTask = this.batchList.map(t => ({ userId: t.userId, taskNum: t.taskNum ? +t.taskNum : 0 }));
      const totalNum = userTask.map(t => t.taskNum).reduce((total, num ) => (total += num), 0);
      console.log(userTask, totalNum);
      if (!userTask.length) {
        return this.closeBatchDialog();
      }
      if (isNaN(totalNum)) {
        return this.$message.error(this.$t('please_enter_a_positive_integer'));
      }
      if (totalNum > this.selectedList.length) {
        return this.$message.error(this.$t('platformCollection.cannot_be_greater_than_the_total_quantity_of_the_checked_order'));
      }
      this.closeBatchDialog();
      this.loading = true;
      this.batchChangeUser({ taskIds, userTask }).then(res => {
        this.loadData();
        this.$message.success(this.$t('success'));
      }).finally(res => {
        this.loading = false;
      });
    },
    getQueryParams () {
      const { endTime } = this.queryParam;
      const data = {
          ...this.queryParam,
          endDateStart: (endTime && endTime.length) ? this.transformTime(endTime[0]) : undefined,
          endDateEnd : (endTime && endTime.length) ? this.transformTime(endTime[1]) : undefined,
      }
      delete data.endTime;
      var param = Object.assign({}, data)
      param.pageNum = this.form.pageNum;
      param.pageSize = this.form.pageSize;
      return filterObj(param)
    },
    handleTableChange (pageNum, pageSize) {
        this.form.pageNum = pageNum;
        this.form.pageSize = pageSize;
        this.loadData();
    },
    // 重置字典类型搜索框的内容
    searchReset () {
      var that = this
      that.queryParam.orderNo = ''
       that.queryParam.aadhaarName = ''
       that.queryParam.mobileNo = ''
       that.queryParam.endTime = undefined;
      that.loadData(this.form.pageNum)
    },
    changeModal (visible) {
        this.visible = visible;
    },
    handleDetail (row) {
        this.orderId = row.orderId;
        this.custInfoId = row.custInfoId;
        this.taskId = row.taskId;
        this.changeModal(true);
    },
    handleUser(row){
      this.selectUser = ''
      this.taskId = row.taskId
      this.confirmLoading = true;
      if(this.ExamineUser.length ==0){
        getCollectionUser().then((res)=>{
          if(res.code===1000){
            this.ExamineUser = res.data
          }else{
            this.ExamineUser = []
            this.$message.error(res.msg)
          }
        this.confirmLoading = false;
        }).catch(e => {
            this.confirmLoading = false;
            this.$message.error(e.message);
        })
      }else{
        this.confirmLoading = false;
      }
      this.visibleT = true
    },
    onChange(e) {
      this.selectUser = e.target.value
    },
    handleOk(e) {
       if(this.selectUser!=''){
        this.confirmLoading = true;
        modCollectionTaskUser({userId:this.selectUser,taskId:this.taskId}).then((res)=>{
          if(res.code===1000){
            this.visibleT = false;
            this.confirmLoading = false;
            this.loadData();
          }else{
            this.confirmLoading = false;
            this.$message.error(res.msg)
          }
        }).catch(e => {
           this.confirmLoading = false;
            this.$message.error(e.message);
      })
       }else{
         this.$message.error(this.$t('platformCollection.please_select_a_credit_examiner'))
       }
      },
      handleCancel(e) {
        this.visibleT = false;
      }
  }
}
</script>
<style lang="less" scoped>
 @import "~@/assets/less/common.less";
 .description {
  line-height: 1.4;
  &.d_text {
    text-indent: 1em;
  }
  &.d_high_light {
    color: #fd9a38;
  }
 }
</style>
