<template>
    <div class="customerDetail">
        <a-spin :spinning="loading">
            <a-card
                :bordered="false"
                type="inner">
                <a-card

                        :title="$t(I18N_KEY + 'customerBasicInfo')"
                        >
                    <detail-list>
                        <detail-list-item :term="$t(I18N_KEY + 'firstName')">{{ basicInfo.firstName }}</detail-list-item>
                        <detail-list-item :term="$t(I18N_KEY + 'middleName')">{{ basicInfo.middleName }}</detail-list-item>
                        <detail-list-item :term="$t(I18N_KEY + 'lastName')">{{ basicInfo.lastName }}</detail-list-item>
                        <detail-list-item :term="$t(I18N_KEY + 'mobile')">{{ basicInfo.mobileNo }}</detail-list-item>
                        <detail-list-item :term="$t(I18N_KEY + 'curp')">{{ basicInfo.curp }}</detail-list-item>
                        <detail-list-item :term="$t('sex')">{{ $t(SEX[basicInfo.sex]) }}</detail-list-item>
                        <detail-list-item :term="$t(I18N_KEY + 'bankAccountName')">{{ bankCardInfo.bankAccountName }}</detail-list-item>
                        <detail-list-item :term="$t(I18N_KEY + 'bankAccountNo')">{{ bankCardInfo.bankAccountNo }}</detail-list-item>
                        <detail-list-item :term="$t('birthday')">{{ basicInfo.birthday }}</detail-list-item>
                        <detail-list-item :term="$t(I18N_KEY + 'age')">{{ basicInfo.age }}</detail-list-item>
                        <detail-list-item :term="$t(I18N_KEY + 'reborrowFlag')">{{ $t(REBORROW_FLAG[basicInfo.reborrowFlag]) }}</detail-list-item>
                        <detail-list-item :term="$t(I18N_KEY + 'createTime')">{{ basicInfo.createdTime }}</detail-list-item>
                    </detail-list>
                </a-card>
                <a-card
                    style="margin-top: 24px"
                    :title="$t(I18N_KEY + 'customerDetailInfo')"
                    type="inner">
                    <detail-list>
                      <detail-list-item :term="$t(I18N_KEY + 'maritalStatus')" label="婚姻状况"><Dict :dict-code="DICT_CODE.MARITAL_STATUS" :value="detailInfo.maritalStatus"/></detail-list-item>
                      <detail-list-item :term="$t(I18N_KEY + 'educationLevel')" label="教育程度"><Dict :dict-code="DICT_CODE.EDUCATION" :value="detailInfo.education"/></detail-list-item>
                      <detail-list-item :term="$t(I18N_KEY + 'personalMonthlyIncome')" label="个人月收入"><Dict :dict-code="DICT_CODE.MONTHLY_INCOME" :value="detailInfo.monthlyIncomeScope"/></detail-list-item>
                      <detail-list-item :term="$t(I18N_KEY + 'residentialAddressPIN')" label="居住地址PIN">{{ detailInfo.residentialAddressPinCode }}</detail-list-item>
                      <detail-list-item :term="$t(I18N_KEY + 'workType')" label="工作类型"><Dict :dict-code="DICT_CODE.WORK_TYPE" :value="detailInfo.workType"/></detail-list-item>
                      <detail-list-item :term="$t(I18N_KEY + 'companyName')" label="公司名称" v-if="detailInfo.companyName">{{ detailInfo.companyName }}</detail-list-item>
                      <detail-list-item :term="$t(I18N_KEY + 'workYear')" label="工作年限" v-if="detailInfo.workYears"><Dict :dict-code="DICT_CODE.WORK_YEARS" :value="detailInfo.workYears"/></detail-list-item>
                      <detail-list-item :term="$t(I18N_KEY + 'payMethod')" label="发薪方式1" v-if="detailInfo.payMethed">{{detailInfo.payMethed}}</detail-list-item>
                      <detail-list-item :term="$t(I18N_KEY + 'payMethod')" label="发薪方式2" v-if="detailInfo.payMethed1">{{detailInfo.payMethed1}}</detail-list-item>
                      <detail-list-item :term="$t(I18N_KEY + 'residentialAddress')" label="居住地址">{{ detailInfo.residentialAddress }}</detail-list-item>
                      <detail-list-item :term="$t(I18N_KEY + 'workingAddress')" label="工作地址" v-if="detailInfo.workAddress">{{ detailInfo.workAddress }}</detail-list-item>
                    </detail-list>
                </a-card>
                <a-card
                    style="margin-top: 24px"
                    :title="$t(I18N_KEY + 'customerLinkInfo')"
                    type="inner">
                    <a-table
                        :columns="linkColumns"
                        :dataSource="linkmanInfos"
                        :pagination="false"
                        bordered
                        ref="table"
                        size="middle"
                        rowKey="id">
                        <span slot="contactType" slot-scope="text" :value="text" >{{ $t(CONTACT_TYPE[text]) }}</span>
                        <Dict slot="relationType" slot-scope="text" :dict-code="DICT_CODE.REALTIONSHIP" :value="text" />
                    </a-table>
                </a-card>
                <!--<a-card-->
                    <!--style="margin-top: 24px"-->
                    <!--:title="$t(I18N_KEY + 'customerAadhaarInfo')"-->
                    <!--type="inner">-->
                    <!--<detail-list>-->
                        <!--<detail-list-item :term="$t(I18N_KEY + 'AadhaarNo')" label="Aadhaar卡号">{{ aadhaarInfo.ocrAadhaarNo }}</detail-list-item>-->
                        <!--<detail-list-item :term="$t(I18N_KEY + 'AadhaarName')" label="Aadhaar卡名称">{{ aadhaarInfo.ocrAadhaarName }}</detail-list-item>-->
                        <!--<detail-list-item :term="$t(I18N_KEY + 'AadhaarAddress')" label="Aadhaar卡地址">{{ aadhaarInfo.ocrAadhaarAddr }}</detail-list-item>-->
                        <!--<detail-list-item :term="$t(I18N_KEY + 'AadhaarYears')" label="Aadhaar卡年份">{{ aadhaarInfo.ocrAadhaarYear }}</detail-list-item>-->
                        <!--<detail-list-item :term="$t(I18N_KEY + 'AadhaarDate')" label="Aadhaar卡日期">{{ aadhaarInfo.ocrAadhaarBirthday }}</detail-list-item>-->
                    <!--</detail-list>-->
                <!--</a-card>-->
                <a-card
                    style="margin-top: 24px"
                    :title="$t(I18N_KEY + 'customerImageInfo')"
                    type="inner"
                    v-viewer="viewOptions">
                    <div v-for="(item, index) in imageInfos" :key="item.imgType">
                        <div class="file-box">
                            <div class="file-item">
                                <p class="file-label">{{ FILE_TYPE[item.imgType] }}</p>
                                <img class="file-preview" :src="item.imgUrl" preview="0">
                            </div>
                        </div>
                    </div>
                </a-card>
                <!-- <a-card
                    type="inner">
                         <a-tabs v-model="" @change="callback">
                            <a-tab-pane tab="Tab 1" key="1">Content of Tab Pane 1</a-tab-pane>
                            <a-tab-pane tab="Tab 2" key="2" forceRender>Content of Tab Pane 2</a-tab-pane>
                            <a-tab-pane tab="Tab 3" key="3">Content of Tab Pane 3</a-tab-pane>
                        </a-tabs>
                </a-card> -->
            </a-card>
        </a-spin>
    </div>
</template>
<script>
    import { DetailList } from '@/components';
    import { mapActions, mapGetters } from 'vuex';
    import { REBORROW_FLAG, SEX, CONTACT_TYPE } from '../../constants/status';
    import Dict from '../modules/Dict';
    import imageMixin from '../../mixins/image-mixin';

    const DetailListItem = DetailList.Item
    const I18N_KEY = 'customer.';
    export default {
        props: {
            id: {
                type: [String, Number],
                default: undefined,
            }
        },
        mixins: [imageMixin],
        components: {
            DetailList,
            DetailListItem,
            Dict,
        },
        data() {
            return {
                I18N_KEY,
                REBORROW_FLAG,
                SEX,
                CONTACT_TYPE,
                title: '客户详情',
                visible: false,
                loading: true,
                disableSubmit: false,
                modal: {
                    width: '100%',
                    style: { top: '20px' },
                    fullScreen: true
                },
                linkColumns: [
                    {
                        title: this.$t(I18N_KEY + 'contactType'),
                        dataIndex: 'contactType',
                        key: 'contactType',
                        align:"center",
                        scopedSlots: { customRender: 'contactType' }
                    },
                    {
                        title: this.$t(I18N_KEY + 'contactRelationship'),
                        dataIndex: 'relationType',
                        key: 'relationType',
                        align:"center",
                        scopedSlots: { customRender: 'relationType' }
                    },
                    {
                        title: this.$t(I18N_KEY + 'contactName'),
                        dataIndex: 'contactName',
                        key: 'contactName',
                        align:"center"
                    },
                    {
                        title: this.$t(I18N_KEY + 'contactPhone'),
                        dataIndex: 'contactPhone',
                        key: 'contactPhone',
                        align:"center"
                    },
                ],
                FILE_TYPE: {
                    'aadhaar_front': this.$t(I18N_KEY + 'aadhaarFront'),
                    'aadhaar_back': this.$t(I18N_KEY + 'aadhaarBack'),
                    'pan_front': this.$t(I18N_KEY + 'panFront'),
                    'face_photo_list': this.$t(I18N_KEY + 'facePhotoList'),
                },
            };
        },
        computed: {
            ...mapGetters('customer', [
                'customerDetail',
            ]),
            basicInfo () {
                return (this.customerDetail && this.customerDetail.basicInfo) || {};
            },
            detailInfo () {
                return (this.customerDetail && this.customerDetail.detailInfo) || {};
            },
            linkmanInfos () {
                return (this.customerDetail && this.customerDetail.linkmanInfos) || [];
            },
            aadhaarInfo () {
                return (this.customerDetail && this.customerDetail.aadhaarInfo) || {};
            },
            panInfo () {
                return (this.customerDetail && this.customerDetail.panInfo) || {};
            },
            imageInfos () {
                return (this.customerDetail && this.customerDetail.imageInfos) || [];
            },
            bankCardInfo () {
                return (this.customerDetail && this.customerDetail.bankCardInfo) || [];
            },
        },
        created () {
            this.requestDetail(this.id);
        },
        methods: {
            ...mapActions('customer', [
                'getCustomerDetail',
            ]),
            changeTab (tab) {
                this.active = tab;
                this.$router.push({
                    name: this.$route.name,
                    query: {
                        tab,
                    }
                })
            },
            drawContainer () {
                return document.querySelector('.ant-modal-wrap') || 'body';
            },
            activeTab () {
                this.active = this.$route.query.tab ? this.$route.query.tab : '1';
            },
            onTabChange (key) {
                this.noTitleKey = key
            },
            handleOk(){
                this.close()  
            },
            handleCancel(){
                this.close()
            },
            requestDetail (id) {
                this.getCustomerDetail({ id }).then(res => {
                    this.loading = false;
                }).catch(e => {
                    this.$message.error(e.message);
                })
            },
            edit(){
                this.visible =true
            },
            close () {
                this.$emit('close')
                this.visible = false
            },
        }
    }
</script>
<style lang="less" scoped>
    .tag {
        border-style: dashed;
        background: #fff;
    }
    
    .title {
        font-size: 14px;
        color: rgba(0,0,0,.65);
        font-weight: 400;
        margin-bottom: 12px;
    }

    .file-box {
        float: left;
        width: 200px;
        height: 200px;
        margin-right: 10px;
        margin: 0 8px 8px 0;

        .file-item {
            width: 100%;
            height: 100%;
            position: relative;
            border: 1px solid #d9d9d9;
            border-radius: 4px;

            .file-preview {
                padding: 8px;
                width: 182px;
                height: 142px;
            }

            .file-label {
                margin: 0;
                text-align: center;
                border-bottom: 1px solid #d9d9d9;
                padding: 8px;
                font-weight: 500;
                font-size: 16px;
            }
        }
    }

    /deep/ .description-list .content {
        font-weight: 700;
    }
</style>
<style lang="less">
    /* 修改滚动条样式 */
    div::-webkit-scrollbar {
        width: 10px;
        height: 10px;
    }

    div::-webkit-scrollbar-track {
        background: rgb(239, 239, 239);
        border-radius: 2px;
    }

    div::-webkit-scrollbar-thumb {
        background: #d8d8d8;
        border-radius: 10px;
    }

    div::-webkit-scrollbar-thumb:hover {
        background: #d8d8d8;
    }

    div::-webkit-scrollbar-corner {
        background: rgb(239, 239, 239);
    }
</style>
