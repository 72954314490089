<template>
  <div>
    <a-spin :spinning="loading">
      <a-card
        :title="$t(I18N_KEY + 'userDetail')"
        type="inner"
        style="margin-top: 24px;">
        <detail-list>
          <detail-list-item :term="$t(I18N_KEY + 'mobile')">
            <a :href="'sip:'+ teleMarketData.mobile">{{ teleMarketData.mobile }}</a>
            <a :href="'sip:'+ teleMarketData.mobile" style='margin-left: 20px'><a-button type="primary" icon='phone'>{{ $t(I18N_KEY + "call") }}</a-button></a>
          </detail-list-item>
          <detail-list-item :term="$t(I18N_KEY + 'email')">{{teleMarketData.email}}</detail-list-item>
        </detail-list>
      </a-card>

      <a-card
        :title="$t(I18N_KEY + 'userOperateHistory')"
        type="inner"
        style="margin-top: 24px;">
        <detail-list>
          <detail-list-item :term="$t(I18N_KEY + 'registerTime')">{{teleMarketData.registerTime}}</detail-list-item>
          <detail-list-item :term="$t(I18N_KEY + 'baseInfoSubmitTime')">{{teleMarketData.baseInfoSubmitTime}}</detail-list-item>
          <detail-list-item :term="$t(I18N_KEY + 'linkManSubmitTime')">{{teleMarketData.linkManSubmitTime}}</detail-list-item>
          <detail-list-item :term="$t(I18N_KEY + 'idInfoSubmitTime')">{{teleMarketData.idInfoSubmitTime}}</detail-list-item>
          <detail-list-item :term="$t(I18N_KEY + 'bankInfoSubmitTime')">{{teleMarketData.bankInfoSubmitTime}}</detail-list-item>
          <detail-list-item :term="$t(I18N_KEY + 'orderSubmitTime')">{{teleMarketData.orderSubmitTime}}</detail-list-item>
        </detail-list>
      </a-card>

      <a-card
        :title="$t(I18N_KEY + 'followUpHistory')"
        type="inner"
        style="margin-top: 24px;">

        <a-form :model='form' :rules='rules' layout='vertical' name='followUpForm'>
          <a-row :gutter='24'>
            <a-col :span='8'>
              <a-form-item :label='$t(I18N_KEY + "followUpStatus")' :required='required_true'>
                <a-checkbox-group  style="width:100%" v-model:value="form.followUpStatus">
                  <a-row :gutter='24'>
                    <a-col :span='12' v-for="item in followUpStatuses" v-bind:key="item.value">
                      <a-checkbox :value="item.value">{{$t(I18N_KEY + "followUpStatus_" + item.label)}}</a-checkbox>
                    </a-col>
                  </a-row>
                </a-checkbox-group>
              </a-form-item>
            </a-col>
            <a-col :span='8'>
              <a-form-item :label='$t(I18N_KEY + "notApplyReason")'>
                <a-radio-group style="width:100%" v-model:value="form.notApplyReason">
                  <a-row :gutter='24'>
                    <a-col :span='24' v-for="item in followUpNotApplyReasons" v-bind:key="item.value">
<!--                      <a-radio :value="item.value">{{$t(I18N_KEY + "notApplyReason_" + item.label)}}</a-radio>-->
                      <a-radio :value="item.value">{{item[fieldName]}}</a-radio>
                      <a-select v-model="form.notApplySubReasonE" :placeholder="$t('select')" style="width: 100%" v-if='item.value == "e" && form.notApplyReason == "e"'>
                        <a-select-option v-for='item1 in followUpNotApplySubReasonsE' :key='item1.value'>
                          {{item1[fieldName]}}
                        </a-select-option>
                      </a-select>
                      <a-select v-model="form.notApplySubReasonG" :placeholder="$t('select')" style="width: 100%" v-if='item.value == "g" && form.notApplyReason == "g"'>
                        <a-select-option v-for='item2 in followUpNotApplySubReasonsG' :key='item2.value'>
                          {{item2[fieldName]}}
                        </a-select-option>
                      </a-select>
                      <a-select v-model="form.notApplySubReasonI" :placeholder="$t('select')" style="width: 100%" v-if='item.value == "i" && form.notApplyReason == "i"'>
                        <a-select-option v-for='item3 in followUpNotApplySubReasonsI' :key='item3.value'>
                          {{item3[fieldName]}}
                        </a-select-option>
                      </a-select>
                    </a-col>
                  </a-row>
                </a-radio-group>
              </a-form-item>
            </a-col>

            <a-col :span='8'>
              <a-form-item :label='$t("remark")' :required='required_true'>
                <a-textarea :rows="3" :placeholder="$t('enter')" v-model:value="form.remark" />
<!--                <span style='color:red;'>闪退和按钮失效类Bug需提供手机型号和页面，其他类型需上传视频或截图</span>-->
                <span style='color:red;'>{{$t(I18N_KEY + "bugNotice")}}</span>
              </a-form-item>
            </a-col>

<!--            <a-col :span='12'>-->
<!--              &lt;!&ndash; 图片上传组件 &ndash;&gt;-->
<!--                <div class="btn_select" @click="selectLoadImg">-->
<!--                  <div>-->
<!--                    <input type="file" name="file" accept="image/*" @change="changeImage()" ref="avatarInput">-->
<!--                  </div>-->
<!--                </div>-->
<!--                <button type="submit" class="btn_submit" @click="upLoad">上传图片</button>-->
<!--            </a-col>-->

            <a-col :span='24'>
              <a-col :span='8'>
                <a-form-item :label='$t(I18N_KEY + "uploadImage")'>
                  <a-upload :customRequest='customRequest'
                            :remove='remove'
                            v-model:file-list="fileList">
                    <a-button v-if='fileList.length < 4'>{{ $t(I18N_KEY + "upload") }}</a-button>
                  </a-upload>
                </a-form-item>
              </a-col>
              <span style="float: right;overflow: hidden;" class="table-page-search-submitButtons">
                <a-button type="primary" @click="followUp">{{ $t(I18N_KEY + "followUp") }}</a-button>
              </span>
            </a-col>
          </a-row>
        </a-form>

      </a-card>
    </a-spin>
  </div>
</template>
<script>
import { DetailList } from '@/components';
import imageMixin from '@/mixins/image-mixin';
import { baseData, dataDetail, dataList, followUp, upload} from '@/webpublicapi/teleMarket'
import { filterObj } from '@/utils/util'
const DetailListItem = DetailList.Item
const I18N_KEY = 'teleMarket.';
import {mapGetters} from "vuex";
export default {
  name: 'TeleMarketDetail',
  props: {
    orderId: {
      type: [String, Number],
      default: undefined,
    },
    followUpStatuses: {
      type: Array,
      default: () => [],
    },
    followUpNotApplyReasons: {
      type: Array,
      default: () => [],
    },
    followUpNotApplySubReasonsE: {
      type: Array,
      default: () => [],
    },
    followUpNotApplySubReasonsG: {
      type: Array,
      default: () => [],
    },
    followUpNotApplySubReasonsI: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    DetailList,
    DetailListItem,
  },
  mixins: [imageMixin],
  data () {
    return {
      I18N_KEY,
      loading: false,
      visible: false,
      required_true: true,
      teleMarketData: {},
      // followUpRecords: [],
      workOrder: {},
      attachments: [],
      dataSource:[],
      imgCount: 0,
      fileList: [],
      rules :{
        followUpStatus: [{ required: true, message: 'Please select' }],
        notApplyReason: [{ required: true, message: 'Please select' }],
      },
      form: {
        followUpStatus: [],
        remark: '',
        attachment: '',
        notApplyReason: '',
        notApplySubReason: '',
        notApplySubReasonE: '',
        notApplySubReasonG: '',
        notApplySubReasonI: '',
      },
      imageLoading:[],
      fieldName: 'textCn',
    }
  },
  created () {
    this.requestDetail(this.orderId);
  },
  computed: {
    ...mapGetters('language', ['languageStorage']),
  },
  methods: {
    requestDetail (id) {
      dataDetail(id).then(res => {
        this.teleMarketData = res.data.data || {};
        this.dataSource = res.data.followUpRecords || [];
        this.loading = false;
      }).catch(e => {
        this.$message.error(e.message);
        this.loading = false;
      })
    },
    handleApp(app) {
      var app = app;
      for(var item in this.apps) {
        if(app === this.apps[item].appSsid) {
          return app+ '/' + this.apps[item].appName
        }
      }
      return app
    },
    handleDetail(record){
      this.attachments = record.attachmentUrl;
      this.visible = true;
    },
    getQueryParams() {
      var attacmentCodes = '';
      if(this.fileList.length > 0) {
        for(var item in this.fileList){
          var file = this.fileList[item];
          if(attacmentCodes === '') {
            attacmentCodes = file.attCode
          } else {
            attacmentCodes = attacmentCodes + ',' + file.attCode
          }
        }
        this.form.attachment = attacmentCodes
      }
      const data = {
        ...this.form,
        id: this.orderId,
      }
      var param = Object.assign({}, data)
      return filterObj(param)
    },
    followUp(){
      // 执行跟进操作
      // 校验图片是否上传完成
      console.log(this.imageLoading)
      for(var item in this.imageLoading) {
        if("loading" === this.imageLoading[item]) {
          this.$message.error(this.$t(I18N_KEY + 'imageLoadingNotice'))
          return;
        }
      }
      this.loading = true
      var params = this.getQueryParams()
      // console.log(params['followUpStatus'])
      if(params['followUpStatus'] == undefined || params['followUpStatus'].length == 0) {
        this.$message.error(this.$t(I18N_KEY + 'followUpNotice'))
        this.loading = false
        return;
      }
      if(params['remark'] == undefined || params['remark'] == null || params['remark'] == '') {
        this.$message.error(this.$t(I18N_KEY + 'remarkNotice'))
        this.loading = false
        return;
      }
      var pre = params['notApplyReason'];
      if((pre == 'e' && (params['notApplySubReasonE'] == '' || params['notApplySubReasonE'] == undefined))
        ||(pre == 'g' && (params['notApplySubReasonG'] == '' || params['notApplySubReasonG'] == undefined))
        ||(pre == 'i' && (params['notApplySubReasonI'] == '' || params['notApplySubReasonI'] == undefined))) {
        this.$message.error(this.$t(I18N_KEY + 'notApplySubReasonNotice'))
        this.loading = false
        return;
      }
      console.log(params)
      if(pre != '' && pre != undefined) {
        switch (pre) {
          case 'e': params['notApplySubReason'] = params['notApplySubReasonE']; break;
          case 'g': params['notApplySubReason'] = params['notApplySubReasonG']; break;
          case 'i': params['notApplySubReason'] = params['notApplySubReasonI']; break;
          default: params['notApplySubReason'] = '';
        }
      }
      // if(params['notApplyReason'] == undefined || params['notApplyReason'] == '') {
      //   this.$message.error(this.$t(I18N_KEY + 'followUpNotice'))
      //   this.loading = false
      //   return;
      // }
      followUp(Object.assign({}, params)).then((res) => {
        if (res.code === 1000) {
          this.$message.info("success")
          this.$emit('closeLoad')
        } else {
          this.$message.error(res.msg)
        }
        this.loading = false
      }).catch(e => {
        this.loading = false;
        this.$message.error(e.message);
      })
    },
    selectLoadImg() {
      this.$refs.avatarInput.dispatchEvent(new MouseEvent("click"));
    },
    changeImage() {
      let files = this.$refs.avatarInput.files;
      let that = this;
      function readAndPreview(file) {
        that.file = file
        if (/\.(jpe?g|png|gif)$/i.test(file.name)) {
          let reader = new FileReader();
          reader.onload = function() {
            if (that.imgData !== this.result) {
              that.imgData = this.result // 这个是base64的数据格式
            }
          };
          reader.readAsDataURL(file);
        }
      }

      if (files) {
        [].forEach.call(files, readAndPreview);
      }
    },
    upLoad() {
      if(this.imgCount > 3) {
        this.$message.error(this.$t(I18N_KEY + 'imgCountLimitNotice'));
        return;
      }
      let imgFile = this.file;//获取到上传的图片
      let formData = new FormData();//通过formdata上传
      formData.append('file', imgFile)
      this.axios({
        method: "post",
        url: "/telemarket/upload",
        data: formData,
        core: true
      }).then((res) => {
          // console.log(res.data);
          this.imgCount = this.imgCount + 1;
          alert("上传成功! 地址为:"+res.data.attachmentCode+"---"+this.imgCount)
          var att = this.form['attachment'];
          if(att === '') {
            alert(1)
            this.form['attachment'] = res.data.attachmentCode;
          } else {
            alert(2)
            this.form['attachment'] = att + ',' + res.data.attachmentCode
          }

          // this.form.setFieldsValue({
          //   logoUrl: res.data.attachmentCode
          // })
        })
        .catch((error) => {
          console.log(error);
        })

    },
    customRequest(file){
      const form = new FormData();
      var theCount = this.imgCount;
      this.imgCount = this.imgCount + 1;
      this.imageLoading[Number(theCount)] = 'loading'
      form.append('file', file.file)
      upload(form).then(res => {
        if (res.code === 1000) {
          file.onSuccess(res, file.file)
          file.status = 'done'
          this.imageLoading[Number(theCount)] = 'done';
          this.$message.info("success")
          file['attCode'] = res.data.attachmentCode
          this.fileList.push(file);
          // console.log(file)
        } else {
          file.onError()
          file.status = 'error'
          this.imageLoading[Number(theCount)] = 'done';
          this.$message.error(res.msg)
        }
      }).catch(e => {
        file.onError()
        file.status = 'error'
        this.imageLoading[Number(theCount)] = 'done';
        this.$message.error(e.message);
      })

    },
    remove(file) {
      // console.log(file)
      var newList = [];
      for(var item in this.fileList){
        var theFile = this.fileList[item];
        if(theFile.attCode === file.response.data.attachmentCode) {
          continue;
        }
        newList.push(theFile);
      }
      this.fileList = newList;
      // console.log(newList)
      return true;
    }
  },
  watch: {
    languageStorage: {
      handler (lang) {
        if (lang == 'en-US') {
          this.fieldName = 'textEn';
        } else if (lang == 'zh-CN') {
          this.fieldName = 'textCn';
        } else if (lang == 'es') {
          this.fieldName = 'textEs';
        } else {
          this.fieldName = 'textEn';
        }
      },
      immediate: true,
    }
  },
}
</script>
<style lang="less" scoped>
.file-box {
  float: left;
  width: 50px;
  height: 50px;
  margin-right: 10px;
  margin: 0 8px 8px 0;

  .file-item {
    width: 100%;
    height: 100%;
    position: relative;
    border: 1px solid #d9d9d9;
    border-radius: 4px;

    .file-preview {
      padding: 8px;
      width: 182px;
      height: 142px;
    }

    .file-label {
      margin: 0;
      text-align: center;
      border-bottom: 1px solid #d9d9d9;
      padding: 8px;
      font-weight: 500;
      font-size: 16px;
    }
  }
}
</style>