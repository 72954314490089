<template>
  <div :bordered="false">
    <div class="table-page-search-wrapper">
      <a-form layout="inline" @keyup.enter.native="searchQuery">
        <a-row :gutter="12">
          <a-col :md="6" :sm="8">
            <a-form-item :label="$t('date')">
              <a-range-picker v-model="queryParam.endTime" format="YYYY-MM-DD" />
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="8">
            <a-form-item :label="$t('customer.appName')">
              <a-select v-model="queryParam.appssid">
                <a-select-option v-for="item in appList" :key="item.appssid">
                  {{ item.appName }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="12" :sm="8">
            <a-form-item :label="$t('custServiceWorkOrder.handleStatus')">
              <dict-select
                v-model="queryParam.status"
                :dict-code="DICT_CODE.repayCertWorkOrderStatus"
                :begin="[{ value: '', label: '全部', labelEn: 'All' }]"
                :placeholder="$t('select')">
              </dict-select>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="8">
            <a-form-item :label="$t('order.settlementStatus')">
              <dict-select
                v-model="queryParam.settleStatus"
                :dict-code="DICT_CODE.SETTLE_STATUS"
                :begin="[{ value: '', label: '全部', labelEn: 'All' }]"
                :placeholder="$t('select')">
              </dict-select>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="8">
            <a-form-item :label="$t('phoneNo')">
              <a-input v-model="queryParam.mobileNo" />
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="8">
            <a-form-item :label="$t('order.orderNo')">
              <a-input v-model="queryParam.orderNo" />
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="8">
            <a-form-item :label="$t('remark')">
              <a-input v-model="form.remark" />
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="8">
            <a-form-item label="token">
              <a-input v-model="token" />
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="8">
            <span style="float: left; overflow: hidden" class="table-page-search-submitButtons">
              <a-button type="primary" @click="searchQuery" icon="search">{{ $t('search') }}</a-button>
              <a-button type="primary" @click="searchReset" icon="reload" style="margin-left: 8px">{{
                $t('reset')
              }}</a-button>
            </span>
          </a-col>
        </a-row>
      </a-form>
      <!-- 备注弹窗 -->
      <a-modal v-model:visible="visibleremark" :title="$t('remark')">
        <a-textarea v-model:value="remark" placeholder="Please enter" :auto-size="{ minRows: 4, maxRows: 6 }" />
        <template #footer>
          <a-button key="back" @click="handleCancelremark">{{$t('cancel')}}</a-button>
          <a-button key="submit" type="primary" :loading="loading" @click="handleremarkOk">{{$t('confirm')}}</a-button>
        </template>
      </a-modal>
      <a-modal v-model:visible="visibleUrl">
        <div style="margin: 20px">{{ url }}</div>
        <template #footer>
          <a-button key="back" @click="handleCancelurl">{{$t('cancel')}}</a-button>
          <a-button key="submit" type="primary" :loading="loading" @click="copy(url)">{{$t('teleMarket.copy')}}</a-button>
        </template>
      </a-modal>
      <!-- 创建指定金额支付订单 -->
      <a-modal v-model:visible="visibleAmount" :title="$t('repaycert.enterAmount')">
        <a-row>
          <a-col :md="10" :sm="8">
            <a-input v-model="zdAmt" type="number" />
          </a-col>
        </a-row>
        <div v-if="zdAmountUrl">
          <div style="margin: 24px 0px -10px 0px">H5 Link</div>
          <div style="margin: 20px; margin-left: 0px">{{ zdAmountUrl }}</div>
          <a-button key="submit" type="primary" @click="copy(zdAmountUrl)">{{$t('teleMarket.copy')}}</a-button>
        </div>
        <template #footer>
          <a-button key="back" @click="handleCancelAmount">{{$t('cancel')}}</a-button>
          <a-button key="submit" type="primary" :loading="loadingAmt" @click="submitAmount()">{{$t('confirm')}}</a-button>
        </template>
      </a-modal>
      <a-modal
        :title="$t('repaycert.confirmStatus')"
        :visible="handlevisible"
        :maskClosable="false"
        :confirmLoading="confirmLoading"
        :width="800"
        @ok="handlestatus"
        @cancel="handleCancel"
      >
        <a-radio-group v-model="status">
          <a-radio :style="radioStyle" :value="0">{{$t('repaycert.wordOrderStatus_0')}}</a-radio>
          <a-radio :style="radioStyle" :value="1">{{$t('repaycert.wordOrderStatus_1')}}</a-radio>
          <a-radio :style="radioStyle" :value="2">{{$t('repaycert.wordOrderStatus_2')}}</a-radio>
          <a-radio :style="radioStyle" :value="3">{{$t('repaycert.wordOrderStatus_3')}}</a-radio>
          <a-radio :style="radioStyle" :value="4">{{$t('repaycert.wordOrderStatus_4')}}</a-radio>
        </a-radio-group>
      </a-modal>
      <a-table
        key="id"
        ref="table"
        size="middle"
        rowKey="taskId"
        :columns="columns"
        :dataSource="dataSource"
        :pagination="false"
        :loading="loading"
        bordered
      >
        <span slot="createdTime" slot-scope="text, record">
          <p>{{ transformTime2(record.createdTime) }}</p>
        </span>
        <span slot="updateTime" slot-scope="text, record">
          <p>{{ transformTime2(record.updateTime) }}</p>
        </span>
        <span slot="appIdName" slot-scope="text, record">
          <p>{{ record.appSsid }} - {{ record.appName }}</p>
        </span>
        <Dict slot="settleStatus" slot-scope="text" :dict-code="DICT_CODE.SETTLE_STATUS" :value="text"/>
        <div slot="imgFileUrls" slot-scope="text, record">
          <div v-viewer="viewOptions">
            <div style="cursor: pointer" v-for="(item, index) in record.imgUrlFiles" href="">
              <img style="margin: 10px 0px" class="image" :src="item.url" alt="" />
            </div>
          </div>
        </div>
        <Dict slot="status" slot-scope="text" :dict-code="DICT_CODE.repayCertWorkOrderStatus" :value="text"/>

        <span slot="action" slot-scope="text, record">
          <a-button
            size="small"
            type="primary"
            @click="handleDetail(record)"
            style="margin-right: 5px margin-bottom:10px"
          >
            {{ record.status == 0 ? $t('handle') : $t('repaycert.modifyStatus') }}</a-button
          >
          <a-button
            type="primary"
            size="small"
            @click="handleRepayUrl(record)"
            style="margin-left: 5px; margin-bottom: 10px"
          >
            {{$t('repaycert.collectLink')}}</a-button
          >
          <a-button type="primary" size="small" @click="editRemark(record)" style="margin-left: 5px"> {{$t('remark')}}</a-button>
          <a-button type="primary" size="small" @click="zdAmount(record)" style="margin: 10px"
            >{{$t('repaycert.createRepayOrder')}}</a-button
          >
        </span>
      </a-table>
      <div style="margin: 10px 0px; display: flex; justify-content: flex-end">
        <v-pagination
          v-if="total"
          :page-num="form.pageNum"
          :page-size="form.pageSize"
          :total="total"
          @change="handleTableChange"
        />
      </div>
    </div>

    <a-modal
      :loading="loading"
      :footer="null"
      :visible="pictureVisible"
      :destroyOnClose="true"
      :maskClosable="false"
      :confirmLoading="confirmLoading"
      :width="500"
      @cancel="handleCancel"
    >
      <p>
        <span>AppSsid：</span> <span>{{ this.currImgInfo.appSsid }}</span>
      </p>
      <p>
        <span>{{$t('order.orderNo')}}：</span> <span> {{ this.currImgInfo.orderNo }} </span>
      </p>
      <div ref="canvasRef" style="display: flex; justify-content: space-around">
        <div class="active">
          <img style="margin: 10px; width: 200px" :src="this.currImgInfo.imgUrl" />
          <!-- <a @click="btndownload()">下载</a> -->
          <a-button type="primary" @click="btndownload()" :loading="loading"> 下载 </a-button>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import exportFile from '@/api/exportFile'
import { filterObj } from '@/utils/util'
import { getApplist } from '@/webpublicapi/order'
import Dict from '../modules/Dict'
import DictSelect from '../modules/DictSelect'
import moment from 'moment'
import vModal from '../modules/Modal'
import _ from 'lodash'
import { mapActions, mapGetters } from 'vuex'
import { repayCertWorkOrderList, updateRepayCertWorkOrder, createPayOrder } from '@/webpublicapi/CollectionAccountInfo'
import { createRepayUrlFunc } from '@/webpublicapi/order'
import imageMixin from '../../../mixins/image-mixin'

// import { getDict } from "@/webpublicapi/system.js"
const I18N_KEY = {
  ORDER: 'order.',
  CUSTOMER: 'customer.',
  COLLECTION: 'collection.',
}

export default {
  name: 'CollectionPayCertificate',
  components: {
    Dict,
    DictSelect,
    vModal,
  },
  mixins: [imageMixin],
  data() {
    return {
      status: '',
      radioStyle: {
        display: 'block',
        height: '30px',
        lineHeight: '30px',
      },
      zdAmt: '',
      zdAmountUrl: '',
      loadingAmt: false,
      visibleAmount: false,
      StatusItem: {
        status: '',
        id: '',
      },
      record1: {},
      visibleUrl: false,
      visibleremark: false,
      I18N_KEY,
      Arrlist: [],
      toggleSearchStatus: false,
      confirmLoading: false,
      visible: false,
      handlevisible: false,
      pictureVisible: false,
      orderIds: undefined,
      visibleRepayUrlCard: false,
      currImgInfo: {},
      custInfoId: undefined,
      batchLoading: false,
      selectedRowKeys: [],
      selectedList: [],
      // 分页参数
      form: {
        pageNum: 1,
        pageSize: 10,
      },
      appList: [],
      total: 0,
      dataSource: [],
      // 查询条件
      queryParam: {
        mobileNo: '',
      },
      loading: false,
      isorter: {
        column: 'createTime',
        order: 'desc',
      },
      labelCol: {
        xs: { span: 8 },
        sm: { span: 5 },
      },
      wrapperCol: {
        xs: { span: 16 },
        sm: { span: 19 },
      },

      selectUser: '',
      ExamineUser: [],
      taskId: '',
      token: '',
      remark: '',
      url: '',
    }
  },
  computed: {
    ...mapGetters('platformCollection', ['listUserWithNum']),
    batchList() {
      return this.listUserWithNum || []
    },
    columns() {
      return [
        {
          title: this.$t('repaycert.workorder'),
          align: 'left',
          dataIndex: 'id',
        },
        {
          title: this.$t('custServiceWorkOrder.createdTime'),
          align: 'left',
          dataIndex: 'createdTimeStr',
          // scopedSlots: { customRender: 'createdTime' },
        },
        {
          title: this.$t('customer.appName'),
          align: 'left',
          dataIndex: 'appIdName',
          scopedSlots: { customRender: 'appIdName' },
        },
        {
          title: this.$t('order.orderNo'),
          align: 'left',
          dataIndex: 'orderNo',
        },
        {
          title: this.$t('phoneNo'),
          align: 'left',
          dataIndex: 'mobileNo',
        },
        {
          title: this.$t('order.settlementStatus'),
          dataIndex: 'settleStatus',
          align: 'center',
          scopedSlots: { customRender: 'settleStatus' },
        },
        {
          title: this.$t('customer.orderImages'),
          align: 'center',
          dataIndex: 'imgFileUrls',
          scopedSlots: { customRender: 'imgFileUrls' },
        },
        {
          title: this.$t('custServiceWorkOrder.updateTime'),
          align: 'left',
          dataIndex: 'updateTimeStr',
          // scopedSlots: { customRender: 'updateTime' },
        },
        {
          title: this.$t('custServiceWorkOrder.handleStatus'),
          dataIndex: 'status',
          align: 'center',
          scopedSlots: { customRender: 'status' },
        },
        {
          title: this.$t('repaycert.collector'),
          dataIndex: 'collectorMobile',
          align: 'center',
          scopedSlots: { customRender: 'collectorMobile' },
        },
        {
          title: this.$t('order.applyAmt'),
          dataIndex: 'applyAmount',
          align: 'center',
          // scopedSlots: { customRender: 'collectorMobile' },
        },
        {
          title: this.$t('remark'),
          dataIndex: 'remark',
          align: 'center',
          //  width: 260,
          // scopedSlots: { customRender: 'collectorMobile' },
        },
        {
          title: this.$t('custServiceWorkOrder.operate'),
          dataIndex: 'action',
          width: 250,
          align: 'left',
          scopedSlots: { customRender: 'action' },
        },
      ]
    },
  },
  created() {
    this.loadData(this.form)
    getApplist().then((res) => {
      this.appList = res.data || []
    })
  },
  methods: {
    ...mapActions('platformCollection', ['getListUserWithNum', 'batchChangeUser']),
    handleCancelAmount() {
      this.visibleAmount = false
      this.zdAmountUrl = ''
      this.zdAmt = ''
    },
    zdAmount(record) {
      this.record1 = record
      this.visibleAmount = true
    },
    submitAmount() {
      if (!this.zdAmt) {
        this.$message.error('请输入金额')
        return
      }
      this.loadingAmt = true
      let data = {
        id: this.record1.id,
        amount: this.zdAmt,
      }
      createPayOrder(data).then((res) => {
        this.loadingAmt = false
        if (res.code == 1000) {
          this.zdAmountUrl = res.data.url
        } else {
          this.$message.error(res)
        }
      })
    },
    // ---------------------------------
    handleToggleSearch() {
      this.toggleSearchStatus = !this.toggleSearchStatus
    },
    editRemark(record) {
      this.record1 = record
      this.remark = record.remark
      this.visibleremark = true
    },
    handleCancelurl() {
      this.visibleUrl = false
    },
    handleCancelremark() {
      this.visibleremark = false
      this.remark = ''
    },
    //保存备注
    handleremarkOk() {
      let data = {
        remark: this.remark,
        id: this.record1.id,
        status: this.record1.status,
      }
      updateRepayCertWorkOrder(data)
        .then((res) => {
          console.log(res)
          if (res.code == '1000') {
            this.$message.success('备注成功')
            this.visibleremark = false
            this.remark = ''
            this.loadData()
          } else {
            this.$message.error(res.msg)
          }
          // if(res.data.code)
          this.loadData()
        })
        .then((err) => {
          console.log(err)
        })
    },
    // 查询
    searchQuery() {
      this.loadData()
    },
    async loadData(data) {
      this.selectedList = []
      this.selectedRowKeys = []
      var params = this.getQueryParams() // 查询条件
      this.loading = true
      await repayCertWorkOrderList(params)
        .then((res) => {
          this.total = res.data.total
          this.dataSource = res.data.rows
        })
        .catch((err) => {
          console.log(err)
          this.dataSource = []
          this.total = ''
        })
      this.loading = false
    },
    transformTime(time) {
      return moment(time).format('YYYY-MM-DD')
    },
    transformTime2(time) {
      return moment(time).format('YYYY-MM-DD HH:mm:ss')
    },
    transformStringToLow(str) {
      return str && str.toLocaleLowerCase()
    },

    submitBatch() {
      const taskIds = this.selectedList.map((t) => t.taskId)
      const userTask = this.batchList.map((t) => ({ userId: t.userId, taskNum: t.taskNum ? +t.taskNum : 0 }))
      const totalNum = userTask.map((t) => t.taskNum).reduce((total, num) => (total += num), 0)
      console.log(userTask, totalNum)
      if (!userTask.length) {
        return this.closeBatchDialog()
      }
      if (isNaN(totalNum)) {
        return this.$message.error(this.$t('please_enter_a_positive_integer'))
      }
      if (totalNum > this.selectedList.length) {
        return this.$message.error(
          this.$t('platformCollection.cannot_be_greater_than_the_total_quantity_of_the_checked_order')
        )
      }
      this.closeBatchDialog()

      this.batchChangeUser({ taskIds, userTask })
        .then((res) => {
          console.log(res)
          this.loadData()
          this.$message.success(this.$t('success'))
        })
        .finally((res) => {})
    },
    getQueryParams() {
      const { endTime } = this.queryParam
      const data = {
        ...this.queryParam,
        startDate: endTime && endTime.length ? this.transformTime(endTime[0]) : undefined,
        endDate: endTime && endTime.length ? this.transformTime(endTime[1]) : undefined,
        collectType: 1, //非分期
        remark: this.form.remark, //备注
        token: this.token,
      }
      delete data.endTime
      var param = Object.assign({}, data)
      param.pageNum = this.form.pageNum
      param.pageSize = this.form.pageSize
      return filterObj(param)
    },
    // 分页
    handleTableChange(pageNum, pageSize) {
      this.form.pageNum = pageNum
      this.form.pageSize = pageSize
      let data = { ...this.form, ...this.queryParam }
      this.loadData()
    },
    // 重置字典类型搜索框的内容
    searchReset() {
      var that = this
      that.queryParam.orderNo = ''
      that.queryParam.appName = ''
      that.queryParam.mobileNo = ''
      this.queryParam.settleStatus = ''
      this.queryParam.status = ''
      this.queryParam.appssid = ''
      that.queryParam.endTime = undefined
      this.form.remark = ''
      this.token = ''
      that.loadData(this.form.pageNum)
    },
    changeModal(visible) {
      this.visible = visible
    },
    // 处理Btn
    handleDetail(row) {
      this.status = row.status
      this.handlevisible = true
      this.orderIds = row.orderIds
      this.custInfoId = row.custInfoId
      this.taskId = row.taskId
      this.StatusItem.status = row.status
      this.StatusItem.status = this.StatusItem.status == 1 ? 0 : 1
      this.StatusItem.id = row.id
      this.changeModal(true)
    },
    handleRepayUrl(val) {
      createRepayUrlFunc({ orderId: val.orderId })
        .then((res) => {
          let input = document.createElement('input')
          this.url = res.data.url
          this.visibleUrl = true
        })
        .catch((err) => {
          console.log(err)
        })
    },
    handlestatus() {
      this.confirmLoading = true
      this.StatusItem.status = this.status
      this.Getupdate(this.StatusItem)
    },
    async Getupdate(data) {
      await updateRepayCertWorkOrder(data)
        .then((res) => {
          if (res.code == '1000') {
            //  this.$message.success('更新成功')
            this.visible = true
            this.handlevisible = false
            this.loadData()
          }
          this.confirmLoading = false
        })
        .then((err) => {
          console.log(err)
        })
      this.visible = false
    },
    handleCancel(e) {
      this.status = ''
      this.handlevisible = false
      this.visible = false
      this.pictureVisible = false
    },
    // 处理状态下拉框
    Btnstatus(val) {
      this.queryParam.status = val
    },
    Btnsquare(val) {
      this.queryParam.settleStatus = val
    },
    // ---
    btndownload() {
      this.loading = true
      let imgFilePath = this.currImgInfo.filePath
      console.log(222, imgFilePath)
      let splitArr = imgFilePath.split('/')
      const fileName = splitArr[splitArr.length - 1]
      console.log('fileName:::', fileName)
      const file = {
        fileUrl: '/api/web/webpublic/repayCertWorkOrder/getRepayCertImage?filePath=' + imgFilePath,
        fileName: fileName,
      }
      exportFile(file)
        .catch((e) => {
          this.$message.error(e.message)
          this.loading = false
        })
        .finally(() => {
          this.loading = false
        })
    },
    handleCurrImgData(record, imgUrlInfo) {
      this.pictureVisible = true
      this.currImgInfo.filePath = imgUrlInfo.filePath
      this.currImgInfo.imgUrl = imgUrlInfo.url
      this.currImgInfo.appSsid = record.appSsid
      this.currImgInfo.orderNo = record.orderNo
      console.log(this.currImgInfo)
    },
    copy(e) {
      //复制方法
      let input = document.createElement('input')
      input.value = e
      document.body.appendChild(input)
      input.select()
      document.execCommand('copy')
      document.body.removeChild(input)
      this.$message.success('复制成功')
      this.visibleUrl = false
    },
    searchBatch() {
      this.batchLoading = true
      this.getListUserWithNum({ userName: this.queryParam.inName, groupIdIn: this.queryParam.groupIdIn }).finally(
        (res) => {
          this.batchLoading = false
        }
      )
    },
  },
}
</script>
<style lang="less" scoped>
@import '~@/assets/less/common.less';

.description {
  line-height: 1.4;

  &.d_text {
    text-indent: 1em;
  }

  &.d_high_light {
    color: #fd9a38;
  }
}

.active {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.image {
  width: 100px;
  height: 100px;
}
</style>
