<template>
  <div :bordered="false">
    <div class="table-page-search-wrapper">
      <a-form layout="inline" @keyup.enter.native="searchQuery">
        <a-row :gutter="12">
          <!--<a-col :md="10" :sm="10">-->
          <!--<a-form-item :label="$t(I18N_KEY.COLLECTION + 'createTime')">-->
          <!--<a-range-picker-->
          <!--v-model="queryParam.endTime"-->
          <!--format="YYYY-MM-DD"-->
          <!--style="width: 100%;"/>-->
          <!--</a-form-item>-->
          <!--</a-col>-->
          <!--<a-col :md="10" :sm="10">-->
          <!--<a-form-item :label="$t(I18N_KEY.COLLECTION + 'settleTime')">-->
          <!--<a-range-picker-->
          <!--v-model="queryParam.settleTime"-->
          <!--format="YYYY-MM-DD"-->
          <!--style="width: 100%;"/>-->
          <!--</a-form-item>-->
          <!--</a-col>-->
          <!--<a-col :md="6" :sm="8">-->
          <!--<a-form-item :label="$t(I18N_KEY.ORDER + 'overdueDaysStart')">-->
          <!--<a-input :placeholder="$t('enter') + ' ' + transformStringToLow($t(I18N_KEY.ORDER + 'overdueDays'))" v-model="queryParam.overdueDaysStart"></a-input>-->
          <!--</a-form-item>-->
          <!--</a-col>-->
          <a-col :md="6" :sm="8">
            <span style="float: left; overflow: hidden" class="table-page-search-submitButtons">
              <a-button type="primary" @click="searchQuery" icon="search">{{ $t('search') }}</a-button>
              <!--<a-button type="primary" @click="searchReset" icon="reload" style="margin-left: 8px">{{ $t('reset') }}</a-button>-->
            </span>
          </a-col>
        </a-row>
      </a-form>

      <a-table
        ref="table"
        rowKey="id"
        size="middle"
        :columns="columns"
        :dataSource="dataSource"
        :pagination="false"
        :loading="loading"
        bordered
      >
        <span slot="action" slot-scope="text, record">
          <!-- slot-scope="text, record" -->
          <a @click="distribution(record)">{{ $t('platformCollection.allocation') }}</a>
        </span>
      </a-table>
    </div>
    <v-modal v-model="visible" :title="$t('order.collectionDetail')">
      <div style="padding: 20px">
        <collection-detail
          v-if="visible"
          :orderId="orderId"
          :custInfoId="custInfoId"
          :taskId="taskId"
          :auditDrawer="false"
          @close="visible = false"
        />
      </div>
    </v-modal>

    <a-modal
      v-model="applyAmtVisible"
      :title="$t('platformCollection.are_you_sure_you_want_to_allocate')"
      @ok="handleOk"
    >
      <div style="display: flex; align-items: center">
        借款金额:
        <a-input
          v-model="applyAmt"
          placeholder="（只分案大于此借款金额，可不填）"
          type="number"
          style="width: 280px; margin-left: 5px"
        />
      </div>
      <p style="color: red; font-size: 12px">备注：如果填值，分案之后需要执行入 Redis</p>
    </a-modal>
  </div>
</template>

<script>
import { filterObj } from '@/utils/util'
import { countCollectionTaskDispense, CollectionSignInDistribution } from '@/webpublicapi/platformCollection'
import Dict from '../modules/Dict'
import DictSelect from '../modules/DictSelect'
import moment from 'moment'
import vModal from '../modules/Modal'
import CollectionDetail from './CollectionDetail'

let currentRecord = null

const I18N_KEY = {
  ORDER: 'order.',
  CUSTOMER: 'customer.',
  COLLECTION: 'collection.',
}

export default {
  name: 'CountCollectionTaskDispense',
  components: {
    Dict,
    DictSelect,
    vModal,
    CollectionDetail,
  },
  data() {
    return {
      I18N_KEY,
      toggleSearchStatus: false,
      visible: false,
      orderId: undefined,
      custInfoId: undefined,
      taskId: undefined,
      // 分页参数
      form: {
        pageNum: 1,
        pageSize: 10,
      },
      total: 0,
      dataSource: [],
      // 查询条件
      queryParam: {
        orderNo: '',
        aadhaarName: '',
        mobileNo: '',
      },
      loading: false,
      isorter: {
        column: 'createTime',
        order: 'desc',
      },
      labelCol: {
        xs: { span: 8 },
        sm: { span: 5 },
      },
      wrapperCol: {
        xs: { span: 16 },
        sm: { span: 19 },
      },
      // 借款金额
      applyAmtVisible: false,
      applyAmt: '',
    }
  },
  computed: {
    columns() {
      return [
        {
          title: this.$t('platformCollection.type'),
          align: 'left',
          dataIndex: 'type',
        },
        {
          title: this.$t('platformCollection.total_amount_to_be_expedited'),
          align: 'left',
          dataIndex: 'totalCount',
        },
        {
          title: this.$t('platformCollection.total_allocated'),
          align: 'left',
          dataIndex: 'alReadyDispense',
        },
        {
          title: this.$t('platformCollection.unallocated_total_amount'),
          align: 'left',
          dataIndex: 'notDispense',
        },
        {
          title: this.$t('action'),
          dataIndex: 'action',
          align: 'center',
          fixed: 'right',
          width: 100,
          scopedSlots: { customRender: 'action' },
        },
      ]
    },
  },
  created() {
    this.getDict()
    this.loadData()
  },
  methods: {
    handleToggleSearch() {
      this.toggleSearchStatus = !this.toggleSearchStatus
    },
    searchQuery() {
      this.loadData()
    },
    loadData(arg) {
      // 加载数据 若传入参数1则加载第一页的内容
      // if (arg === 1) {
      //   this.form.pageNum = 1
      // }
      this.loading = true
      var params = this.getQueryParams() // 查询条件
      countCollectionTaskDispense(Object.assign({}, params))
        .then((res) => {
          if (res.code === 1000) {
            // this.total = res.data.total
            this.dataSource = res.data || []
          } else {
            this.total = 0
            this.dataSource = []
            this.$message.error(res.msg)
          }
          this.loading = false
        })
        .catch((e) => {
          this.loading = false
          this.$message.error(e.message)
        })
    },
    transformTime(time) {
      return moment(time).format('YYYY-MM-DD')
    },
    transformStringToLow(str) {
      return str && str.toLocaleLowerCase()
    },
    getQueryParams() {
      const { endTime } = this.queryParam
      const { settleTime } = this.queryParam
      const data = {
        ...this.queryParam,
        endDateStart: endTime && endTime.length ? this.transformTime(endTime[0]) : undefined,
        endDateEnd: endTime && endTime.length ? this.transformTime(endTime[1]) : undefined,
        settleDateStart: settleTime && settleTime.length ? this.transformTime(settleTime[0]) : undefined,
        settleDateEnd: settleTime && settleTime.length ? this.transformTime(settleTime[1]) : undefined,
      }
      delete data.applyDate
      var param = Object.assign({}, data)
      // param.pageNum = this.form.pageNum;
      // param.pageSize = this.form.pageSize;
      return filterObj(param)
    },
    handleTableChange(pageNum, pageSize) {
      this.form.pageNum = pageNum
      this.form.pageSize = pageSize
      this.loadData()
    },
    // 重置字典类型搜索框的内容
    searchReset() {
      var that = this
      that.queryParam.endTime = undefined
      that.queryParam.settleTime = undefined
      that.loadData(this.form.pageNum)
    },
    changeModal(visible) {
      this.visible = visible
    },
    distribution(record) {
      currentRecord = record
      this.applyAmt = ''
      this.applyAmtVisible = true
    },
    handleOk() {
      let params = { collectionLevel: currentRecord.normalConfigId }
      let amount = +this.applyAmt

      if (amount != 0 && !isNaN(amount)) {
        params.applyAmt = amount
      }

      this.loading = true
      CollectionSignInDistribution(params)
        .then((res) => {
          this.applyAmtVisible = false
          this.loadData()
        })
        .catch((e) => {
          this.loading = false
          this.applyAmtVisible = false
          this.$message.error(e.message)
        })
    },
  },
}
</script>
<style lang="less" scoped>
@import '~@/assets/less/common.less';
</style>
