<template>
  <a-modal
    :title=" $t(I18N_KEY + 'recycle')"
    :width="900"
    :visible="visible"
    :confirmLoading="confirmLoading"
    @ok="handleOk"
    @cancel="handleCancel"
    :cancelText="$t('close')"
    wrapClassName="ant-modal-cust-warp"
    style="top:5%;overflow-y: hidden">
    <a-spin :spinning="confirmLoading">
      <a-form :form="form">

        <a-form-item :labelCol="labelCol"
                     :wrapperCol="wrapperCol"
                     :label="$t(I18N_KEY + 'assignTime')">
          <a-range-picker v-decorator="[ 'assignTime']" format="YYYY-MM-DD" style="width: 100%;"/>
        </a-form-item>

        <a-form-item :labelCol="labelCol"
                     :wrapperCol="wrapperCol"
                     :label="$t(I18N_KEY + 'seatMobile')">
          <a-select v-decorator="[ 'user', validatorRules.user]" :placeholder="$t('select') + ' ' + $t(I18N_KEY + 'seatMobile')">
            <a-select-option v-for='item in teleMarketUsers' :key='item.value'>
              {{item.label}}
            </a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          :label="$t(I18N_KEY + 'type')">
          <a-select v-decorator="[ 'type']" :placeholder="$t('select') + ' ' + $t(I18N_KEY + 'type')">
            <a-select-option v-for='item in types' :key='item.value'>
              {{$t(I18N_KEY + 'type_' + item.label)}}
            </a-select-option>
          </a-select>
<!--          <a-radio-group-->
<!--            v-decorator="['type', { initialValue: '0' }]">-->
<!--            <span v-for="item in this.types">-->
<!--              <a-radio :value=item>{{ $t(I18N_KEY + 'type_' + item) }}</a-radio>-->
<!--            </span>-->
<!--          </a-radio-group>-->
        </a-form-item>
      </a-form>
    </a-spin>
  </a-modal>
</template>
<script>
import pick from 'lodash.pick'
const I18N_KEY = 'teleMarket.';
export default {
  name: "RecycleModal",
  props: {
    teleMarketUsers: {
      type: Array,
      default: () => [],
    },
    types:{
      type: Array,
      default: () => [],
    },
  },
  data () {
    return {
      I18N_KEY,
      title:"操作",
      visible: false,
      model: {},
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
      confirmLoading: false,
      form: this.$form.createForm(this),
      validatorRules:{
        user:{
          rules: [
            { required: true, message: 'Please input user!' },
          ]}
      },
    }
  },
  created () {
  },
  methods: {
    preRecycle () {
      this.form.resetFields();
      this.open();
      this.model = {
        ...this.model,
      }
      this.$nextTick(() => {
        this.form.setFieldsValue(pick(this.model,'assignTime', 'user', 'type'))
      });
    },
    open () {
      this.visible = true;
    },
    close () {
      this.$emit('close');
      this.visible = false;
    },
    openLoading () {
      this.confirmLoading = true;
    },
    closeLoading () {
      this.confirmLoading = false;
    },
    handleOk () {
      // console.log(88888)
      const that = this;
      // 触发表单验证
      this.form.validateFields((err, values) => {
        if (!err) {
          that.confirmLoading = true;
          let formData = {
            ...values,
          }
          this.$emit('ok', formData);
        }
      })
    },
    handleCancel () {
      this.close()
    },
  },
}
</script>
<style scoped>

</style>