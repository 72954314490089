<template>
  <a-modal
    :title="title"
    :width="600"
    :visible="visible"
    :confirmLoading="confirmLoading"
    @ok="handleOk"
    @cancel="handleCancel"
    cancelText="关闭"
  >
    <a-spin :spinning="confirmLoading">
      <a-form :form="form">
        <a-form-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          label="订单状态（审批状态）">
          <dict-select
            v-decorator="[ 'approvalStatus']"
            :dict-code="DICT_CODE.ORDER_STATUS"
            :begin="[{ value: '', label: '全部', labelEn: 'All' }]"
            :placeholder="$t('select')"
          />
        </a-form-item>
        <a-form-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          label="放款状态">
          <dict-select
            v-decorator="[ 'loanStatus']"
            :dict-code="DICT_CODE.LOAN_STATUS"
            :begin="[{ value: '', label: '全部', labelEn: 'All' }]"
            :placeholder="$t('select')"
          />
        </a-form-item>
        <a-form-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          label="逾期状态">
          <dict-select
            v-decorator="[ 'overdueStatus']"
            :dict-code="DICT_CODE.OVERDUE_STATUS"
            :begin="[{ value: '', label: '全部', labelEn: 'All' }]"
            :placeholder="$t('select')"
          />
        </a-form-item>
        <a-form-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          label="结清状态">
          <dict-select
            v-decorator="[ 'settleStatus']"
            :dict-code="DICT_CODE.SETTLE_STATUS"
            :begin="[{ value: '', label: '全部', labelEn: 'All' }]"
            :placeholder="$t('select')"
          />
        </a-form-item>
        <a-form-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          label="申请金额">
          <a-input placeholder="请输入申请金额" v-decorator="[ 'applyAmt']"/>
        </a-form-item>
        <a-form-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          label="利息">
          <a-input placeholder="请输入利息" v-decorator="[ 'interest']"/>
        </a-form-item>
        <a-form-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          label="抵扣金额">
          <a-input placeholder="请输入抵扣金额" v-decorator="[ 'deductionFee']"/>
        </a-form-item>
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>
import pick from 'lodash.pick'
import { editOrderStatusForTest } from '@/webpublicapi/order'
import DictSelect from '../../modules/DictSelect';


// duplicateCheck
export default {
  name: 'TestOrderModal',
  components: {
    DictSelect,
  },
  data () {
    return {
      value: 1,
      title: '操作',
      visible: false,
      model: {},
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 }
      },
      confirmLoading: false,
      form: this.$form.createForm(this),
    }
  },
  created () {
  },
  methods: {
    add () {
      this.edit({})
    },
    edit (record) {
      if (record.id) {
        this.visiblekey = true
      } else {
        this.visiblekey = false
      }
      this.form.resetFields()
      this.model = Object.assign({}, record)
      this.visible = true
      this.$nextTick(() => {
        this.model.approvalStatus=String(this.model.approvalStatus)
        this.model.loanStatus=String(this.model.loanStatus)
        this.model.overdueStatus=String(this.model.overdueStatus)
        this.model.settleStatus=String(this.model.settleStatus)
        this.form.setFieldsValue(pick(this.model,  'approvalStatus', 'loanStatus', 'overdueStatus','settleStatus','applyAmt','interest', 'deductionFee'))
      })
    },
    // 确定
    handleOk () {
      const that = this
      // 触发表单验证
      this.form.validateFields((err, values) => {
        if (!err) {
          that.confirmLoading = true
          const formData = Object.assign(this.model, values)
          let obj
          console.log(formData)
          obj = editOrderStatusForTest(formData)
          obj.then((res) => {
            if (res.code === 1000) {
              this.$message.success(res.msg)
              this.$emit('ok')
            } else {
              this.$message.warning(res.msg)
            }
          }).finally(() => {
            this.confirmLoading = false
            this.close()
          })
        }
      })
    },
    // 关闭
    handleCancel () {
      this.close()
    },
    close () {
      this.$emit('close')
      this.visible = false
    }
  }
}
</script>
