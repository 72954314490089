<template>
  <div :bordered="false">
    <div class="table-page-search-wrapper">

      <a-form layout="inline" @keyup.enter.native="searchQuery">
        <a-row :gutter="12">
          <a-col :md="10" :sm="10">
            <a-form-item :label="'申请日期'">
              <a-range-picker
                v-model="queryParam.lendingTime"
                format="YYYY-MM-DD"
                style="width: 100%;"/>
            </a-form-item>
          </a-col>
          <a-col :md='8' :sm='8'>
            <a-form-item :label="'产品类型'">
              <a-select mode='multiple'
                        v-model='queryParam.appSsids'
              >
                <a-select-option v-for='item in appList' :key='item.appssid'>
                  {{ item.appName }}
                </a-select-option>
              </a-select>

            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="8">
            <a-form-item :label="'订单类型'">
              <a-select default-value="" style="width: 120px" v-model="queryParam.orderLoanType">
                <a-select-option value="">
                  全部
                </a-select-option>
                <a-select-option value="1">
                  首贷
                </a-select-option>
                <a-select-option value="2">
                  复贷
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="8">
            <a-form-item :label="$t('order.applicationDeadline')">
              <a-select default-value="" style="width: 190px" v-model="queryParam.deadlineConfigValue">
                <a-select-option v-for="item in deadlineConfigList" :key="item.itemValue">
                  {{ item.itemText }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :md="3" :sm="4">
            <a-form-item :label="'费率'">
              <a-input :placeholder="$t('enter') + ' 费率'" v-model="queryParam.serviceFee"></a-input>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="8">
            <span style="float: left;overflow: hidden;" class="table-page-search-submitButtons">
              <a-button type="primary" @click="searchQuery" icon="search">{{ $t('search') }}</a-button>
              <a-button type="primary" @click="searchReset" icon="reload" style="margin-left: 8px">{{ $t('reset') }}</a-button>
              <!--<a-button type="primary" @click="downLoadData('')" icon="download" style="margin-left: 8px">导出</a-button>-->
            </span>
          </a-col>
        </a-row>
      </a-form>


      <a-table
        ref="table"
        rowKey="id"
        size="middle"
        :columns="columns"
        :dataSource="dataSource"
        :pagination="false"
        :loading="loading"
        bordered>
      </a-table>


    </div>
    <v-modal
        v-model="visible"
        :title="$t('order.collectionDetail')">
        <div style="padding: 20px;">
            <collection-detail
              v-if="visible"
              :orderId="orderId"
              :custInfoId="custInfoId"
              :taskId="taskId"
              :auditDrawer="false"
              @close="visible = false;"/>
        </div>
    </v-modal>
  </div>
</template>

<script>
import { filterObj } from '@/utils/util'
import {
  getApplist,
  reborrowCount,
  reborrowCountForSubmit,
  reborrowCountForSubmitInstallment
} from '@/webpublicapi/order'
import Dict from '../modules/Dict';
import DictSelect from '../modules/DictSelect';
import exportFile from '@/webpublicapi/exportFile'
import moment from 'moment'
import vModal from '../modules/Modal';
import {getAllDeadlineConfig} from "@/webpublicapi/deadlineConfig";
const I18N_KEY = {
  ORDER: 'order.',
  CUSTOMER: 'customer.',
  COLLECTION: 'collection.',
}

export default {
  name: 'reborrowCount',
  components: {
    Dict,
    DictSelect,
    vModal,
  },
  data () {
    return {
      I18N_KEY,
      toggleSearchStatus:false,
      visible: false,
      orderId: undefined,
      custInfoId: undefined,
      taskId: undefined,
      // 分页参数
      form: {
        pageNum: 1,
        pageSize: 10,
      },
      total: 0,
      dataSource: [],
      deadlineConfigList: [],
      appList: [],
      // 查询条件
      queryParam: {
        "appssid":"",
        "serviceFee":"",
        "duration":"",
        'appSsids': '',
        'orderLoanType': ''
      },
      loading: false,
      isorter: {
        column: 'createTime',
        order: 'desc'
      },
      labelCol: {
        xs: { span: 8 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 16 },
        sm: { span: 19 }
      }
    }
  },
  computed: {
    columns () {
      return [
        {
          title:  '实际还款日期',
          align: 'left',
          dataIndex: 'timeStr'
        },
        {
          title: '结清笔数',
          align: 'left',
          dataIndex: 'countSettleOrder'
        },
        {
          title: '还款当天复借',
          align: 'left',
          dataIndex: 'curReborrowCount'
        },
        {
          title: '还款第2天复借',
          align: 'left',
          dataIndex: 'reborrowCount2'
        },
        {
          title: '还款第3天复借',
          align: 'left',
          dataIndex: 'reborrowCount3'
        },
        {
          title: '还款第4天复借',
          align: 'left',
          dataIndex: 'reborrowCount4'
        },
        {
          title: '还款第5天复借',
          align: 'left',
          dataIndex: 'reborrowCount5'
        },
        {
          title: '还款第6天复借',
          align: 'left',
          dataIndex: 'reborrowCount6'
        },
        {
          title: '还款第7天复借',
          align: 'left',
          dataIndex: 'reborrowCount7'
        },
        {
          title: '总复借笔数',
          align: 'left',
          dataIndex: 'reborrowCount8'
        }
        ,
        {
          title: '首日复借率',
          align: 'left',
          dataIndex: 'rate1'
        },
        {
          title: '7日复借率',
          align: 'left',
          dataIndex: 'rate7'
        },
        {
          title: '10日复借率',
          align: 'left',
          dataIndex: 'rate10'
        },
        {
          title: '15日复借率',
          align: 'left',
          dataIndex: 'rate15'
        },
        {
          title: '复借率',
          align: 'left',
          dataIndex: 'rate'
        }



      ]
    },
  },
  created () {
    this.getDict();
    this.loadData();
    getAllDeadlineConfig().then(res => {
      this.deadlineConfigList = res.data || [];
    });
    getApplist().then(res => {
      this.appList = [];
      this.appInList = res.data || [];
      for(var i = 0 ; i < this.appInList.length ; i ++){
        if(this.appInList[i].appssid != ''){
          this.appList.push(this.appInList[i]);
        }
      }
    });
  },
  methods: {
    handleToggleSearch(){
      this.toggleSearchStatus = !this.toggleSearchStatus;
    },
    searchQuery () {
      this.loadData()
    },
    loadData (arg) {
      // 加载数据 若传入参数1则加载第一页的内容
      // if (arg === 1) {
      //   this.form.pageNum = 1
      // }
      this.loading = true
      var params = this.getQueryParams()// 查询条件
      reborrowCountForSubmitInstallment(Object.assign({}, params)).then((res)=>{
        if(res.code===1000){
          // this.total = res.data.total
          this.dataSource = res.data || [];

          for(var i = 0 ; i < this.dataSource.length ; i++){
            var dataIn = this.dataSource[i];
            // dataIn.rate = (dataIn.reborrowCount8 / dataIn.countSettleOrder).toFixed(2);
          }

        }else{
          this.total = 0
          this.dataSource = []
          this.$message.error(res.msg)
        }
        this.loading = false
      }).catch(e => {
            this.loading = false;
            this.$message.error(e.message);
      })
    },
    transformTime (time) {
      return moment(time).format('YYYY-MM-DD')
    },
    transformStringToLow (str) {
        return str && str.toLocaleLowerCase();
    },
    getQueryParams () {
      const { lendingTime } = this.queryParam;
      const data = {
          ...this.queryParam,
          lendingTimeStart: (lendingTime && lendingTime.length) ? this.transformTime(lendingTime[0]) : undefined,
          lendingTimeEnd : (lendingTime && lendingTime.length) ? this.transformTime(lendingTime[1]) : undefined,
      }
      delete data.applyDate;
      var param = Object.assign({}, data)

      return filterObj(param)
    },
    handleTableChange (pageNum, pageSize) {
        this.form.pageNum = pageNum;
        this.form.pageSize = pageSize;
        this.loadData();
    },
    // 重置字典类型搜索框的内容
    searchReset () {
      var that = this
      that.queryParam.lendingTime = undefined;
      that.loadData(this.form.pageNum)
      that.queryParam.appSsids = ''
    },
    changeModal (visible) {
        this.visible = visible;
    },
    downLoadData (arg2) {
      var params = this.getQueryParams(arg2)// 查询条件
      const file = {
        fileUrl: '/api/web/order/countLoanDataWithDateExcel?lendingTimeStart=' + params.lendingTimeStart + '&lendingTimeEnd=' + params.lendingTimeEnd + '&custType=' + params.custType,
        fileName: 'countLoanDataWithDateExcel.xls',
      }
      this.loading = true;
      exportFile(file).catch(e => {
        this.$message.error(e.message);
      }).finally(() => {
        this.loading = false;
      })
    }

  }
}
</script>
<style lang="less" scoped>
 @import "~@/assets/less/common.less";
</style>
