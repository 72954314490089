<template>
  <a-card :bordered="false">

    <div class="table-page-search-wrapper">
      <a-spin :spinning='spinning'>
        <a-form layout="inline" @keyup.enter.native="searchQuery">
          <a-row :gutter="12">
            <a-col :span="12">
              <a-form-item :label="'实际到期日'" :labelCol="{span: 6}" :wrapperCol="{span: 14, offset: 1}">
                <a-range-picker
                  v-model="queryParam.dataDt"
                  format="YYYY-MM-DD" />
              </a-form-item>
              <a-form-item
                :labelCol="labelCol"
                :wrapperCol="wrapperCol"
                label="分期类型">
                <a-radio-group
                  v-model="queryParam.collectorType">
                  <a-radio value="1">非分期</a-radio>
                  <a-radio value="2">分期</a-radio>
                </a-radio-group>
              </a-form-item>
            </a-col>
            <a-button type="primary" @click="searchQuery" icon="search">{{ $t('search') }}</a-button>
          </a-row>

          <a-row>
            <a-col>
            <span style="float: left;overflow: hidden;" class="table-page-search-submitButtons">
              <a-button type="primary" @click="recovery()" icon="edit" style="margin-left: 8px">回收</a-button>
            </span>
            </a-col>
          </a-row>
        </a-form>
      </a-spin>

      <a-table
        ref="table"
        rowKey="id"
        size="middle"
        :columns="columns"
        :dataSource="dataSource"
        :pagination="false"
        :loading="loading"
        bordered>
      </a-table>

    </div>
  </a-card>
</template>

<script>
import { filterObj } from '@/utils/util'
import {getRecovery, recovery} from '@/webpublicapi/platformCollection'
import moment from 'moment'

export default {
  name: 'AppList',
  components: {
  },
  data () {
    return {
      visible: false,
      spinning: false,
      dataSource: [],
      loading: false,
      headers: {
        // authorization: 'authorization-text',
      },
      queryParam: {
        dataDt: undefined,
        collectorType: "1",
      }
    }
  },
  computed: {
    columns () {
      return [
        {
          title:  '全部',
          align: 'left',
          dataIndex: 'allCount'
        },
        {
          title:  '在催',
          align: 'left',
          dataIndex: 'inTableCount'
        }
      ]
    },
  },
  methods: {
    getQueryParams () {
      const { dataDt } = this.queryParam;
      const data = {
        ...this.queryParam,
        startDate: dataDt && this.transformTime(dataDt[0]),
        endDate: dataDt && this.transformTime(dataDt[1]),
      }
      delete data.dataDt;
      return filterObj(data)
    },
    searchQuery () {
      this.loadData()
    },
    loadData () {
      this.loading = true
      var params = this.getQueryParams()// 查询条件

      getRecovery(Object.assign({}, params)).then((res)=>{
        if(res.code===1000){
          this.dataSource = res.data
        }else{
          this.dataSource = []
          this.$message.error(res.msg)
        }
        this.loading = false
      }).catch(e => {
        this.loading = false;
        this.$message.error(e.message);
      })
    },
    transformTime (time) {
      return moment(time).format('YYYY-MM-DD')
    },
    recovery() {
      this.spinning = true;
      var params = this.getQueryParams()// 查询条件

      recovery(Object.assign({}, params)).then((res)=>{
        if(res.code===1000){
          this.$message.info('更新条数'+res.data)
        }else{
          this.$message.error(res.msg)
        }
        this.spinning = false;
      }).catch(e => {
        this.spinning = false;
        this.$message.error(e.message);
      })
    },
  }
}
</script>
<style lang="less" scoped>
@import "~@/assets/less/common.less";
</style>
