import { axios } from '@/utils/request'

export function baseData(parameter = {}) {
  return axios({
    url: '/custService/dict/baseData',
    method: 'post',
    data: parameter
  })
}

export function detailBaseData(parameter = {}) {
  return axios({
    url: '/custService/dict/detailBaseData',
    method: 'post',
    data: parameter
  })
}

export function secondLevelQuestion(firstLevelQuestion) {
  return axios({
    url: '/custService/dict/baseData/'+firstLevelQuestion,
    method: 'get'
  })
}

export function dayReport(parameter = {}) {
  return axios({
    url: '/custService/report/dayReport',
    method: 'post',
    data: parameter
  })
}

export function flushDayReport(parameter = {}) {
  return axios({
    url: '/custService/report/dayReport/flush',
    method: 'post',
    data: parameter
  })
}

export function weekReport(parameter = {}) {
  return axios({
    url: '/custService/report/weekReport',
    method: 'post',
    data: parameter
  })
}

export function createReport(parameter = {}) {
  return axios({
    url: '/custService/report/createReport',
    method: 'post',
    data: parameter
  })
}

export function deleteReport(id) {
  return axios({
    url: '/custService/report/delete/'+ id,
    method: 'get'
  })
}

export function reportList(parameter = {}) {
  return axios({
    url: '/custService/report/list',
    method: 'post',
    data: parameter
  })
}

export function workOrderList(parameter = {}) {
  return axios({
    url: '/custService/workOrders/list',
    method: 'post',
    data: parameter
  })
}

export function handleWorkOrder(parameter = {}) {
  return axios({
    url: '/custService/workOrders/handle',
    method: 'post',
    data: parameter
  })
}

export function repairWorkOrder(parameter = {}) {
  return axios({
    url: '/custService/workOrders/repair',
    method: 'post',
    data: parameter
  })
}

export function repairWorkOrderBatch(parameter = {}) {
  return axios({
    url: '/custService/workOrders/repairBatch',
    method: 'post',
    data: parameter
  })
}

export function workOrderDetail(id) {
  return axios({
    url: '/custService/workOrders/detail/' + id,
    method: 'get'
  })
}
// 版本操作
export function versionList(parameter = {}) {
  return axios({
    url: '/custService/version/list',
    method: 'post',
    data: parameter
  })
}

// export function versionDetail(id) {
//   return axios({
//     url: '/custService/version/detail/' + id,
//     method: 'get'
//   })
// }

export function versionOperate(parameter = {}) {
  return axios({
    url: '/custService/version/opt',
    method: 'post',
    data: parameter
  })
}

export function versionDelete(id) {
  return axios({
    url: '/custService/version/delete/' + id,
    method: 'get'
  })
}

// 字典操作
export function dictList(parameter = {}) {
  return axios({
    url: '/custService/dict/list',
    method: 'post',
    data: parameter
  })
}

// export function dictDetail(id) {
//   return axios({
//     url: '/custService/dict/detail/' + id,
//     method: 'get'
//   })
// }

export function dictOperate(parameter = {}) {
  return axios({
    url: '/custService/dict/opt',
    method: 'post',
    data: parameter
  })
}

// export function dictValid(id) {
//   return axios({
//     url: '/custService/dict/valid/' + id,
//     method: 'get'
//   })
// }

export function dictDelete(id) {
  return axios({
    url: '/custService/dict/delete/' + id,
    method: 'get'
  })
}

export function dictReload() {
  return axios({
    url: '/custService/dict/reload',
    method: 'get'
  })
}

export function nextApprovelData(parameter = {}) {
  return axios({
    url: '/custService/workOrders/nextApprovelData',
    method: 'post',
    data: parameter
  })
}

export function getquestionV1ByWorkFlowType(workFlowType) {
  return axios({
    url: '/custService/workOrders/question/' + workFlowType,
    method: 'get'
  })
}

export function collectionUser(parameter = {}) {
  return axios({
    url: '/custService/workOrders/collectionUser',
    method: 'post',
    data: parameter
  })
}

export function custUser(parameter = {}) {
  return axios({
    url: '/custService/workOrders/custUser',
    method: 'post',
    data: parameter
  })
}

export function createWorkOrder(parameter = {}) {
  return axios({
    url: '/custService/workOrders/create',
    method: 'post',
    data: parameter
  })
}

export function uploadAndGet(parameter = {}) {
  return axios({
    url: '/custService/workOrders/uploadAndGet',
    method: 'post',
    data: parameter
  })
}

export function updateCollectionOrder(parameter = {}) {
  return axios({
    url: '/custService/workOrders/updateCollectionOrder',
    method: 'post',
    data: parameter
  })
}

export function baseDataForCollectionComplaintReport(parameter = {}) {
  return axios({
    url: '/custService/dict/baseData/collectionComplaintReport',
    method: 'post',
    data: parameter
  })
}

export function collectionComplaintReport(parameter = {}) {
  return axios({
    url: '/custService/report/collectionComplaintReport',
    method: 'post',
    data: parameter
  })
}

export function baseDataForSettleStatusReport(parameter = {}) {
  return axios({
    url: '/custService/dict/baseData/settleStatusReport',
    method: 'post',
    data: parameter
  })
}
export function settleStatusReport(parameter = {}) {
  return axios({
    url: '/custService/report/settleStatusReport',
    method: 'post',
    data: parameter
  })
}

export function modelTagWeekReport(parameter = {}) {
  return axios({
    url: '/custService/report/modelTagWeekReport',
    method: 'post',
    data: parameter
  })
}

export function toFinance(parameter = {}) {
  return axios({
    url: '/custService/workOrders/toFinance',
    method: 'post',
    data: parameter
  })
}