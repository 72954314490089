<template>
  <div :bordered="false">
    <div class="table-page-search-wrapper">

      <a-form layout="inline" @keyup.enter.native="searchQuery">
        <a-row :gutter="12">
          <a-col :md="10" :sm="10">
            <a-form-item :label="$t(I18N_KEY.ORDER + 'settlementTime')">
              <a-range-picker
                v-model="queryParam.lendingTime"
                format="YYYY-MM-DD"
                style="width: 100%;"/>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="8">
            <a-form-item :label="'产品类型'">
              <a-select
                v-model="queryParam.appSsid"
                mode="multiple"
                @change="onChange"
              >
                <a-select-option v-for="item in appList" :key="item.appssid">
                  {{ item.appName }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="8">
            <a-form-item :label="'期限'">
              <a-select default-value="" style="width: 120px" v-model="queryParam.duration">
                <a-select-option value="">
                  全部
                </a-select-option>
                <a-select-option value="7">
                  7天
                </a-select-option>
                <a-select-option value="10">
                  10天
                </a-select-option>
                <a-select-option value="14">
                  14天
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>


          <a-col :md="6" :sm="8">
            <span style="float: left;overflow: hidden;" class="table-page-search-submitButtons">
              <a-button type="primary" @click="searchQuery" icon="search">{{ $t('search') }}</a-button>
              <a-button type="primary" @click="searchReset" icon="reload" style="margin-left: 8px">{{ $t('reset') }}</a-button>
            </span>
          </a-col>
        </a-row>
      </a-form>


      <a-table
        ref="table"
        rowKey="id"
        size="middle"
        :columns="columns"
        :dataSource="dataSource"
        :pagination="false"
        :loading="loading"
        bordered>
      </a-table>


    </div>
    <v-modal
        v-model="visible"
        :title="$t('order.collectionDetail')">
        <div style="padding: 20px;">
            <collection-detail
              v-if="visible"
              :orderId="orderId"
              :custInfoId="custInfoId"
              :taskId="taskId"
              :auditDrawer="false"
              @close="visible = false;"/>
        </div>
    </v-modal>
  </div>
</template>

<script>
import { filterObj } from '@/utils/util'
import {getApplist, reborrowCountInstallment} from '@/webpublicapi/order'
import Dict from '../modules/Dict';
import DictSelect from '../modules/DictSelect';
import exportFile from '@/webpublicapi/exportFile'
import moment from 'moment'
import vModal from '../modules/Modal';
const I18N_KEY = {
  ORDER: 'order.',
  CUSTOMER: 'customer.',
  COLLECTION: 'collection.',
}

export default {
  name: 'reborrowCount',
  components: {
    Dict,
    DictSelect,
    vModal,
  },
  data () {
    return {
      I18N_KEY,
      toggleSearchStatus:false,
      visible: false,
      orderId: undefined,
      custInfoId: undefined,
      taskId: undefined,
      // 分页参数
      form: {
        pageNum: 1,
        pageSize: 10,
      },
      total: 0,
      dataSource: [],
      appList: [],
      // 查询条件
      queryParam: {
        lendingTime:[]
      },
      loading: false,
      isorter: {
        column: 'createTime',
        order: 'desc'
      },
      labelCol: {
        xs: { span: 8 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 16 },
        sm: { span: 19 }
      }
    }
  },
  computed: {
    columns () {
      return [
        {
          title:  '实际还款日期',
          align: 'left',
          dataIndex: 'timeStr'
        },
        {
          title: '实际还款笔数',
          align: 'left',
          dataIndex: 'countSettleOrder'
        },
        {
          title: '还款当天复借',
          align: 'left',
          dataIndex: 'curReborrowCount'
        },
        {
          title: '还款第2天复借',
          align: 'left',
          dataIndex: 'reborrowCount2'
        },
        {
          title: '还款第3天复借',
          align: 'left',
          dataIndex: 'reborrowCount3'
        },
        {
          title: '还款第4天复借',
          align: 'left',
          dataIndex: 'reborrowCount4'
        },
        {
          title: '还款第5天复借',
          align: 'left',
          dataIndex: 'reborrowCount5'
        },
        {
          title: '还款第6天复借',
          align: 'left',
          dataIndex: 'reborrowCount6'
        },
        {
          title: '还款第7天复借',
          align: 'left',
          dataIndex: 'reborrowCount7'
        },
        {
          title: '总复借笔数',
          align: 'left',
          dataIndex: 'reborrowCount8'
        },
        {
          title: '首日复借率',
          align: 'left',
          dataIndex: 'rate1'
        },
        {
          title: '7日复借率',
          align: 'left',
          dataIndex: 'rate7'
        },
        {
          title: '10日复借率',
          align: 'left',
          dataIndex: 'rate10'
        },
        {
          title: '15日复借率',
          align: 'left',
          dataIndex: 'rate15'
        },
        {
          title: '复借率',
          align: 'left',
          dataIndex: 'rate'
        }

      ]
    },
  },
  created () {
    this.getTime();
    this.getDict();
    this.loadData();
    getApplist().then(res => {
      this.appList = [];
      this.appInList = res.data || [];
      for(var i = 0 ; i < this.appInList.length ; i ++){
        if(this.appInList[i].appssid != ''){
          this.appList.push(this.appInList[i]);
        }
      }
    });
  },
  methods: {
    getTime() {
      this.queryParam.lendingTime = [];
      var nowDate = new Date();
      this.queryParam.lendingTime[0]=moment(nowDate.setDate(nowDate.getDate() - 6));
      this.queryParam.lendingTime[1]=moment(new Date());

    },
    handleToggleSearch(){
      this.toggleSearchStatus = !this.toggleSearchStatus;
    },
    searchQuery () {
      this.loadData()
    },
    loadData (arg) {
      // 加载数据 若传入参数1则加载第一页的内容
      // if (arg === 1) {
      //   this.form.pageNum = 1
      // }
      this.loading = true
      var params = this.getQueryParams()// 查询条件
      reborrowCountInstallment(Object.assign({}, params)).then((res)=>{
        if(res.code===1000){
          // this.total = res.data.total
          this.dataSource = res.data || [];

        }else{
          this.total = 0
          this.dataSource = []
          this.$message.error(res.msg)
        }
        this.loading = false
      }).catch(e => {
            this.loading = false;
            this.$message.error(e.message);
      })
    },
    transformTime (time) {
      return moment(time).format('YYYY-MM-DD')
    },
    transformStringToLow (str) {
        return str && str.toLocaleLowerCase();
    },
    getQueryParams () {
      const { lendingTime } = this.queryParam;
      const data = {
          ...this.queryParam,
          settleTimeStart: (lendingTime && lendingTime.length) ? this.transformTime(lendingTime[0]) : undefined,
          settleTimeEnd : (lendingTime && lendingTime.length) ? this.transformTime(lendingTime[1]) : undefined
      }
      delete data.applyDate;
      var param = Object.assign({}, data)

      return filterObj(param)
    },
    handleTableChange (pageNum, pageSize) {
        this.form.pageNum = pageNum;
        this.form.pageSize = pageSize;
        this.loadData();
    },
    // 重置字典类型搜索框的内容
    searchReset () {
      var that = this
      this.getTime();
      if (this.queryParam.appSsid) {
        this.queryParam.appSsid = undefined;
      }
      this.queryParam.serviceFee = null;
      this.queryParam.duration = null;
      this.queryParam.orderType = null;

      that.loadData(this.form.pageNum)
    },
    changeModal (visible) {
        this.visible = visible;
    },

  }
}
</script>
<style lang="less" scoped>
 @import "~@/assets/less/common.less";
</style>
