export const REBORROW_FLAG = {
	0: '未复借',
	1: '已复借',
}

export const SEX = {
    '1': 'man',
    '2': 'woman',
}

export const CONTACT_TYPE = {
    0: 'firstContact',
    1: 'secondContact',
}

export const TRADESTATUS = {
  '00': '交易中',
  '01': '交易成功',
  '02': '交易退款',
  '03': '交易取消',
  '04': '交易失败',
  '05': '交易队列中',
}

export const TRADETYPE = {
  '0': '提现',
  '1': '存款',
}

export const ORDER_TYPE = {
    '0': 'Orden normal',
    '1': 'Orden de prórroga',
}

export const ORDER_TYPE_CN = {
  '0': '正常订单',
  '1': '展期订单',
}

export const STATUS_TYPE = {
  0: '否',
  1: '是',
}
export const REFUND_TRADE_STATUS = {
  '00': '交易中',
  '01': '交易成功',
  '02': '交易退款',
  '03': '交易取消',
  '04': '交易提交',
  '05': '交易失败',
}

export const CURP_TYPE = {
    '0': 'CC',
  '1': 'CE',
  '2': 'TI',
  '3': 'NIT',
  '4': 'Pasaporte',
}

export const COLLECT_TYPE = {
  '1': 'BANK',
  '2': 'NEQUI',

}
