<template>
  <a-modal
    :title="title"
    :width="1200"
    :visible="visible"
    :confirmLoading="confirmLoading"
    @ok="handleOk"
    @cancel="handleCancel"
    cancelText="关闭"
  >
    <a-spin :spinning="confirmLoading">
      <a-form :form="form">
        <a-table
          ref="table"
          rowKey="id"
          size="middle"
          :columns="columns"
          :dataSource="jsonList"
          :pagination="false"
          :loading="loading"
          bordered>
<!--          <Dict slot="loanStatus" slot-scope="text" :dict-code="DICT_CODE.LOAN_STATUS" :value="text"/>-->
<!--          <Dict slot="approvalStatus" slot-scope="text" :dict-code="DICT_CODE.ORDER_STATUS" :value="text"/>-->
<!--          <Dict slot="settleStatus" slot-scope="text" :dict-code="DICT_CODE.INSTALLMENT_SETTLE_STATUS" :value="text"/>-->
<!--          <Dict slot="overdueStatus" slot-scope="text" :dict-code="DICT_CODE.OVERDUE_STATUS" :value="text"/>-->
          <span slot="action" slot-scope="text, recordV">
            <a @click="downLoadData(recordV, '1')" type="button" icon="download" style="margin-left: 8px">导json</a>
            <a-divider type="vertical"/>
            <a @click="downLoadData(recordV, '0')" type="button" icon="download" style="margin-left: 8px">原始json</a>
            <a-divider type="vertical"/>
            <a-popconfirm title="确定清除吗?" @confirm="() =>handleClear(recordV.id)">
            <a>删除</a>
          </a-popconfirm>
        </span>

        </a-table>
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>
import pick from 'lodash.pick'
import { deleteJsonData } from '@/webpublicapi/customer'
import DictSelect from '../../modules/DictSelect';
import Dict from '@/views/webpublic/modules/Dict.vue'
import exportFile from '@/webpublicapi/exportFile'


// duplicateCheck
export default {
  name: 'TestInstallOrderListModal',
  components: {
    Dict,
    DictSelect,
  },
  data () {
    return {
      value: 1,
      title: '操作',
      visible: false,
      loading: false,
      columns: [
        {
          title: 'id',
          align: 'left',
          dataIndex: 'id',
        },
        {
          title: '订单编号',
          align: 'left',
          dataIndex: 'orderInfoId',
        },
        {
          title: '路径',
          align: 'left',
          dataIndex: 'filePath'
        },
        {
          title: this.$t('action'),
          dataIndex: 'action',
          align:"center",
          scopedSlots: { customRender: 'action' },
        }
      ],
      dataSource:[],
      jsonList: [],
      model: {},
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 }
      },
      confirmLoading: false,
      form: this.$form.createForm(this),
    }
  },
  created () {
  },
  methods: {
    checkBoxChange(checkedValue) {
      console.log(checkedValue)
    },
    add () {
      this.edit({})
    },
    edit (record) {
      if (record.id) {
        this.visiblekey = true
      } else {
        this.visiblekey = false
      }
      this.form.resetFields()
      this.model = Object.assign({}, record)
      this.visible = true
      this.$nextTick(() => {
        this.jsonList = record.jsonList;
      })
    },
    downLoadData (record, type) {
      const file = {
        fileUrl: '/api/web/webpublic/order/test/getJsonDataById?jsonId=' + record.id + '&type=' + type ,
        fileName: record.id + '.json',
      }
      this.loading = true;
      exportFile(file).catch(e => {
        this.$message.error(e.message);
      }).finally(() => {
        this.loading = false;
      })
    },
    handleClear(id){
      this.confirmLoading = true
      deleteJsonData({jsonFileId: id}).then((res) => {
        if (res.code === 1000) {
          this.$message.success(res.msg)
          this.$emit('ok')
        } else {
          this.$message.warning(res.msg)
        }
      }).finally(() => {
        this.confirmLoading = false
        this.close()
      })
    },
    // 确定
    handleOk () {
      const that = this
      // 触发表单验证
      this.form.validateFields((err, values) => {
        if (!err) {
          that.confirmLoading = true
          const formData = Object.assign(this.model, values)
          let obj
          console.log(formData)
          obj = editInstallmentOrderStatusForTest(formData)
          obj.then((res) => {
            if (res.code === 1000) {
              this.$message.success(res.msg)
              this.$emit('ok')
            } else {
              this.$message.warning(res.msg)
            }
          }).finally(() => {
            this.confirmLoading = false
            this.close()
          })
        }
      })
    },
    // 关闭
    handleCancel () {
      this.close()
    },
    close () {
      this.$emit('close')
      this.visible = false
    }
  }
}
</script>
