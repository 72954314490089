import { axios } from '@/utils/request'

export function userStatistics (parameter = {}) {
  return axios({
    url: '/telemarket/user/statistics',
    method: 'post',
    data: parameter
  })
}

export function manualLoadData (parameter = {}) {
  return axios({
    url: '/telemarket/manual/loadData',
    method: 'post',
    data: parameter
  })
}

export function dataList (parameter = {}) {
  return axios({
    url: '/telemarket/data/list',
    method: 'post',
    data: parameter
  })
}

export function dataDetail (id) {
  return axios({
    url: '/telemarket/data/detail/' +id,
    method: 'get'
  })
}

export function dataCount (parameter = {}) {
  return axios({
    url: '/telemarket/data/count',
    method: 'post',
    data: parameter
  })
}


export function teleMarketUsers () {
  return axios({
    url: '/telemarket/users',
    method: 'get'
  })
}

export function baseData () {
  return axios({
    url: '/telemarket/baseData',
    method: 'get'
  })
}

// 回收
export function recycle (parameter = {}) {
  return axios({
    url: '/telemarket/recycle',
    method: 'post',
    data: parameter
  })
}

// 再分配
export function reAssign (parameter = {}) {
  return axios({
    url: '/telemarket/reAssign',
    method: 'post',
    data: parameter
  })
}

//坐席操作
export function seatList(parameter = {}) {
  return axios({
    url: '/telemarket/seat/list',
    method: 'post',
    data: parameter
  })
}

export function seatOperate(parameter = {}) {
  return axios({
    url: '/telemarket/seat/opt',
    method: 'post',
    data: parameter
  })
}

export function seatDelete(id) {
  return axios({
    url: '/telemarket/seat/delete/' + id,
    method: 'get'
  })
}

// 报表
export function efficiency(parameter = {}) {
  return axios({
    url: '/telemarket/report/efficiency',
    method: 'post',
    data: parameter
  })
}

export function t1Convert(parameter = {}) {
  return axios({
    url: '/telemarket/report/t1Convert',
    method: 'post',
    data: parameter
  })
}

export function t0t7Convert(parameter = {}) {
  return axios({
    url: '/telemarket/report/t0t7Convert',
    method: 'post',
    data: parameter
  })
}

export function followUp(parameter = {}){
  return axios({
    url: '/telemarket/data/followUp',
    method: 'post',
    data: parameter
  })
}

export function upload(parameter = {}){
  return axios({
    url: '/telemarket/upload',
    method: 'post',
    data: parameter
  })
}