<template>
  <div :bordered="false">
<!--        <div class="table-page-search-wrapper" style='margin-bottom: 20px'>-->
<!--          <a-form layout="inline" @keyup.enter.native="searchQuery">-->
<!--            <a-row :gutter="24">-->
<!--              <a-col :md="7" :sm="8">-->
<!--                <span style="float: left;overflow: hidden;">-->
<!--                  <a-button type="primary" @click="assign" icon="search">{{ $t(I18N_KEY + 'assign') }}</a-button>-->
<!--                </span>-->
<!--              </a-col>-->
<!--            </a-row>-->
<!--          </a-form>-->
<!--        </div>-->
<!--        <template>-->
<!--          <a-divider style="height: 1px; background-color: #7cb305" />-->
<!--        </template>-->
    <div class="table-page-search-wrapper">
      <a-form layout="inline" @keyup.enter.native="searchQuery">
        <a-row :gutter="24">
          <a-col :md="10" :sm="8">
            <a-form-item :label="$t(I18N_KEY + 'type')">
              <a-select v-model="queryParam.type" :placeholder="$t('select')" style="width: 100%">
                <a-select-option value="">{{ $t(I18N_KEY+'all') }}</a-select-option>
                <a-select-option v-for='item in types' :key='item.value'>
                  {{$t(I18N_KEY + 'type_' + item.label)}}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :md="6" :sm="8">
            <a-form-item :label="$t(I18N_KEY + 'registerTime')">
              <a-date-picker v-model="queryParam.registerTime" format="YYYY-MM-DD" style="width: 100%;"/>
            </a-form-item>
          </a-col>

<!--          <a-col :md="6" :sm="8">-->
<!--            <a-form-item :label="$t(I18N_KEY + 'assignTime')">-->
<!--              <a-date-picker v-model="queryParam.assignTime" format="YYYY-MM-DD" style="width: 100%;"/>-->
<!--            </a-form-item>-->
<!--          </a-col>-->
          <a-col :md="8" :sm="12">
            <a-form-item :label="$t(I18N_KEY + 'assignTime')">
              <a-range-picker v-model="queryParam.assignTime" format="YYYY-MM-DD" />
            </a-form-item>
          </a-col>

          <a-col :md="6" :sm="8">
            <a-form-item :label="$t(I18N_KEY + 'followStatus')">
              <a-select v-model="queryParam.followStatus2" :placeholder="$t('select')" style="width: 200px">
                <a-select-option value="">{{ $t(I18N_KEY+'all') }}</a-select-option>
                <a-select-option v-for='item in followStatuses' :key='item.value'>
                  {{$t(I18N_KEY + 'followStatus_' + item.label)}}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :md="6" :sm="8">
            <a-form-item :label="$t(I18N_KEY + 'convertStatus')">
              <a-select v-model="queryParam.t1ConvertStatus" :placeholder="$t('select')" style="width: 100%">
                <a-select-option value="">{{ $t(I18N_KEY+'all') }}</a-select-option>
                <a-select-option v-for='item in t1ConvertStatuses' :key='item.value'>
                  {{$t(I18N_KEY + 'convertStatus_' + item.label)}}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :md="6" :sm="8">
            <a-form-item :label="$t(I18N_KEY + 'teleMarketUser')">
              <template>
                <a-select
                  v-model:value="queryParam.user"
                  show-search
                  :placeholder="$t('enter') + ' ' + $t(I18N_KEY + 'teleMarketUser')"
                  style="width: 200px"
                >
                  <a-select-option value="">{{ $t(I18N_KEY+'all') }}</a-select-option>
                  <a-select-option v-for="item in teleMarketUsers" :value="item.value">
                    {{item.label }}
                  </a-select-option>
                </a-select>
              </template>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="8">
            <a-form-item :label="$t(I18N_KEY + 'app')">
              <a-select v-model="queryParam.appSsid" :placeholder="$t('select')" style="width: 100%;">
                <a-select-option value="">{{ $t(I18N_KEY+'all') }}</a-select-option>
                <a-select-option v-for="item in apps" :value="item.appSsid">
                  {{ item.appSsid + '/' + item.appName }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :md="6" :sm="8">
            <a-form-item :label="$t(I18N_KEY + 'mobile')">
              <a-input :placeholder="$t('enter') + ' ' + $t(I18N_KEY + 'mobile')" v-model="queryParam.mobile"></a-input>
            </a-form-item>
          </a-col>

          <a-col :md="6" :sm="8">
            <a-form-item :label="$t(I18N_KEY + 'email')">
              <a-input :placeholder="$t('enter') + ' ' + $t(I18N_KEY + 'email')" v-model="queryParam.email"></a-input>
            </a-form-item>
          </a-col>

          <a-col :md="6" :sm="8">
            <a-form-item :label="$t(I18N_KEY + 'userName')">
              <a-input :placeholder="$t('enter') + ' ' + $t(I18N_KEY + 'userName')" v-model="queryParam.username"></a-input>
            </a-form-item>
          </a-col>

          <a-col :md="8" :sm="8">
            <a-form-item :label="$t(I18N_KEY + 'followUpStatus')">
              <a-select v-model="queryParam.followUpTag" :placeholder="$t('select')" style="width: 100%">
                <a-select-option value="">{{ $t(I18N_KEY+'all') }}</a-select-option>
                <a-select-option v-for='item in followUpStatuses' :key='item.value'>
                  {{$t(I18N_KEY + 'followUpStatus_' + item.label)}}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :md="10" :sm="8">
            <a-form-item :label="$t(I18N_KEY + 'notApplyReason')">
              <a-select v-model="queryParam.notApplyReason" :placeholder="$t('select')" style="width: 100%">
                <a-select-option value="">{{ $t(I18N_KEY+'all') }}</a-select-option>
                <a-select-option v-for='item in followUpNotApplyReasons' :key='item.value'>
<!--                  {{$t(I18N_KEY + 'notApplyReason_' + item.label)}}-->
                  {{item[fieldName]}}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :md="6" :sm="8" v-if='view'>
            <a-form-item :label="$t(I18N_KEY + 'exportType')">
              <a-select v-model="queryParam.exportType" :placeholder="$t('select')" style="width: 100%">
                <a-select-option value="">{{ $t(I18N_KEY+'all') }}</a-select-option>
                <a-select-option v-for='item in exportTypes' :key='item'>
                  {{$t(I18N_KEY + 'exportType_' + item)}}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :md="8" :sm="12">
            <a-form-item :label="$t(I18N_KEY + 'followUpTime')">
              <a-range-picker v-model="queryParam.followUpDate" format="YYYY-MM-DD" />
            </a-form-item>
          </a-col>

          <a-col :md="7" :sm="8">
            <span style="float: left;overflow: hidden;" class="table-page-search-submitButtons">
              <a-button type="primary" @click="searchQuery" icon="search">{{ $t('search') }}</a-button>
              <a-button type="primary" @click="searchReset" icon="reload" style="margin-left: 8px">{{ $t('reset') }}</a-button>
              <a-button type="primary" v-if='showScrmExportButton' @click="downLoadData()" icon="download" style="margin-left: 8px">{{ $t(I18N_KEY + 'scrmExport') }}</a-button>
              <a-button type="primary" v-if='view' @click="recycle" icon="reload" style="margin-left: 8px">{{ $t(I18N_KEY+'recycle') }}</a-button>
              <a-button type="primary" v-if='view' @click="downLoadTagData()" icon="download" style="margin-left: 8px">{{ $t(I18N_KEY + 'exportTagData') }}</a-button>
              <a-button type="primary" v-if='view && showExportButton' @click="downLoadCommonData()" icon="download" style="margin-left: 8px">{{ $t('exportFile') }}</a-button>
<!--              <a-button type="primary" @click="reAssign" icon="reload" style="margin-left: 8px">{{ $t(I18N_KEY+'reAssign') }}</a-button>-->
<!--              <a-button type="primary" @click="push" icon="reload" style="margin-left: 8px">{{ $t('push') }}</a-button>-->
            </span>
          </a-col>
        </a-row>

      </a-form>
      <a-table
        ref="table"
        rowKey="id"
        size="middle"
        :columns="columns"
        :dataSource="dataSource"
        :pagination="false"
        :loading="loading"
        :scroll="{ x: 1500 }"
        :rowClassName='rowClassName'
        bordered>
        <template #type="text,record,index">{{$t(I18N_KEY + 'type_' + text)}}</template>
        <template #curStage="text,record,index"><span v-if='text != undefined && text != null && text != ""'>{{$t(I18N_KEY + 'curStage_' + text)}}</span></template>
        <template #followStatus2="text,record,index">{{$t(I18N_KEY + 'followStatus_' + text)}}</template>
<!--        <template #dataStatus="text,record,index">{{$t(I18N_KEY + 'convertStatus_' + text)}}</template>-->
        <template #t1ConvertStatus="text,record,index">{{$t(I18N_KEY + 'convertStatus_' + text)}}</template>
<!--        <template #assignStatus="text,record,index">{{$t(I18N_KEY + 'assignStatus_' + text)}}</template>-->
        <span slot="action" slot-scope="text, record">
          <a @click="handleRepayUrl(record)" v-if='record.t1ConvertStatus == "0" && (record.type == "8" || record.type == "9")'>{{ $t(I18N_KEY + 'repayUrl') }}</a>
           <a-divider type="vertical" v-if='record.t1ConvertStatus == "0" && (record.type == "8" || record.type == "9")'/>
          <a @click="handleDetail(record)">{{ $t('view') }}</a>
          <a-divider type="vertical"/>
          <a @click="followUp(record)">{{ $t(I18N_KEY + 'followUp') }}</a>
        </span>
      </a-table>
            <div style="margin-top: 15px; text-align: right;">
              <v-pagination
                v-if="total"
                :page-num="form.pageNum"
                :page-size="form.pageSize"
                :total="total"
                :pageSizeOptions = "pageSizeOptions"
                @change="handleTableChange" />
            </div>
    </div>
<!--    <assign-modal ref="modalForm"  :tele-market-users='teleMarketUsers' :types='types' :type-values='typeValues' :tele-market-user-values='teleMarketUserValues'/>-->
    <recycle-modal ref="modalForm1" :tele-market-users='teleMarketUsers' :types='types' @ok="modalFormOk1"></recycle-modal>
    <v-modal
      v-model="visible"
      :title="$t(I18N_KEY + 'teleMarketDetail')">
      <div style="padding: 20px;">
        <tele-market-detail v-if="visible" :orderId="orderId"
                            :follow-up-not-apply-reasons='followUpNotApplyReasons'
                            :follow-up-not-apply-sub-reasons-e='followUpNotApplySubReasonsE'
                            :follow-up-not-apply-sub-reasons-g='followUpNotApplySubReasonsG'
                            :follow-up-not-apply-sub-reasons-i='followUpNotApplySubReasonsI'/>
      </div>
    </v-modal>
    <v-modal
      v-model="visible1"
      :title="$t(I18N_KEY + 'followUp')">
      <div style="padding: 20px;">
        <follow-up v-if="visible1" :orderId="orderId"
                   :follow-up-statuses='useFollowUpStatuses'
                   :follow-up-not-apply-reasons='followUpNotApplyReasons'
                   :follow-up-not-apply-sub-reasons-e='followUpNotApplySubReasonsE'
                   :follow-up-not-apply-sub-reasons-g='followUpNotApplySubReasonsG'
                   :follow-up-not-apply-sub-reasons-i='followUpNotApplySubReasonsI'
                   @closeLoad='closeLoad'/>
      </div>
    </v-modal>
    <a-modal
      v-model:visible="visibleRepayUrlCard"
      :title="$t(I18N_KEY + 'repayUrl')"
      ok-text="确认"
      cancel-text="取消"
      @ok="hideModal"
    >
      <p>{{ repayUrl }}</p>
      <p><a-button type="primary" @click='copy(repayUrl)'>{{$t(I18N_KEY + 'copy')}}</a-button></p>
    </a-modal>
<!--    <follow-up ref="modalForm2" :follow-up-statuses='followUpStatuses' :orderId="orderId" @ok="modalFormOk2"></follow-up>-->
  </div>
</template>
<script>
import { filterObj } from '@/utils/util';
import { dataList, teleMarketUsers, baseData, recycle, reAssign, seatOperate } from '@/webpublicapi/teleMarket'
import moment from 'moment';
import vModal from '../modules/Modal';
import exportFile from '@/api/exportFile'
import AssignModal from './modules/AssignModal'
import RecycleModal from './modules/RecycleModal'
import TeleMarketDetail from './TeleMarketDetail';
import FollowUp from './FollowUp';
import { createRepayUrlFunc } from '@/webpublicapi/order'
import {mapGetters} from "vuex";

const I18N_KEY = 'teleMarket.';
export default {
  name: 'TeleMarketData',
  components: {
    vModal,
    AssignModal,
    RecycleModal,
    TeleMarketDetail,
    FollowUp,
  },
  data () {
    return {
      I18N_KEY,
      toggleSearchStatus:false,
      toggleSearchStatus1:false,
      visible: false,
      visible1: false,
      confirmLoading: false,
      visibleRepayUrlCard: false,
      repayUrl: '',
      orderId: undefined,
      types: [],
      followStatuses: [],
      followUpStatuses: [],
      useFollowUpStatuses:[],
      followUpStatuses1: [],// 针对首复贷
      followUpStatuses2: [],// 针对提前结清
      followUpNotApplyReasons: [],
      followUpNotApplySubReasonsE: [],
      followUpNotApplySubReasonsG: [],
      followUpNotApplySubReasonsI: [],
      t1ConvertStatuses: [],
      // assignStatuses: [],
      teleMarketUsers: [],
      typeValues:[],
      apps:[],
      fieldName: 'textCn',
      showExportButton: false,
      showScrmExportButton: false,
      teleMarketUserValues:[],
      exportTypes: ['t1_convert'],
      pageSizeOptions: ['10', '20', '30', '50', '70', '90', '100'],
      form: {
        pageNum: 1,
        pageSize: 10,
      },
      view: false,
      total: 0,
      dataSource: [],
      // 查询条件
      queryParam: {
        "user": '',
        "type": '',
        "registerTime": '',
        "assignTime": [],
        "followStatus2": '',
        "appSsid": '',
        "mobile": '',
        "email": '',
        "username": '',
        "followUpTag": '',
        "notApplyReason": '',
        "notApplySubReason": '',
        "followUpDate": [],
        "exportType": '',
        "t1ConvertStatus": '',
      },
      loading: false,
      // 表头
      isorter: {
        column: 'createTime',
        order: 'desc'
      },
      labelCol: {
        xs: { span: 8 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 16 },
        sm: { span: 19 }
      }
    }
  },

  computed: {
    ...mapGetters('language', ['languageStorage']),
    columns () {
      return [
        {
          title: this.$t(I18N_KEY + 'assignTime'),
          align: 'center',
          width: 100,
          dataIndex: 'assignTime'
        },
        {
          title: this.$t(I18N_KEY + 'app'),
          align: 'center',
          width: 150,
          dataIndex: 'appSsid',
          customRender: (text, record) => {
            var text = text;
            return this.getAppName(text);
          }
        },
        {
          title: this.$t(I18N_KEY + 'registerTime'),
          align: 'center',
          width: 150,
          dataIndex: 'registerTime'
        },
        {
          title: this.$t(I18N_KEY + 'type'),
          align: 'center',
          width: 200,
          dataIndex: 'type',
          scopedSlots: { customRender: 'type' }
        },
        {
          title: this.$t(I18N_KEY + 'curStage'),
          align: 'center',
          width: 150,
          dataIndex: 'curStage',
          scopedSlots: { customRender: 'curStage' }
        },
        {
          title: this.$t(I18N_KEY + 'followStatus'),
          align: 'center',
          width: 100,
          dataIndex: 'followStatus2',
          scopedSlots: { customRender: 'followStatus2' }
        },
        {
          title: this.$t(I18N_KEY + 'convertStatus'),
          align: 'center',
          width: 100,
          dataIndex: 't1ConvertStatus',
          scopedSlots: { customRender: 't1ConvertStatus' }
        },
        {
          title: this.$t(I18N_KEY + 'teleMarketUser'),
          align: 'center',
          width: 100,
          dataIndex: 'user',
          customRender: (text, record) => {
            var text = text;
            return this.getUserLabel(text);
          }
        },
        {
          title: this.$t(I18N_KEY + 'mobile'),
          align: 'center',
          width: 120,
          dataIndex: 'mobileEncrypt'
        },
        {
          title: this.$t(I18N_KEY + 'email'),
          align: 'center',
          width: 200,
          dataIndex: 'email'
        },
        {
          title: this.$t(I18N_KEY + 'userName'),
          align: 'center',
          width: 120,
          dataIndex: 'username'
        },
        {
          title: this.$t(I18N_KEY + 'lastSettleTime'),
          align: 'center',
          width: 150,
          dataIndex: 'lastSettleTime'
        },
        {
          title: this.$t(I18N_KEY + 'lastApplyTime'),
          align: 'center',
          width: 150,
          dataIndex: 'lastApplyTime'
        },
        {
          title: this.$t(I18N_KEY + 'lastFollowUpTime'),
          align: 'center',
          width: 150,
          dataIndex: 'lastFollowTime'
        },
        {
          title: this.$t(I18N_KEY + 'lastFollowUpStatus'),
          align: 'center',
          width: 200,
          dataIndex: 'lastFollowStatuses',
          customRender: (text, record) => {
            var text = text;
            var result = ''
            for(var item in text) {
              if(result === '') {
                result = this.$t(I18N_KEY + 'followUpStatus_' + text[item])
              } else {
                result = result+','+this.$t(I18N_KEY + 'followUpStatus_' + text[item])
              }
            }
            return result;
          }
        },
        {
          title: this.$t(I18N_KEY + 'lastNotApplyReason'),
          align: 'center',
          width: 150,
          dataIndex: 'lastNotApplyReason',
          // scopedSlots: { customRender: 'notApplyReason' }
          customRender: (text, record) => {
            var text = text;
            if(text != undefined && text != null && text != '') {
              // return this.$t(I18N_KEY + 'notApplyReason_' + text)
              for(var i in this.followUpNotApplyReasons) {
                if(text === this.followUpNotApplyReasons[i].value) {
                  return this.followUpNotApplyReasons[i][this.fieldName]
                }
              }
            } else {
              return '';
            }
          }
        },
        {
          title: this.$t(I18N_KEY + 'lastNotApplySubReason'),
          align: 'center',
          width: 150,
          dataIndex: 'lastNotApplySubReason',
          // scopedSlots: { customRender: 'notApplyReason' }
          customRender: (text, record) => {
            var text = text;
            if(text != undefined && text != null && text != '') {
              var pre = text.substring(0,1)
              var followUpNotApplySubReasons = [];
              switch (pre) {
                case 'e': followUpNotApplySubReasons = this.followUpNotApplySubReasonsE; break;
                case 'g': followUpNotApplySubReasons = this.followUpNotApplySubReasonsG; break;
                case 'i': followUpNotApplySubReasons = this.followUpNotApplySubReasonsI; break;
                default: followUpNotApplySubReasons = [];
              }
              if(followUpNotApplySubReasons != []) {
                for(var i in followUpNotApplySubReasons) {
                  if(text === followUpNotApplySubReasons[i].value) {
                    return followUpNotApplySubReasons[i][this.fieldName]
                  }
                }
              }
            } else {
              return '';
            }
          }
        },
        {
          title: this.$t(I18N_KEY + 'lastFollowRemark'),
          align: 'center',
          width: 250,
          dataIndex: 'lastFollowRemark'
        },
        {
          title: this.$t('action'),
          dataIndex: 'action',
          align: 'center',
          fixed: 'right',
          width: 180,
          scopedSlots: { customRender: 'action' }
        }
      ]
    },
  },
  created () {
    this.loadBaseData();
    this.loadData(1);
  },
  methods: {
    searchQuery () {
      this.loadData(1)
    },
    loadBaseData() {
      // teleMarketUsers({}).then((res) => {
      //   if (res.code === 1000) {
      //     this.teleMarketUsers = res.data;
      //     var teleMarketUserValues = [];
      //     for(var item in this.teleMarketUsers) {
      //       teleMarketUserValues.push(this.teleMarketUsers[item].value)
      //     }
      //     this.teleMarketUserValues = teleMarketUserValues;
      //     console.log(this.teleMarketUserValues)
      //   } else {
      //     this.$message.error(res.msg)
      //   }
      // }).catch(e => {
      //   this.$message.error(e.message);
      // })
      baseData({}).then((res) => {
        if (res.code === 1000) {
          this.teleMarketUsers = res.data.seats;
          var teleMarketUserValues = [];
          for(var item in this.teleMarketUsers) {
            teleMarketUserValues.push(this.teleMarketUsers[item].value)
          }
          this.teleMarketUserValues = teleMarketUserValues;
          this.types = res.data.types;
          var typeValues = [];
          for(var item in this.types) {
            typeValues.push(this.types[item].value)
          }
          this.typeValues = typeValues;
          this.followStatuses = res.data.followStatuses;
          this.followUpStatuses = res.data.followUpStatuses;
          this.followUpStatuses1 = res.data.followUpStatuses1;
          this.followUpStatuses2 = res.data.followUpStatuses2;
          this.followUpNotApplyReasons = res.data.followUpNotApplyReasons;
          this.followUpNotApplySubReasonsE = res.data.followUpNotApplySubReasonsE;
          this.followUpNotApplySubReasonsG = res.data.followUpNotApplySubReasonsG;
          this.followUpNotApplySubReasonsI = res.data.followUpNotApplySubReasonsI;
          this.t1ConvertStatuses = res.data.t1ConvertStatuses;
          this.view = res.data.view;
          // this.assignStatuses = res.data.assignStatuses;
          this.apps = res.data.apps;
          this.showExportButton = res.data.showExportButton;
          this.showScrmExportButton = res.data.showScrmExportButton;
        } else {
          this.$message.error(res.msg)
        }
      }).catch(e => {
        this.$message.error(e.message);
      })
    },
    loadData(arg) {
      // 加载数据 若传入参数1则加载第一页的内容
      if (arg === 1) {
        this.form.pageNum = 1
      }
      this.loading = true
      var params = this.getQueryParams()// 查询条件
      dataList(Object.assign({}, params)).then((res) => {
        if (res.code === 1000) {
          console.log(res)
          this.total = res.data.total
          this.dataSource = res.data.rows
        } else {
          this.total = 0
          this.dataSource = []
          this.$message.error(res.msg)
        }
        this.loading = false
      }).catch(e => {
        this.loading = false;
        this.$message.error(e.message);
      })
    },
    checkParam(p){
      if(p===null || p === 'null' || p === undefined || p === 'undefined') {
        return '';
      }
      return p;
    },
    downLoadData () {
      const params = this.getQueryParams1()// 查询条件
      const file = {
        fileUrl: '/api/web/webpublic/telemarket/data/export?assignStartTime=' + this.checkParam(params.assignStartTime)
          + '&assignEndTime=' + this.checkParam(params.assignEndTime)
          + '&registerTime=' + this.checkParam(params.registerTime)
          + '&user=' + this.checkParam(params.user)
          + '&type=' + this.checkParam(params.type)
          + '&followStatus=' + this.checkParam(params.followStatus)
          + '&t1ConvertStatus=' + this.checkParam(params.t1ConvertStatus)
          + '&mobile=' + this.checkParam(params.mobile)
          + '&email=' + this.checkParam(params.email)
          + '&username=' + this.checkParam(params.username)
          + '&followUpTag=' + this.checkParam(params.followUpTag)
          + '&notApplyReason=' + this.checkParam(params.notApplyReason)
          + '&appSsid=' + this.checkParam(params.appSsid)
          + "&followUpStartDate=" + this.checkParam(params.followUpStartDate)
          + "&followUpEndDate=" + this.checkParam(params.followUpEndDate),
        fileName: 'teleMarketData.xls',
      }
      this.loading = true;
      exportFile(file).catch(e => {
        this.$message.error(e.message);
      }).finally(() => {
        this.loading = false;
      })
    },
    downLoadCommonData(){
      const params = this.getQueryParams1()// 查询条件
      const file = {
        fileUrl: '/api/web/webpublic/telemarket/data/commonExport?assignStartTime=' + this.checkParam(params.assignStartTime)
          + '&assignEndTime=' + this.checkParam(params.assignEndTime)
          + '&registerTime=' + this.checkParam(params.registerTime)
          + '&user=' + this.checkParam(params.user)
          + '&type=' + this.checkParam(params.type)
          + '&followStatus=' + this.checkParam(params.followStatus)
          + '&t1ConvertStatus=' + this.checkParam(params.t1ConvertStatus)
          + '&mobile=' + this.checkParam(params.mobile)
          + '&email=' + this.checkParam(params.email)
          + '&username=' + this.checkParam(params.username)
          + '&followUpTag=' + this.checkParam(params.followUpTag)
          + '&notApplyReason=' + this.checkParam(params.notApplyReason)
          + '&appSsid=' + this.checkParam(params.appSsid)
          + "&followUpStartDate=" + this.checkParam(params.followUpStartDate)
          + "&followUpEndDate=" + this.checkParam(params.followUpEndDate)
          + "&exportType=" + this.checkParam(params.exportType),
        fileName: 'teleMarketData.xls',
      }
      this.loading = true;
      exportFile(file).catch(e => {
        this.$message.error(e.message);
      }).finally(() => {
        this.loading = false;
      })
    },
    downLoadTagData () {
      const params = this.getQueryParams1()// 查询条件
      var followUpStartDate = params.followUpStartDate;
      var followUpEndDate = params.followUpEndDate;
      if(this.checkParam(followUpStartDate) == '' || this.checkParam(followUpEndDate) == '') {
        this.$message.error(this.$t(I18N_KEY + 'downloadTagDataNotice'))
        return;
      }
      const file = {
        fileUrl: '/api/web/webpublic/telemarket/followUpTag/export?assignStartTime=' + this.checkParam(params.assignStartTime)
          + '&assignEndTime=' + this.checkParam(params.assignEndTime)
          + '&registerTime=' + this.checkParam(params.registerTime)
          + '&user=' + this.checkParam(params.user)
          + '&type=' + this.checkParam(params.type)
          + '&followStatus=' + this.checkParam(params.followStatus)
          + '&t1ConvertStatus=' + this.checkParam(params.t1ConvertStatus)
          + '&mobile=' + this.checkParam(params.mobile)
          + '&email=' + this.checkParam(params.email)
          + '&username=' + this.checkParam(params.username)
          + '&followUpTag=' + this.checkParam(params.followUpTag)
          + '&notApplyReason=' + this.checkParam(params.notApplyReason)
          + '&appSsid=' + this.checkParam(params.appSsid)
          + "&followUpStartDate=" + this.checkParam(params.followUpStartDate)
          + "&followUpEndDate=" + this.checkParam(params.followUpEndDate),
        fileName: 'teleMarketLabelData.xls',
      }
      this.loading = true;
      exportFile(file).catch(e => {
        this.$message.error(e.message);
      }).finally(() => {
        this.loading = false;
      })
    },
    transformTime(time) {
      return moment(time).format('YYYY-MM-DD')
    },
    transformStringToLow(str) {
      return str && str.toLocaleLowerCase();
    },
    assign(){
      this.$refs.modalForm.open();
    },
    recycle(){
      this.$refs.modalForm1.preRecycle();
      this.$refs.modalForm1.disableSubmit = false;
    },
    getQueryParams() {
      const { followUpDate,assignTime } = this.queryParam;
      const data = {
        ...this.queryParam,
        followUpStartDate: followUpDate && this.transformTime(followUpDate[0]),
        followUpEndDate: followUpDate && this.transformTime(followUpDate[1]),
        assignStartTime: assignTime && this.transformTime(assignTime[0]),
        assignEndTime: assignTime && this.transformTime(assignTime[1]),
      }
      if(this.queryParam.followUpDate.length === 0) {
        data['followUpStartDate'] = ''
        data['followUpEndDate']=''
      }
      if(this.queryParam.assignTime.length === 0) {
        data['assignStartTime'] = ''
        data['assignEndTime']=''
      }
      data['registerTime'] = data['registerTime'] && this.transformTime(data['registerTime']);
      // data['assignTime'] = data['assignTime'] && this.transformTime(data['assignTime']);
      console.log(data)
      var param = Object.assign({}, data)
      param.pageNum = this.form.pageNum
      param.pageSize = this.form.pageSize
      return filterObj(param)
    },

    getQueryParams1() {
      const { followUpDate,assignTime } = this.queryParam;
      const data = {
        ...this.queryParam,
        followUpStartDate: followUpDate && this.transformTime(followUpDate[0]),
        followUpEndDate: followUpDate && this.transformTime(followUpDate[1]),
        assignStartTime: assignTime && this.transformTime(assignTime[0]),
        assignEndTime: assignTime && this.transformTime(assignTime[1]),
      }
      if(this.queryParam.followUpDate.length === 0) {
        data['followUpStartDate'] = ''
        data['followUpEndDate']=''
      }
      if(this.queryParam.assignTime.length === 0) {
        data['assignStartTime'] = ''
        data['assignEndTime']=''
      }
      data['registerTime'] = data['registerTime'] && this.transformTime(data['registerTime']);
      // data['assignTime'] = data['assignTime'] && this.transformTime(data['assignTime']);
      return Object.assign({}, data)
    },
    handleTableChange(pageNum, pageSize) {
      this.form.pageNum = pageNum;
      this.form.pageSize = pageSize;
      this.loadData();
    },
    // 重置字典类型搜索框的内容
    searchReset() {
      var that = this
      that.queryParam.user = ''
      that.queryParam.assignTime = []
      that.queryParam.registerTime = ''
      that.queryParam.type = '';
      that.queryParam.followStatus2 = '';
      that.queryParam.t1ConvertStatus = '';
      that.queryParam.appSsid = '';
      that.queryParam.mobile = '';
      that.queryParam.email = '';
      that.queryParam.username = '';
      that.queryParam.followUpTag = '';
      that.queryParam.notApplyReason = '';
      that.queryParam.followUpDate = [];
    },
    changeModal(visible) {
      this.visible = visible;
    },
    handleDetail(row) {
      this.orderId = row.id;
      this.changeModal(true);
    },
    getUserLabel(user) {
      for(var item in this.teleMarketUsers) {
        var userObj = this.teleMarketUsers[item];
        if(userObj.value === user){
          return userObj.label;
        }
      }
    },
    modalFormOk1(data) {
      this.doRecycle(data);
    },
    doRecycle(data) {
      console.log(data)
      data['assignStartTime'] = data['assignTime'] && this.transformTime(data['assignTime'][0]);
      data['assignEndTime'] = data['assignTime'] && this.transformTime(data['assignTime'][1]);
      const form = {
        ...data,
      };
      recycle(form).then(res => {
        this.$refs.modalForm1.closeLoading();
        this.$refs.modalForm1.close();
        if (res.code === 1000) {
          this.$message.success(res.msg);
          this.loadData(1);
        } else {
          this.$message.error(res.msg);
        }
      }).catch(e => {
        this.$refs.modalForm1.closeLoading();
        this.$message.error(e.message);
      })
    },
    getAppName(appSsid) {
      for(var item in this.apps) {
        var appObj = this.apps[item];
        if(appObj.appSsid === appSsid) {
          return appSsid+ '/'+appObj.appName;
        }
      }
      return appSsid;
    },
    followUp(row){
      this.orderId = row.id;
      var type = row.type;
      if(type == '8' || type == '9') {
        this.useFollowUpStatuses = this.followUpStatuses2;
      } else {
        this.useFollowUpStatuses = this.followUpStatuses1;
      }
      this.visible1 = true
    },
    closeLoad() {
      this.visible1 = false
      this.loadData(1)
    },
    rowClassName(record,index) {
      var followStatus = record.followStatus2;
      var t1ConvertStatus = record.t1ConvertStatus;
      if(t1ConvertStatus == '1') {
        return 'teleMarketDataRowCompletedColor';
      } else if(followStatus == '1') {// 已跟进
        return 'teleMarketDataRowFollowedColor'
      }
      return '';
    },
    handleRepayUrl(record) {
      createRepayUrlFunc({ orderId:record.orderId}).then(res => {
        if(1000 != res.code){
          alert(res.msg);
          return;
        }else{
          this.repayUrl = res.data.url;
          this.visibleRepayUrlCard = true;
        }
      }).catch(e => {
        self.loading = false;
        this.$message.error(e.message);
        return;
      })
    },
    hideModal(){
      this.visibleRepayUrlCard = false
      this.repayUrl = ''
    },
    copy(e) { //复制方法
      let input = document.createElement('input')
      input.value = e
      document.body.appendChild(input)
      input.select()
      document.execCommand("copy")
      document.body.removeChild(input)
      this.$message.info("copy success");
    },
    // handle(record) {
    //   const self = this;
    //   this.$confirm({
    //     title: this.$t(I18N_KEY + 'inputOptRemark'),// 请输入操作备注
    //     content: h => {
    //       return <div>
    //         <div>
    //           Remark ：<input id='optRemark' type='text'/>
    //         </div>
    //       </div>
    //     },
    //     onOk() {
    //       self.loading = true;
    //       var optRemark = document.getElementById("optRemark").value;
    //
    //       if (!optRemark.trim()) {
    //         alert(this.$t(I18N_KEY + 'optRemarkMustNotNull'));// 操作备注不能为空
    //         self.loadData();
    //         return false;
    //       }
    //       handleWorkOrder({id: record.id, optRemark: optRemark}).then(res => {
    //         if (res.code === 1000) {
    //           self.loadData();
    //         } else {
    //           self.$message.error(res.msg);
    //           self.loadData();
    //         }
    //
    //       }).catch(e => {
    //         self.loading = false;
    //         self.$message.error(e.message);
    //       })
    //     },
    //   });
    // },
  },
  watch: {
    languageStorage: {
      handler (lang) {
        if (lang == 'en-US') {
          this.fieldName = 'textEn';
        } else if (lang == 'zh-CN') {
          this.fieldName = 'textCn';
        } else if (lang == 'es') {
          this.fieldName = 'textEs';
        } else {
          this.fieldName = 'textEn';
        }
      },
      immediate: true,
    }
  },
}
</script>
<style lang="less" scoped>
@import "~@/assets/less/common.less";
</style>
<style>
  .teleMarketDataRowCompletedColor {
    background-color: lightskyblue;
  }
  .teleMarketDataRowFollowedColor {
    background-color: aquamarine;
  }
</style>