<template>
  <div :bordered="false">
    <div class="table-page-search-wrapper">
      <a-form layout="inline" @keyup.enter.native="searchQuery">
        <a-row :gutter="24">
          <a-col :md="6" :sm="8">
            <a-form-item :label="$t(I18N_KEY + 'orderNo')">
              <a-input :placeholder="$t('enter') + ' ' + transformStringToLow($t(I18N_KEY + 'orderNo'))" v-model="queryParam.orderNo"></a-input>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="8">
            <a-form-item :label="$t('phoneNo')">
              <a-input
                :placeholder="$t('enter') + ' ' + transformStringToLow($t('phoneNo'))"
                v-model="queryParam.mobileNo"></a-input>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="8">
            <a-form-item :label="$t('name')">
              <a-input
                :placeholder="$t('enter') + ' ' + transformStringToLow($t('names'))"
                v-model="queryParam.names"></a-input>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="8">
            <span style="float: left;overflow: hidden;" class="table-page-search-submitButtons">
              <a-button type="primary" @click="searchQuery" icon="search">{{ $t('search') }}</a-button>
              <a-button type="primary" @click="searchReset" icon="reload" style="margin-left: 8px">{{ $t('reset') }}</a-button>
            </span>
          </a-col>
        </a-row>
      </a-form>

      <a-table
        ref="table"
        rowKey="id"
        size="middle"
        :columns="columns"
        :dataSource="dataSource"
        :scroll="{ x: 2300, y: 2000 }"
        :pagination="false"
        :loading="loading"
        bordered>
        <Dict slot="loanStatus" slot-scope="text" :dict-code="DICT_CODE.LOAN_STATUS" :value="text"/>
        <Dict slot="approvalStatus" slot-scope="text" :dict-code="DICT_CODE.ORDER_STATUS" :value="text"/>
        <Dict slot="settleStatus" slot-scope="text" :dict-code="DICT_CODE.INSTALLMENT_SETTLE_STATUS" :value="text"/>
        <Dict slot="overdueStatus" slot-scope="text" :dict-code="DICT_CODE.OVERDUE_STATUS" :value="text"/>
        <span slot="action" slot-scope="text, record">
          <a @click="handleOrderDetail(record)" v-if='record.approvalStatus == 4 && record.loanStatus == 1'>
            还款计划
          </a>
          <a @click="createRepayUrl(record)" v-if='record.approvalStatus == 4 && record.loanStatus == 1'>
            还款链接
          </a>
<!--          <a @click="handleLoan(record.id)" v-if='record.approvalStatus == 4 && record.loanStatus == 0'>-->
<!--            放款-->
<!--          </a>-->
<!--          <a @click="handleOrderEdit(record)" v-if='record.approvalStatus != 4'>-->
<!--            订单编辑-->
<!--          </a>-->
<!--          <a @click="handleEdit(record)" v-if='record.approvalStatus == 4 && record.loanStatus == 1 && record.settleStatus == 0'>-->
<!--            编辑还款计划-->
<!--          </a>-->
        </span>

<!--        <a-table-->
<!--          slot="expandedRowRender"-->
<!--          slot-scope="text"-->
<!--          ref="table"-->
<!--          size="middle"-->
<!--          :columns="innerColumns"-->
<!--          :data-source="text.repayPlans"-->
<!--          :loading="loading"-->
<!--          :pagination="false"-->
<!--          bordered>-->
<!--        </a-table>-->
      </a-table>
      <div style="margin-top: 15px; text-align: right;">
          <v-pagination
              v-if="total"
              :page-num="form.pageNum"
              :page-size="form.pageSize"
              :total="total"
              @change="handleTableChange" />
      </div>
    </div>
    <v-modal
        v-model="visible"
        :title="$t(I18N_KEY + 'orderDetail')">
        <div style="padding: 20px;">
            <order-detail v-if="visible" :orderId="orderId" :custInfoId="custInfoId"/>
        </div>
    </v-modal>
    <TestInstallOrderModal ref="modalForm" @ok="modalFormOk"></TestInstallOrderModal>
    <TestInstallOrderModalT ref="modalFormT" @ok="modalFormOkT"></TestInstallOrderModalT>
    <TestInstallOrderListModal ref="modalFormF" ></TestInstallOrderListModal>

    <a-modal
      v-model:visible="visibleRepayUrlCard"
      :title="$t('createRepayUrl')"
      ok-text="确认"
      cancel-text="取消"
      @ok="hideModal"
    >
      <p>{{ repayUrl }}</p>
      <p><a-button type="primary" @click='copy(repayUrl)'>{{$t('teleMarket.copy')}}</a-button></p>
    </a-modal>
  </div>
</template>

<script>
import { filterObj } from '@/utils/util'
import {
  getPlatformAllOrderList,
  clearOrderForTest,
  getInstallmentPlatformAllOrderList,
  installmentLoanOrder, getInstallmentPlatformAllOrderListPrd, createRepayUrlFunc, createInstallmentRepayUrl
} from '@/webpublicapi/order'
import Dict from '../modules/Dict';
import DictSelect from '../modules/DictSelect';
import vModal from '../modules/Modal';
import OrderDetail from '../order/OrderDetail';
import moment from 'moment';
import TestInstallOrderModal from './modules/TestInstallOrderModal';
import TestInstallOrderModalT from './modules/TestInstallOrderModalT';
import TestInstallOrderListModal from './modules/TestInstallOrderListModal';

import exportFile from '@/webpublicapi/exportFile'
import md5 from 'md5'
import { extendOffline } from '@/api/order'


const I18N_KEY = 'order.';
export default {
  name: 'TestInstallOrderList',
  components: {
    Dict,
    DictSelect,
    vModal,
    OrderDetail,
    TestInstallOrderModal,
    TestInstallOrderModalT,
    TestInstallOrderListModal,
  },
  data () {
    return {
      I18N_KEY,
      visibleRepayUrlCard: false,
      toggleSearchStatus:false,
      visible: false,
      orderId: undefined,
      custInfoId: undefined,
      form: {
        pageNum: 1,
        pageSize: 10,
      },
      total: 0,
      dataSource: [],
      // 查询条件
      queryParam: {
        "orderNo":"",
        "mobileNo":"",
        "names":"",
        "appssid":"",
        "aadhaarName":"",
        "createdTime":undefined,
        "loanStatus": '',
        "approvalStatus": '',
        "settleStatus": '',
      },
      loading: false,
      // 表头
      isorter: {
        column: 'createTime',
        order: 'desc'
      },
      labelCol: {
        xs: { span: 8 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 16 },
        sm: { span: 19 }
      }
    }
  },
  computed: {
    columns () {
      return [
        {
          title: this.$t(I18N_KEY + 'orderNo'),
          align: 'left',
          dataIndex: 'orderNo'
        },
        {
          title: this.$t('phoneNo'),
          align: 'left',
          dataIndex: 'mobileNo'
        },
        {
          title: this.$t('names'),
          align: 'left',
          dataIndex: 'names'
        },
        {
          title: '盘号',
          align: 'left',
          dataIndex: 'appSsid'
        },
        {
          title: this.$t(I18N_KEY + 'applicationAmount'),
          align: 'left',
          dataIndex: 'applyAmt'
        },
        // {
        //   title: this.$t(I18N_KEY + 'deductionFee'),
        //   align: 'left',
        //   dataIndex: 'deductionFee'
        // },
        {
          title: this.$t(I18N_KEY + 'deadline'),
          align: 'left',
          dataIndex: 'duration'
        },
        {
          title: this.$t(I18N_KEY + 'loanStatus'),
          align: 'left',
          dataIndex: 'loanStatus',
          scopedSlots: { customRender: 'loanStatus' }
        },
        {
          title: this.$t(I18N_KEY + 'approvalStatus'),
          align: 'left',
          dataIndex: 'approvalStatus',
          scopedSlots: { customRender: 'approvalStatus' }
        },
        {
          title: this.$t(I18N_KEY + 'settlementStatus'),
          align: 'left',
          dataIndex: 'settleStatus',
          scopedSlots: { customRender: 'settleStatus' }
        },
        // {
        //   title: this.$t(I18N_KEY + 'overdueStatus'),
        //   align: 'left',
        //   dataIndex: 'overdueStatus',
        //   scopedSlots: { customRender: 'overdueStatus' }
        // },
        {
          title: this.$t(I18N_KEY + 'applicationTime'),
          align: 'left',
          dataIndex: 'createdTime'
        }
        ,
        {
          title: this.$t('action'),
          dataIndex: 'action',
          align: 'center',
          fixed: 'right',
          width: 300,
          scopedSlots: { customRender: 'action' }
        }
      ]
    },

    innerColumns () {
       return [
         {
           title: '期数',
           align: 'left',
           dataIndex: 'period',
         },
         {
           title: '开始日',
           align: 'left',
           dataIndex: 'beginDate',
         },
         {
           title: '结束日',
           align: 'left',
           dataIndex: 'endDate'
         },
         {
           title: '本金',
           align: 'left',
           dataIndex: 'capital'
         },
         {
           title: '已还本金',
           align: 'left',
           dataIndex: 'settledCapital'
         },
         {
           title: '利息',
           align: 'left',
           dataIndex: 'interest'
         },
         {
           title: '服务费',
           align: 'left',
           dataIndex: 'serviceFee'
         },
         {
           title: '滞纳金',
           align: 'left',
           dataIndex: 'lateFee'
         },
         {
           title: '其他费用',
           align: 'left',
           dataIndex: 'otherFee'
         },
         {
           title: '抵扣费用',
           align: 'left',
           dataIndex: 'deductionFee'
         },
         {
           title: '状态',
           align: 'left',
           dataIndex: 'status'
         }
       ]
    }
  },
  created () {
    // this.loadData(1)
  },
  methods: {
    handleToggleSearch(){
      this.toggleSearchStatus = !this.toggleSearchStatus;
    },
    searchQuery () {
      this.loadData(1)
    },
    loadData (arg) {
      if (arg === 1) {
        this.form.pageNum = 1
      }
      this.loading = true
      var params = this.getQueryParams()// 查询条件
      getInstallmentPlatformAllOrderListPrd(Object.assign({}, params)).then((res)=>{
        if(res.code===1000){
          this.total = res.data.total
          this.dataSource = res.data.rows
        }else{
          this.total = 0
          this.dataSource = []
          this.$message.error(res.msg)
        }
        this.loading = false
      }).catch(e => {
            this.loading = false;
            this.$message.error(e.message);
      })
    },
    transformTime (time) {
      return moment(time).format('YYYY-MM-DD')
    },
    transformStringToLow (str) {
        return str && str.toLocaleLowerCase();
    },
    getQueryParams () {
      const { createdTime } = this.queryParam;
      const data = {
          ...this.queryParam,
          applyDateStart: createdTime && this.transformTime(createdTime[0]),
          applyDateEnd: createdTime && this.transformTime(createdTime[1]),
      }
      delete data.createdTime;
      var param = Object.assign({}, data)
      param.pageNum = this.form.pageNum
      param.pageSize = this.form.pageSize
      return filterObj(param)
    },
    handleTableChange (pageNum, pageSize) {
        this.form.pageNum = pageNum;
        this.form.pageSize = pageSize;
        this.loadData();
    },
    // 重置字典类型搜索框的内容
    searchReset () {
      var that = this
      that.queryParam.orderNo = ''
      that.queryParam.mobileNo = ''
      that.queryParam.names = ''
       that.queryParam.aadhaarName = ''
       that.queryParam.createdTime = undefined;
      that.queryParam.loanStatus = ''
      that.queryParam.approvalStatus = ''
      that.queryParam.settleStatus = ''
      that.loadData(this.form.pageNum)
    },
    changeModal (visible) {
        this.visible = visible;
    },
    handleDetail (row) {
        this.orderId = row.id;
        this.custInfoId = row.custInfoId;
        this.changeModal(true);
    },
    handleOrderDetail (record) {
      this.$refs.modalFormF.edit(record)
      this.$refs.modalFormF.title = '详情'
    },
    createRepayUrl(record) {
      createInstallmentRepayUrl({ orderId:record.id}).then(res => {
        if(1000 != res.code){
          alert(res.msg);
          return;
        }else{
          this.repayUrl = res.data.url;
          this.visibleRepayUrlCard = true;
        }
      }).catch(e => {
        self.loading = false;
        this.$message.error(e.message);
        return;
      })
    },
    handleOrderEdit (record) {
      this.$refs.modalFormT.edit(record)
      this.$refs.modalFormT.title = '编辑'
    },
    handleEdit (record) {
      this.$refs.modalForm.edit(record)
      this.$refs.modalForm.title = '编辑'
    },
    handleLoan(id){
      const self = this;
      this.$confirm({

        title: '发起放款',
        // content: h => {
        //   return <div>
        //     <div>
        //       订单编号：id.id
        //     </div>
        //   </div>
        // },
        onOk() {
          self.loading = true;

          installmentLoanOrder({id}).then((res) => {
            console.log(res)
            if (res.code === 1000) {
              console.log('success')
              self.$message.success(res.msg)
              self.loadData()
            } else {
              console.log('failed')
              self.$message.error(res.msg)
              self.loadData();
            }
          }).catch(e => {
            self.loading = false;
            self.$message.error(e.message);
          })
        },
      });

    },
    handleClear(id){
      clearOrderForTest({id}).then((res) => {
        if (res.code === 1000) {
          this.$message.success(res.msg)
          this.loadData()
        } else {
          this.$message.warning(res.msg)
        }
      })
    },
    downLoadData (record, type) {
      const file = {
        fileUrl: '/api/web/webpublic/order/test/getJsonData?orderId=' + record.id + '&type=' + type ,
        fileName: 'jsonForCustExcel.txt',
      }
      this.loading = true;
      exportFile(file).catch(e => {
        this.$message.error(e.message);
      }).finally(() => {
        this.loading = false;
      })
    },
    // model回调
    modalFormOk () {
      this.loadData()
    },
    modalFormOkT () {
      this.loadData()
    },
    copy(e) { //复制方法
      let input = document.createElement('input')
      input.value = e
      document.body.appendChild(input)
      input.select()
      document.execCommand("copy")
      document.body.removeChild(input)
      this.$message.info("copy success");
    }
  }
}
</script>
<style lang="less" scoped>
 @import "~@/assets/less/common.less";
</style>
