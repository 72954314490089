<template>
  <a-card :bordered="false">
    <div class="table-page-search-wrapper">
      <a-spin :spinning="spinning">
        <a-form layout="inline" @keyup.enter.native="searchQuery">
          <a-row :gutter="12">
            <a-col :span="12">
              <a-form-item :label="'到期日'" :labelCol="{ span: 6 }" :wrapperCol="{ span: 14, offset: 1 }">
                <a-range-picker v-model="queryParam.dataDt" format="YYYY-MM-DD" />
              </a-form-item>
            </a-col>
            <a-button type="primary" @click="searchQuery" icon="search">{{ $t('search') }}</a-button>
          </a-row>

          <a-row :gutter="12">
            <a-col :span="6">
              <a-form-item :label="$t('单数')">
                <a-input v-model="queryParam.orderLimit" />
              </a-form-item>
            </a-col>
          </a-row>

          <a-row :gutter="12">
            <a-col :md="6" :sm="8">
              <a-form-item :label="'用户类型'">
                <a-select default-value="" style="width: 120px" v-model="queryParam.loanCountFlag">
                  <a-select-option value=""> 全部 </a-select-option>
                  <a-select-option value="00"> 新户 </a-select-option>
                  <a-select-option value="01"> 老户 </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="8">
              <a-form-item :label="$t('order.applicationDeadline')">
                <a-select mode="multiple" default-value="" style="width: 190px" v-model="queryParam.durations">
                  <a-select-option v-for="item in deadlineConfigList" :key="item.itemValue">
                    {{ item.itemText }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
          </a-row>

          <a-row :gutter="12">
            <a-col :span="12">
              <a-form-item :label="'更新后到期日'" :labelCol="{ span: 6 }" :wrapperCol="{ span: 14, offset: 1 }">
                <a-date-picker v-model="queryParam.afterDataDt" format="YYYY-MM-DD" />
              </a-form-item>
            </a-col>
          </a-row>

          

          <a-row>
            <a-col :md="6" :sm="8">
            <a-form-item :label="'产品类型'" v-if="appList">
              <a-select mode="multiple" v-model="queryParam.appssids">
                <a-select-option v-for="item in appList" :key="item.appssid">
                  {{ item.appName }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
            <a-col>
              <span style="float: left; overflow: hidden" class="table-page-search-submitButtons">
                <a-button type="primary" @click="insertRedis()" icon="edit" style="margin-left: 8px"
                  >重新入Redis队列</a-button
                >
              </span>
            </a-col>
          </a-row>
          <a-row>
            <a-col>
              <span style="float: left; overflow: hidden" class="table-page-search-submitButtons">
                <a-button type="primary" @click="colSalvage()" icon="edit" style="margin-left: 8px">更新</a-button>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </a-spin>

      <a-table
        ref="table"
        rowKey="id"
        size="middle"
        :columns="columns"
        :dataSource="dataSource"
        :pagination="false"
        :loading="loading"
        bordered
      >
      </a-table>
    </div>
  </a-card>
</template>

<script>
import { filterObj } from '@/utils/util'
import moment from 'moment'
import { colSalVageWeb, getColSakVage, insertRedisWeb } from '@/webpublicapi/platformCollection'
import { getAllDeadlineConfig } from '@/webpublicapi/deadlineConfig'
import { getApplist, getRepaymentReportForBeginDate } from '@/webpublicapi/order'

export default {
  name: 'AppList',
  components: {},
  data() {
    return {
      visible: false,
      spinning: false,
      dataSource: [],
      loading: false,
      headers: {
        // authorization: 'authorization-text',
      },
      appList: [],
      // 查询条件
      deadlineConfigList: [],
      queryParam: {
        dataDt: undefined,
        afterDataDt: undefined,
        orderLimit: 0,
        durations: undefined,
        appssids: undefined,
      },
    }
  },
  computed: {
    columns() {
      return [
        {
          title: '全部',
          align: 'left',
          dataIndex: 'allCount',
        },
        {
          title: '新户数量',
          align: 'left',
          dataIndex: 'newCustCount',
        },
        {
          title: '老户数量',
          align: 'left',
          dataIndex: 'oldCustCount',
        },
      ]
    },
  },
  created() {
    getAllDeadlineConfig().then((res) => {
      this.deadlineConfigList = res.data || []
    })
    getApplist().then((res) => {
      this.appList = res.data || []
      console.log(111111111, this.appList)
    })
  },
  methods: {
    getQueryParams() {
      const { dataDt } = this.queryParam
      const { afterDataDt } = this.queryParam
      const data = {
        ...this.queryParam,
        startDate: dataDt && this.transformTime(dataDt[0]),
        endDate: dataDt && this.transformTime(dataDt[1]),
        afterEndDate: afterDataDt && this.transformTime(afterDataDt),
      }
      delete data.dataDt
      delete data.afterDataDt
      return filterObj(data)
    },
    searchQuery() {
      this.loadData()
    },
    loadData() {
      this.loading = true
      var params = this.getQueryParams() // 查询条件

      getColSakVage(Object.assign({}, params))
        .then((res) => {
          if (res.code === 1000) {
            this.dataSource = res.data
          } else {
            this.dataSource = []
            this.$message.error(res.msg)
          }
          this.loading = false
        })
        .catch((e) => {
          this.loading = false
          this.$message.error(e.message)
        })
    },
    transformTime(time) {
      return moment(time).format('YYYY-MM-DD')
    },
    colSalvage() {
      this.spinning = true
      var params = this.getQueryParams() // 查询条件

      colSalVageWeb(Object.assign({}, params))
        .then((res) => {
          if (res.code === 1000) {
            this.$message.info('更新完成')
          } else {
            this.$message.error(res.msg)
          }
          this.spinning = false
        })
        .catch((e) => {
          this.spinning = false
          this.$message.error(e.message)
        })
    },
    insertRedis() {
      this.spinning = true
      var params = this.getQueryParams() // 查询条件
      insertRedisWeb(Object.assign({}, params))
        .then((res) => {
          if (res.code === 1000) {
            this.$message.info('入redis队列执行成功')
          } else {
            this.$message.error(res.msg)
          }
          this.spinning = false
        })
        .catch((e) => {
          this.spinning = false
          this.$message.error(e.message)
        })
    },
  },
}
</script>
<style lang="less" scoped>
@import '~@/assets/less/common.less';
</style>
