<template>
  <a-modal
    :title="title === '1' ? $t('add') : $t('edit')"
    :width="900"
    :visible="visible"
    :confirmLoading="confirmLoading"
    @ok="handleOk"
    @cancel="handleCancel"
    :cancelText="$t('close')"
    wrapClassName="ant-modal-cust-warp"
    style="top:5%;overflow-y: hidden">

    <a-spin :spinning="confirmLoading">
      <a-form :form="form">

<!--        <a-form-item-->
<!--          :labelCol="labelCol"-->
<!--          :wrapperCol="wrapperCol"-->
<!--          :label="$t(I18N_KEY + 'seatMobile')">-->
<!--          <a-input :placeholder="$t('enter') + ' ' + $t(I18N_KEY + 'seatMobile')" v-decorator="[ 'mobile', validatorRules.mobile]" />-->
<!--        </a-form-item>-->
        <a-form-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          :label="$t(I18N_KEY + 'seatMobile')">
          <a-select v-decorator="[ 'mobile', validatorRules.mobile]" :placeholder="$t('select') + ' ' + $t(I18N_KEY + 'seatMobile')">
            <a-select-option v-for='item in teleMarketUsers' :key='item.value'>
              {{item.label}}
            </a-select-option>
          </a-select>

        </a-form-item>

        <a-form-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          :label="$t(I18N_KEY + 'seat')">
          <a-input :placeholder="$t('enter') + ' ' + $t(I18N_KEY + 'seat')" v-decorator="[ 'seat', validatorRules.seat]" />
        </a-form-item>

        <a-form-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          :label="$t(I18N_KEY + 'nxAccount')">
          <a-input :placeholder="$t('enter') + ' ' + $t(I18N_KEY + 'nxAccount')" v-decorator="[ 'nxAccount', validatorRules.nxAccount]" />
        </a-form-item>

        <a-form-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          :label="$t(I18N_KEY + 'nxPassword')">
          <a-input :placeholder="$t('enter') + ' ' + $t(I18N_KEY + 'nxPassword')" v-decorator="[ 'nxPassword', validatorRules.nxPassword]" />
        </a-form-item>

        <a-form-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          :label="$t(I18N_KEY + 'seatStatus')">
          <a-radio-group
            v-decorator="['status', { initialValue: '0' }]">
            <!--            <a-radio value="0">不强更</a-radio>-->
            <!--            <a-radio value="1">强更</a-radio>-->
            <span v-for="item in this.statuses">
              <a-radio :value=item>{{ $t(I18N_KEY + 'seatStatus_' + item) }}</a-radio>
            </span>
          </a-radio-group>
        </a-form-item>





      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>
import pick from 'lodash.pick'
const I18N_KEY = 'teleMarket.';
export default {
  name: "SeatModal",
  props: {
    teleMarketUsers: {
      type: Array,
      default: () => [],
    },
  },
  data () {
    return {
      I18N_KEY,
      title:"操作",
      visible: false,
      statuses: ["0","1"],
      model: {},
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
      confirmLoading: false,
      form: this.$form.createForm(this),
      validatorRules:{
        mobile:{
          rules: [
            { required: true, message: 'Please input mobile!' },
          ]},
        seat:{
          rules: [
            { required: true, message: 'Please input seat!' },
          ]},
        nxAccount:{
          rules: [
            { required: false },
          ]},
        nxPassword:{
          rules: [
            { required: false },
          ]},
      },
    }
  },
  created () {
  },
  methods: {
    add () {
      this.edit({});
    },
    edit (record) {
      this.form.resetFields();
      this.open();
      this.model = Object.assign({}, record)
      this.model = {
        ...this.model,
        status: this.model.status != undefined ? '' + this.model.status : '0',
      }

      this.$nextTick(() => {
        this.form.setFieldsValue(pick(this.model,'mobile', 'seat', 'nxAccount','nxPassword','status'))
      });

    },
    open () {
      this.visible = true;
    },
    close () {
      this.$emit('close');
      this.visible = false;
    },
    openLoading () {
      this.confirmLoading = true;
    },
    closeLoading () {
      this.confirmLoading = false;
    },
    handleOk () {
      // console.log(88888)
      const that = this;
      // 触发表单验证
      this.form.validateFields((err, values) => {
        if (!err) {
          that.confirmLoading = true;
          let formData = {
            ...values,
          }
          this.$emit('ok', formData);

        }
      })
    },
    handleCancel () {
      this.close()
    },
  }
}
</script>

<style scoped>

</style>