<template>
  <div :bordered="false">
    <div class="table-page-search-wrapper">
      <a-form layout="inline" @keyup.enter.native="searchQuery">
        <a-row :gutter="24">
          <template>
            <a-col :md="8" :sm="12">
              <a-form-item :label="$t(I18N_KEY + 'dataDate')">
                <a-range-picker v-model="queryParam.createdTime" format="YYYY-MM-DD" />
              </a-form-item>
            </a-col>
          </template>

          <a-col :md="6" :sm="8">
            <a-form-item :label="$t(I18N_KEY + 'teleMarketUser')">
              <template>
                <a-select
                  v-model:value="queryParam.user"
                  show-search
                  :placeholder="$t('enter') + ' ' + $t(I18N_KEY + 'teleMarketUser')"
                  style="width: 200px"
                >
                  <a-select-option value="">{{ $t(I18N_KEY+'all') }}</a-select-option>
                  <a-select-option v-for="item in teleMarketUsers" :value="item.value">
                    {{item.label }}
                  </a-select-option>
                </a-select>
              </template>
            </a-form-item>
          </a-col>

          <a-col :md="6" :sm="8">
            <a-form-item :label="$t(I18N_KEY + 'loanType')">
              <a-select v-model="queryParam.loanType" :placeholder="$t('select')" style="width: 100%">
                <a-select-option value="">{{ $t(I18N_KEY+'all') }}</a-select-option>
                <a-select-option v-for='item in loanTypes' :key='item.value'>
                  {{$t(I18N_KEY + 'loanType_' + item.label)}}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :md="8" :sm="8">
            <a-form-item :label="$t(I18N_KEY + 'type')">
              <a-select v-model="queryParam.type" :placeholder="$t('select')" style="width: 100%">
                <a-select-option value="">{{ $t(I18N_KEY+'all') }}</a-select-option>
                <a-select-option v-for='item in types' :key='item.value'>
                  {{$t(I18N_KEY + 'type_' + item.label)}}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :md="6" :sm="8">
            <a-form-item :label="$t(I18N_KEY + 'app')">
              <a-select v-model="queryParam.appSsid" :placeholder="$t('select')" style="width: 100%;">
                <a-select-option value="">{{ $t(I18N_KEY+'all') }}</a-select-option>
                <a-select-option v-for="item in apps" :value="item.appSsid">
                  {{ item.appSsid + '/' + item.appName }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :md="7" :sm="8">
            <span style="float: left;overflow: hidden;" class="table-page-search-submitButtons">
              <a-button type="primary" @click="searchQuery" icon="search">{{ $t('search') }}</a-button>
              <a-button type="primary" @click="searchReset" icon="reload" style="margin-left: 8px">{{ $t('reset') }}</a-button>
              <a-button type="primary" v-if="view" @click="downLoadData" icon="search" style="margin-left: 8px">{{ $t('exportFile') }}</a-button>
            </span>
          </a-col>
        </a-row>
        <a-divider orientation="left" style="border-color: #7cb305" dashed >{{ $t(I18N_KEY + 'summary') }}</a-divider>
        <a-row :gutter="24">
          <a-col :md="8" :sm="8">
            <a-form-item :label="$t(I18N_KEY + 'totalAssignNum')">
              <a-input v-model='this.totalData.totalNum'/>
            </a-form-item>
          </a-col>
          <a-col :md="8" :sm="8">
            <a-form-item :label="$t(I18N_KEY + 'totalConvertNum')">
              <a-input v-model='this.totalData.passNum'/>
            </a-form-item>
          </a-col>
          <a-col :md="8" :sm="8">
            <a-form-item :label="$t(I18N_KEY + 'totalConvertRate')">
              <a-input v-model='this.totalData.passRate'/>
            </a-form-item>
          </a-col>
          <a-col :md="8" :sm="8">
            <a-form-item :label="$t(I18N_KEY + 'averageAssignNum')">
              <a-input v-model='this.totalData.averageAssignNum'/>
            </a-form-item>
          </a-col>
          <a-col :md="8" :sm="8">
            <a-form-item :label="$t(I18N_KEY + 'averageConvertNum')">
              <a-input v-model='this.totalData.averagePassNum'/>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="24">
          <a-col :md="8" :sm="8">
            <a-form-item :label="$t(I18N_KEY + 'totalEarlySettleAssignNum')">
              <a-input v-model='this.totalData.earlySettleTotalNum'/>
            </a-form-item>
          </a-col>
          <a-col :md="8" :sm="8">
            <a-form-item :label="$t(I18N_KEY + 'totalEarlySettleNum')">
              <a-input v-model='this.totalData.earlySettleNum'/>
            </a-form-item>
          </a-col>
          <a-col :md="8" :sm="16">
            <a-form-item :label="$t(I18N_KEY + 'totalEarlySettleRate')">
              <a-input v-model='this.totalData.earlySettleRate'/>
            </a-form-item>
          </a-col>
          <a-col :md="8" :sm="10">
            <a-form-item :label="$t(I18N_KEY + 'averageEarlySettleAssignNum')">
              <a-input v-model='this.totalData.averageEarlySettleAssignNum'/>
            </a-form-item>
          </a-col>
          <a-col :md="8" :sm="6">
            <a-form-item :label="$t(I18N_KEY + 'averageEarlySettleNum')">
              <a-input v-model='this.totalData.averageEarlySettleNum'/>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
      <a-table
        ref="table"
        rowKey="id"
        size="middle"
        :columns="columns"
        :dataSource="dataSource"
        :pagination="false"
        :loading="loading"
        :scroll="{ x: 1300 }"
        bordered>
        <template #type="text,record,index">{{$t(I18N_KEY + 'type_' + text)}}</template>
<!--        <template #convertRate="text,record,index">{{text + '%'}}</template>-->
      </a-table>
      <div style="margin-top: 15px; text-align: right;">
        <v-pagination
          v-if="total"
          :page-num="form.pageNum"
          :page-size="form.pageSize"
          :total="total"
          @change="handleTableChange" />
      </div>
    </div>
  </div>
</template>
<script>
import { filterObj } from '@/utils/util';
import {t1Convert, baseData} from '@/webpublicapi/teleMarket';
import vModal from "@/views/webpublic/modules/Modal";
import {mapGetters} from "vuex";
import { QuestionCircleOutlined } from '@ant-design/icons-vue';
import _ from 'lodash';
import moment from 'moment/moment'
import exportFile from '@/webpublicapi/exportFile'
// import { defineComponent } from 'vue';
const I18N_KEY = 'teleMarket.';
export default {
  name: 'teleMarketT1Convert',
  components: {
    vModal
  },
  data () {
    return {
      I18N_KEY,
      form: {
        pageNum: 1,
        pageSize: 10,
      },
      view: false,
      total: 0,
      dataSource: [],
      apps: [],// 包列表
      teleMarketUsers: [],// 电销员列表
      types: [],// 类型列表
      loanTypes: [{"label":"1", "value":"1"},{"label": "2", "value":"2"},{"label":"3","value":"3"},{"label":"4","value":"4"}],// 首复贷类型1-首代，2-复贷，3-首复贷，4-提前结清
      totalData: {
        totalNum: 0,
        convertNum: 0,
        convertRate: '0',
        passNum: 0,
        passRate: '0',
        averageAssignNum: '0',
        averagePassNum: '0',
        averageConvertNum: '0',
        earlySettleTotalNum: 0,
        earlySettleNum: 0,
        earlySettleRate: '0',
        averageEarlySettleAssignNum: '0',
        averageEarlySettleNum: '0'
      },
      // 查询条件
      queryParam: {
        "createdTime": [],
        "appSsid": '',
        "type": '',
        "user": '',
        "loanType": '',
      },
      loading: false,
      // 表头
      isorter: {
        column: 'createTime',
        order: 'desc'
      },
      labelCol: {
        xs: { span: 8 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 16 },
        sm: { span: 19 }
      }
    }
  },
  computed: {
    ...mapGetters('language', ['languageStorage']),
    columns() {
      return [
        // {
        //   title: this.$t(I18N_KEY + 'id'),
        //   align: 'left',
        //   dataIndex: 'id'
        // },
        {
          title: this.$t(I18N_KEY + 'dataDate'),
          align: 'center',
          dataIndex: 'dataDate'
        },
        {
          title: this.$t(I18N_KEY + 'teleMarketUser'),
          align: 'center',
          dataIndex: 'user',
          // scopedSlots: {customRender: 'user'},
          customRender: (text, record) => {
            var text = text;
            return this.getUserLabel(text);
          }
        },
        {
          title: this.$t(I18N_KEY + 'app'),
          align: 'center',
          dataIndex: 'appSsid',
          customRender: (text, record) => {
            var text = text;
            return this.getAppName(text);
          }
        },
        {
          title: this.$t(I18N_KEY + 'type'),
          align: 'center',
          dataIndex: 'type',
          scopedSlots: {customRender: 'type'}
        },
        {
          title: this.$t(I18N_KEY + 'assignNum'),
          align: 'center',
          dataIndex: 'totalNum'
        },
        {
          title: this.$t(I18N_KEY + 'passNum'),
          align: 'center',
          dataIndex: 'passNum',
          // 排除提前结清的数据显示为-
          customRender: (text, record) => {
            var text = text;
            var type = record.type;
            if(this.checkType(type)) {
              return '-'
            }
            return text;
          }
        },
        {
          title: this.$t(I18N_KEY + 'passRate'),
          align: 'center',
          dataIndex: 'passRate',
          // scopedSlots: {customRender: 'convertRate'}
          // 排除提前结清的数据显示为-
          customRender: (text, record) => {
            var text = text;
            var type = record.type;
            if(this.checkType(type)) {
              return '-'
            }
            return text;
          }
        },
        {
          title: this.$t(I18N_KEY + 'earlySettleNum'),
          align: 'center',
          dataIndex: 'earlySettleNum',
          // 排除非提前结清的数据显示为-
          customRender: (text, record) => {
            var text = text;
            var type = record.type;
            if(this.checkType(type)) {
              return text;
            }
            return '-';
          }
        },
        {
          title: this.$t(I18N_KEY + 'earlySettleRate'),
          align: 'center',
          dataIndex: 'earlySettleRate',
          // scopedSlots: {customRender: 'convertRate'}
          // 排除非提前结清的数据显示为-
          customRender: (text, record) => {
            var text = text;
            var type = record.type;
            if(this.checkType(type)) {
              return text;
            }
            return '-';
          }
        },
        // {
        //   title: this.$t(I18N_KEY + 'createTime'),
        //   align: 'left',
        //   width: 160,
        //   dataIndex: 'createTime'
        // },
        // {
        //   title: this.$t(I18N_KEY + 'updateTime'),
        //   align: 'left',
        //   width: 160,
        //   dataIndex: 'updateTime'
        // },
        // {
        //   title: this.$t('action'),
        //   dataIndex: 'action',
        //   align: 'center',
        //   fixed: 'right',
        //   width: 150,
        //   scopedSlots: {customRender: 'action'}
        // }
      ]
    },
  },
  created () {
    this.loadBaseData();// 加载电销员列表，类型列表，包列表
    // this.loadData(1);
  },
  methods: {
    searchQuery () {
      this.loadData(1)
    },
    loadBaseData() {
      baseData({}).then((res) => {
        if (res.code === 1000) {
          this.teleMarketUsers = res.data.seats;
          this.types = res.data.types;
          this.apps = res.data.apps;
          this.view = res.data.view;
        } else {
          this.$message.error(res.msg)
        }
      }).catch(e => {
        this.$message.error(e.message);
      })
    },
    loadData(arg) {
      // 加载数据 若传入参数1则加载第一页的内容
      if (arg === 1) {
        this.form.pageNum = 1
      }
      this.loading = true
      var params = this.getQueryParams()// 查询条件
      t1Convert(Object.assign({}, params)).then((res) => {
        if (res.code === 1000) {
          this.total = res.data.total
          this.dataSource = res.data.rows
          this.totalData = res.data.convert
        } else {
          this.total = 0
          this.dataSource = []
          this.$message.error(res.msg)
        }
        this.loading = false
      }).catch(e => {
        this.loading = false;
        this.$message.error(e.message);
      })
    },
    getQueryParams() {
      const { createdTime } = this.queryParam;
      const data = {
        ...this.queryParam,
        startDate: createdTime && this.transformTime(createdTime[0]),
        endDate: createdTime && this.transformTime(createdTime[1]),
      }
      // console.log(this.queryParam.createdTime)
      if(this.queryParam.createdTime.length === 0) {
        data['startDate'] = ''
        data['endDate']=''
      }
      var param = Object.assign({}, data)
      param.pageNum = this.form.pageNum
      param.pageSize = this.form.pageSize
      return filterObj(param)
    },
    transformTime(time) {
      return moment(time).format('YYYY-MM-DD')
    },
    transformStringToLow(str) {
      return str && str.toLocaleLowerCase();
    },
    handleTableChange(pageNum, pageSize) {
      this.form.pageNum = pageNum;
      this.form.pageSize = pageSize;
      this.loadData();
    },
    searchReset() {
      var that = this
      that.queryParam.appSsid = ''
      that.queryParam.type = ''
      that.queryParam.user = ''
      that.queryParam.createdTime = []
      that.queryParam.loanType = ''
    },
    handleReCreate(record) {
      const data = {
        account: record.account,
        type: record.type,
        dateLabel: record.dateLabel
      }
      var param = Object.assign({}, data)
      this.doCreate(filterObj(param))
    },
    getUserLabel(user) {
      for(var item in this.teleMarketUsers) {
          var userObj = this.teleMarketUsers[item];
          if(userObj.value === user){
            return userObj.label;
          }
      }
    },
    getAppName(appSsid) {
      for(var item in this.apps) {
        var appObj = this.apps[item];
        if(appObj.appSsid === appSsid) {
          return appSsid+ '/'+appObj.appName;
        }
      }
      return appSsid;
    },
    getQueryParams1() {
      const { createdTime } = this.queryParam;
      const data = {
        ...this.queryParam,
        startDate: createdTime && this.transformTime(createdTime[0]),
        endDate: createdTime && this.transformTime(createdTime[1]),
      }
      if(this.queryParam.createdTime.length === 0) {
        data['startDate'] = ''
        data['endDate']=''
      }
      return Object.assign({}, data)
    },
    downLoadData () {
      const params = this.getQueryParams1()// 查询条件
      const file = {
        fileUrl: '/api/web/webpublic/telemarket/report/t1Convert/export?startDate=' + this.checkParam(params.startDate) + '&endDate=' + this.checkParam(params.endDate) + '&user=' + this.checkParam(params.user) + '&type=' + this.checkParam(params.type) + '&loanType=' + this.checkParam(params.loanType) + '&appSsid=' + this.checkParam(params.appSsid),
        fileName: 'teleMarketT1Convert.xls',
      }
      this.loading = true;
      exportFile(file).catch(e => {
        this.$message.error(e.message);
      }).finally(() => {
        this.loading = false;
      })
    },
    checkParam(p){
      if(p===null || p === 'null' || p === undefined || p === 'undefined') {
        return '';
      }
      return p;
    },
    checkType(type) {
      if(type == '8' || type == '9') {
        return true
      }
      return false;
    },
  },
}
</script>