import { render, staticRenderFns } from "./countLoanDataWithDate.vue?vue&type=template&id=80058f22&scoped=true&"
import script from "./countLoanDataWithDate.vue?vue&type=script&lang=js&"
export * from "./countLoanDataWithDate.vue?vue&type=script&lang=js&"
import style0 from "./countLoanDataWithDate.vue?vue&type=style&index=0&id=80058f22&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "80058f22",
  null
  
)

export default component.exports