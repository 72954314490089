<template>
  <a-modal
    :title="title"
    :width="1200"
    :visible="visible"
    :confirmLoading="confirmLoading"
    @ok="handleOk"
    @cancel="handleCancel"
    cancelText="关闭"
  >
    <a-spin :spinning="confirmLoading">
      <a-form :form="form">
        <a-table
          ref="table"
          rowKey="id"
          size="middle"
          :columns="columns"
          :dataSource="repayPlan"
          :scroll="{ x: 2300, y: 2000 }"
          :pagination="false"
          :loading="loading"
          bordered>
<!--          <Dict slot="loanStatus" slot-scope="text" :dict-code="DICT_CODE.LOAN_STATUS" :value="text"/>-->
<!--          <Dict slot="approvalStatus" slot-scope="text" :dict-code="DICT_CODE.ORDER_STATUS" :value="text"/>-->
<!--          <Dict slot="settleStatus" slot-scope="text" :dict-code="DICT_CODE.INSTALLMENT_SETTLE_STATUS" :value="text"/>-->
<!--          <Dict slot="overdueStatus" slot-scope="text" :dict-code="DICT_CODE.OVERDUE_STATUS" :value="text"/>-->
<!--          <span slot="action" slot-scope="text, record">-->

<!--          <a @click="handleLoan(record.id)" v-if='record.approvalStatus == 4'>-->
<!--            放款-->
<!--          </a>-->
<!--          <a @click="handleOrderEdit(record)" v-if='record.approvalStatus != 4'>-->
<!--            订单编辑-->
<!--          </a>-->
<!--          <a @click="handleEdit(record)" v-if='record.approvalStatus == 4'>-->
<!--            编辑还款计划-->
<!--          </a>-->
<!--        </span>-->
          <span slot="action" slot-scope="text, record">
            <a-button @click="handleOfflineRepay(record)" style="margin-left: 8px" :disabled="record.status !=0">{{ $t('offlineRepay') }}</a-button>
            <a-button @click="handleDeduceLateFee(record)" style="margin-left: 8px" :disabled="record.status !=0">{{ $t('decLateFee') }}</a-button>
        </span>
        </a-table>

      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>
import pick from 'lodash.pick'
import {installmentDeduceLateFee, installmentRepayOffline} from '@/webpublicapi/order'
import DictSelect from '../../modules/DictSelect';
import Dict from '@/views/webpublic/modules/Dict.vue'
import md5 from "md5";
// duplicateCheck
export default {
  name: 'TestInstallOrderListModal',
  components: {
    Dict,
    DictSelect,
  },
  data () {
    return {
      value: 1,
      settleStatus: "",
      title: '操作',
      visible: false,
      loading: false,
      columns: [
        {
          title: '期数',
          align: 'left',
          dataIndex: 'period',
        },
        {
          title: '状态',
          align: 'left',
          dataIndex: 'status'
        },
        {
          title: '逾期天数',
          align: 'left',
          dataIndex: 'overdueDays'
        },
        {
          title: '开始日',
          align: 'left',
          dataIndex: 'beginDate',
        },
        {
          title: '结束日',
          align: 'left',
          dataIndex: 'endDate'
        },
        {
          title: '本金',
          align: 'left',
          dataIndex: 'capital'
        },
        {
          title: '已还本金',
          align: 'left',
          dataIndex: 'settledCapital'
        },
        {
          title: '利息',
          align: 'left',
          dataIndex: 'interest'
        },
        {
          title: '已还利息',
          align: 'left',
          dataIndex: 'settledInterest'
        },
        {
          title: '服务费',
          align: 'left',
          dataIndex: 'serviceFee'
        },
        {
          title: '已还服务费',
          align: 'left',
          dataIndex: 'settledServiceFee'
        },
        {
          title: '滞纳金',
          align: 'left',
          dataIndex: 'lateFee'
        },
        {
          title: '已还滞纳金',
          align: 'left',
          dataIndex: 'settledLateFee'
        },
        {
          title: '其他费用',
          align: 'left',
          dataIndex: 'otherFee'
        },
        {
          title: '已还其他费用',
          align: 'left',
          dataIndex: 'settledOtherFee'
        },
        {
          title: '手续费',
          align: 'left',
          dataIndex: 'chargeFee'
        },
        {
          title: '已还手续费',
          align: 'left',
          dataIndex: 'settledChargeFee'
        },
        {
          title: '抵扣费用',
          align: 'left',
          dataIndex: 'deductionFee'
        },
        {
          title: '操作',
          align: 'center',
          fixed: 'right',
          width: 150,
          dataIndex: 'action',
          scopedSlots: { customRender: 'action' }
        }
      ],
      dataSource:[],
      repayPlan: [],
      model: {},
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 }
      },
      confirmLoading: false,
      form: this.$form.createForm(this),
    }
  },
  created () {
  },
  methods: {
    checkBoxChange(checkedValue) {
      console.log(checkedValue)
    },
    add () {
      this.edit({})
    },
    handleOfflineRepay(record) {
      const self = this;
      this.$confirm({

        title: '确定线下还款？',
        content: h => {
          return (
            <div>
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                <span style={{ marginRight: '10px' }}>验证密码：</span>
                <input id='password' type='password' style={{ flex: '1' }} required/>
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <span style={{ marginRight: '10px' }}>还款金额：</span>
                <input id='amount' type='number' style={{ flex: '1' }} />
              </div>
            </div>
          );
        },
        onOk() {
          self.loading = true;
          var password = document.getElementById("password").value;
          var amount = document.getElementById("amount").value;

          if(!password.trim()){
            alert('验证密码不能为空');
            self.loading = false;
            return false;
          }
          if (!amount.trim()) {
            alert('还款金额不能为空');
            self.loading = false;
            return false;
          }
          var md5Password = md5(password);

          installmentRepayOffline({ repayPlanId: record.id, password: md5Password, amount, }).then(res => {
            if(res.code===1000){
              // self.visible = false
              self.dealRefreshData(res.data)
              self.$parent.loadData()
            }else{
              self.$message.error(res.msg);
            }
            self.loading = false;
          }).catch(e => {
            self.loading = false;
            self.$message.error(e.message);
          })
        },
      });
    },

    handleDeduceLateFee(record) {
      const self = this;
      this.$confirm({

        title: '确定减免滞纳金？',
        content: h => {
          return (
            <div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <span style={{ marginRight: '10px' }}>减免金额：</span>
                <input id='deduceAmt' type='number' style={{ flex: '1' }} />
              </div>
            </div>
          );
        },
        onOk() {
          self.loading = true;
          var deduceAmt = document.getElementById("deduceAmt").value;

          if (!deduceAmt.trim()) {
            alert('减免金额不能为空');
            self.loading = false;
            return false;
          }

          installmentDeduceLateFee({ planId: record.id, deduceAmt }).then(res => {
            if(res.code===1000){
              self.visible = false
              self.$parent.loadData()
            }else{
              self.$message.error(res.msg);
            }
            self.loading = false;
          }).catch(e => {
            self.loading = false;
            self.$message.error(e.message);
          })
        },
      });
    },
    dealRefreshData(data) {
      let index;

      for (let i = 0; i < this.repayPlan.length; i++) {
        if (data.id === this.repayPlan[i].id) {
          index = i;
          break;
        }
      }

      this.repayPlan[index] = data
    },
    edit (record) {
      if (record.id) {
        this.visiblekey = true
      } else {
        this.visiblekey = false
      }
      this.form.resetFields()
      this.model = Object.assign({}, record)
      this.visible = true

      this.$nextTick(() => {
        this.repayPlan = record.repayPlans;
        this.model.approvalStatus=String(this.model.approvalStatus)
        this.model.loanStatus=String(this.model.loanStatus)
        // this.model.overdueStatus=String(this.model.overdueStatus)
        this.model.settleStatus=String(this.model.settleStatus)
        this.form.setFieldsValue(pick(this.model,  'approvalStatus', 'loanStatus','settleStatus','applyAmt','interest', 'deductionFee', 'orderPeriod'))
      })
    },
    // 确定
    handleOk () {
      this.close()
      // const that = this
      // // 触发表单验证
      // this.form.validateFields((err, values) => {
      //   if (!err) {
      //     that.confirmLoading = true
      //     const formData = Object.assign(this.model, values)
      //     let obj
      //     console.log(formData)
      //     obj = editInstallmentOrderStatusForTest(formData)
      //     obj.then((res) => {
      //       if (res.code === 1000) {
      //         this.$message.success(res.msg)
      //         this.$emit('ok')
      //       } else {
      //         this.$message.warning(res.msg)
      //       }
      //     }).finally(() => {
      //       this.confirmLoading = false
      //       this.close()
      //     })
      //   }
      // })
    },
    // 关闭
    handleCancel () {
      this.close()
    },
    close () {
      this.$emit('close')
      this.visible = false
    }
  }
}
</script>
